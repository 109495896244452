import React from 'react';
import {
    Box,
    Button,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Stack,
    Link,
    useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

import useLoginFormState from '../hooks/useLoginFormState';

const AddProfileStep = () => {
    const { actions } = useLoginFormState();
    const { profileName, gender, dateOfBirth, isLoading, haveReferralCode, referralCode } = useSelector((state) => state.user);
    const theme = useTheme();

    return  (
        <Box sx={{ px: 2, py: 4 }}>
            <TextField
                label="Name"
                value={profileName}
                onChange={(e) => actions.updateField('profileName', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                inputProps={{
                    autoFocus: true,
                }}
            />
            <DatePicker
                label="Date of Birth"
                value={dateOfBirth}
                onChange={(value) => actions.updateField('dateOfBirth', value)}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        sx: { mb: 2 }
                    },
                }}
                maxDate={moment().subtract(3, 'years')}
            />
            <RadioGroup
                aria-label="gender"
                name="gender"
                value={gender}
                onChange={(e) => actions.updateField('gender', e.target.value)}
                row
                sx={{ mb: 2 }}
            >
                <FormControlLabel value="male" control={<Radio />} label="Male" sx={{ color: 'text.primary' }} />
                <FormControlLabel value="female" control={<Radio />} label="Female" sx={{ color: 'text.primary' }} />
            </RadioGroup>
            {!haveReferralCode && (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                    <Typography variant="caption" color="text.secondary">Have a referral code?</Typography>
                    <Link
                        component="button"
                        variant="caption"
                        underline="none"
                        onClick={actions.toggleHaveReferralCode}
                        sx={{ 
                            ml: 1,
                            color: theme.palette.primary.main,
                            '&:hover': {
                                color: theme.palette.primary.dark,
                            }
                        }}
                    >
                        Click here
                    </Link>
                </Stack>
            )}
            {haveReferralCode && (
                <TextField
                    label="Referral Code"
                    value={referralCode}
                    onChange={(e) => actions.updateField('referralCode', e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
            )}
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={actions.handleAddProfile}
                disabled={!profileName || !gender || !dateOfBirth || isLoading}
            >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Add'}
            </Button>
        </Box>
    );
};

export default AddProfileStep;
