import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parsePhoneNumber } from 'react-phone-number-input';

import { useBoolean } from '../../hooks/use-boolean';
import AddEventRegistrationStepper from './AddEventRegistrationStepper';
import { setCurrentStepById, updateFormData } from '../../redux/slices/tournamentRegistrationSlice';
import AddEventForRegistration from './AddEventForRegistration';
import TEventDocUpload from './TEventDocUpload';
import PlayerTeamList from './PlayerTeamList';
import { PhoneInput } from '../../components/phone-input';


// ----------------------------------------------------------------------

const AddEventRegistrationDialog = () => {
  const dialog = useBoolean();
  const [maxWidth, setMaxWidth] = useState('sm');
  const dispatch = useDispatch();
  const { stepper: { currentStepId, steps } } = useSelector((state) => state.eventRegistration);
  const [mobileWithCode, setMobileWithCode] = useState('');
  const [mobile, setMobile] = useState("");
  const [dialCode, setDialCode] = useState("91");

  useEffect(() => {
    if (mobileWithCode) {
      const phoneNumber = parsePhoneNumber(mobileWithCode);
      if (phoneNumber) {
        setMobile(phoneNumber.nationalNumber);
        setDialCode(phoneNumber.countryCallingCode);
      }
    } else {
      setMobile('');
    }
  }, [mobileWithCode]);

  const getCurrentStepIndex = () => steps.findIndex(step => step.id === currentStepId);

  const handleNext = () => {
    const currentIndex = getCurrentStepIndex();
    if (currentIndex < steps.length - 1) {
      dispatch(setCurrentStepById(steps[currentIndex + 1].id));
    }
  };

  const handleBack = () => {
    const currentIndex = getCurrentStepIndex();
    if (currentIndex > 0) {
      dispatch(setCurrentStepById(steps[currentIndex - 1].id));
    }
  };

  const handleReset = () => {
    dispatch(setCurrentStepById(steps[0].id));
  };

  const getStepContent = (stepId) => {
    switch (stepId) {
      case 'select-event':
        return <AddEventForRegistration />;
      case 'add-partner':
        return <PlayerTeamList />;
      case 'documents':
        return <TEventDocUpload />;
      default:
        return 'Unknown step';
    }
  }

  const handlemobileChange = (e) => {
    setMobileWithCode(e);
  };

  return (
    <>
      <Button variant="outlined" onClick={dialog.onTrue}>
        Max Width Dialog
      </Button>
      <Stack p={4}> <PhoneInput name={"mobileNumber"} label={"Mobile number"} onChange={handlemobileChange} />

        <DatePicker label={"Date of birth"} format="DD/MMM/YYYY" />
        <TextField
          required
          onChange={(e) => {

          }}
          placeholder='Player First Name'
          label={'Player First Name'}
        />
        <TextField
          required
          onChange={(e) => {

          }}
          label={'Player Last Name'}
          placeholder='Player Last Name'
        />
        <PhoneInput name={"alternateMobileNumber"} label={"Alternate Mobile number"}
          onChange={handlemobileChange}
        />
      </Stack>
      <Dialog
        open={dialog.value}
        fullScreen
        maxWidth={maxWidth}
        onClose={dialog.onFalse}
      >
        <DialogTitle> <AddEventRegistrationStepper /></DialogTitle>

        <DialogContent>
          <Box pt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            {getStepContent(currentStepId)}
          </Box>
        </DialogContent>

        <DialogActions>
          <Box sx={{ textAlign: 'right' }}>
            <Button 
              disabled={getCurrentStepIndex() === 0} 
              onClick={handleBack} 
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button 
              variant="contained" 
              onClick={handleNext} 
              sx={{ mr: 1 }}
            >
              {getCurrentStepIndex() === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEventRegistrationDialog;
