// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const StartPlaying = ({ ...other }) => {
  const theme = useTheme();

  return (
    <Box {...other}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="22" fill="#62ECBC" />
        <g clipPath="url(#clip0_2795_8062)">
          <path d="M10 20.3201C10.1057 19.8426 10.1927 19.3595 10.321 18.8876C11.0919 16.0527 12.6887 13.7891 15.0827 12.0938C16.6708 10.9698 18.4422 10.2958 20.3688 10.028C20.4607 10.0154 20.5552 10.0142 20.6491 10.0142C21.2347 10.0129 21.821 10.0367 22.4055 10.0092C23.9466 9.93534 25.3951 10.3127 26.7842 10.9248C27.4519 11.2189 28.0795 11.6063 28.7139 11.9705C29.0468 12.162 29.1257 12.5093 28.9398 12.7878C28.754 13.0663 28.4192 13.1189 28.0976 12.9061C26.7373 12.0068 25.2575 11.4191 23.6431 11.1763C21.2929 10.8234 19.0454 11.1657 16.9498 12.2815C13.892 13.9105 11.9216 16.4288 11.2696 19.8339C10.458 24.0738 11.7595 27.6565 15.0414 30.4596C17.0162 32.1461 19.3601 32.9541 21.9593 32.9641C26.803 32.9822 31.086 29.8337 32.472 25.1915C33.4531 21.9066 32.9644 18.8056 31.1092 15.9213C31.0673 15.8555 31.0197 15.793 30.9815 15.7254C30.822 15.4444 30.8921 15.1321 31.1499 14.9638C31.4052 14.7967 31.7487 14.8436 31.9151 15.1127C32.263 15.6759 32.6128 16.2411 32.9087 16.8318C33.4719 17.9558 33.8304 19.1542 33.9612 20.4027C34.0325 21.0824 33.9587 21.7758 33.9906 22.4617C34.0432 23.6106 33.853 24.7208 33.4663 25.7916C31.8963 30.1429 28.856 32.8427 24.3358 33.8609C24.1174 33.9103 23.8978 33.9535 23.6788 33.9992H20.3056C20.1391 33.9654 19.9727 33.9335 19.8069 33.8972C14.9576 32.8251 11.7845 29.927 10.3323 25.1689C10.1852 24.6883 10.1089 24.1858 10 23.6933C10 22.5687 10 21.4447 10 20.3201Z" fill="#193052" />
          <path d="M10 20.3201C10.1057 19.8426 10.1927 19.3595 10.321 18.8876C11.0919 16.0527 12.6887 13.7891 15.0827 12.0938C16.6708 10.9698 18.4422 10.2958 20.3688 10.028C20.4607 10.0154 20.5552 10.0142 20.6491 10.0142C21.2347 10.0129 21.821 10.0367 22.4055 10.0092C23.9466 9.93534 25.3951 10.3127 26.7842 10.9248C27.4519 11.2189 28.0795 11.6063 28.7139 11.9705C29.0468 12.162 29.1257 12.5093 28.9398 12.7878C28.754 13.0663 28.4192 13.1189 28.0976 12.9061C26.7373 12.0068 25.2575 11.4191 23.6431 11.1763C21.2929 10.8234 19.0454 11.1657 16.9498 12.2815C13.892 13.9105 11.9216 16.4288 11.2696 19.8339C10.458 24.0738 11.7595 27.6565 15.0414 30.4596C17.0162 32.1461 19.3601 32.9541 21.9593 32.9641C26.803 32.9822 31.086 29.8337 32.472 25.1915C33.4531 21.9066 32.9644 18.8056 31.1092 15.9213C31.0673 15.8555 31.0197 15.793 30.9815 15.7254C30.822 15.4444 30.8921 15.1321 31.1499 14.9638C31.4052 14.7967 31.7487 14.8436 31.9151 15.1127C32.263 15.6759 32.6128 16.2411 32.9087 16.8318C33.4719 17.9558 33.8304 19.1542 33.9612 20.4027C34.0325 21.0824 33.9587 21.7758 33.9906 22.4617C34.0432 23.6106 33.853 24.7208 33.4663 25.7916C31.8963 30.1429 28.856 32.8427 24.3358 33.8609C24.1174 33.9103 23.8978 33.9535 23.6788 33.9992H20.3056C20.1391 33.9654 19.9727 33.9335 19.8069 33.8972C14.9576 32.8251 11.7845 29.927 10.3323 25.1689C10.1852 24.6883 10.1089 24.1858 10 23.6933C10 22.5687 10 21.4447 10 20.3201Z" fill="black" fillOpacity="0.2" />
          <path d="M13.887 21.9968C13.887 20.998 13.8851 19.9998 13.887 19.001C13.8895 17.9515 14.8512 17.3845 15.7591 17.9052C17.4968 18.9015 19.2312 19.9028 20.9626 20.9091C21.9012 21.4548 21.9024 22.5569 20.9645 23.1026C19.2331 24.1095 17.4986 25.1114 15.7616 26.1084C14.8531 26.6297 13.8895 26.0646 13.8864 25.0163C13.8839 24.01 13.8864 23.0037 13.8864 21.9974L13.887 21.9968ZM20.6134 22.0074C18.7244 20.9166 16.9029 19.8652 15.0421 18.7907V25.2241C16.9048 24.1489 18.7232 23.0988 20.6134 22.0074Z" fill="#193052" />
          <path d="M13.887 21.9968C13.887 20.998 13.8851 19.9998 13.887 19.001C13.8895 17.9515 14.8512 17.3845 15.7591 17.9052C17.4968 18.9015 19.2312 19.9028 20.9626 20.9091C21.9012 21.4548 21.9024 22.5569 20.9645 23.1026C19.2331 24.1095 17.4986 25.1114 15.7616 26.1084C14.8531 26.6297 13.8895 26.0646 13.8864 25.0163C13.8839 24.01 13.8864 23.0037 13.8864 21.9974L13.887 21.9968ZM20.6134 22.0074C18.7244 20.9166 16.9029 19.8652 15.0421 18.7907V25.2241C16.9048 24.1489 18.7232 23.0988 20.6134 22.0074Z" fill="black" fillOpacity="0.2" />
          <path d="M22.5062 22.0009C22.5062 20.9551 22.5049 19.9094 22.5062 18.8637C22.5068 18.1183 22.9204 17.7028 23.6631 17.6997C23.9985 17.6984 24.3345 17.6946 24.6699 17.7003C25.3182 17.7115 25.7524 18.1177 25.7555 18.7667C25.7662 20.9282 25.7662 23.0904 25.7555 25.252C25.7524 25.8903 25.3225 26.2952 24.6856 26.3102C24.3189 26.319 23.9516 26.3184 23.5849 26.3102C22.9304 26.2952 22.5099 25.8665 22.5074 25.2088C22.5037 24.1399 22.5062 23.0704 22.5062 22.0015V22.0009ZM24.618 25.1725V18.8386H23.6444V25.1725H24.618Z" fill="#193052" />
          <path d="M22.5062 22.0009C22.5062 20.9551 22.5049 19.9094 22.5062 18.8637C22.5068 18.1183 22.9204 17.7028 23.6631 17.6997C23.9985 17.6984 24.3345 17.6946 24.6699 17.7003C25.3182 17.7115 25.7524 18.1177 25.7555 18.7667C25.7662 20.9282 25.7662 23.0904 25.7555 25.252C25.7524 25.8903 25.3225 26.2952 24.6856 26.3102C24.3189 26.319 23.9516 26.3184 23.5849 26.3102C22.9304 26.2952 22.5099 25.8665 22.5074 25.2088C22.5037 24.1399 22.5062 23.0704 22.5062 22.0015V22.0009ZM24.618 25.1725V18.8386H23.6444V25.1725H24.618Z" fill="black" fillOpacity="0.2" />
          <path d="M30.0992 22.0177C30.0992 23.0559 30.0998 24.0935 30.0992 25.1318C30.0992 25.9028 29.6874 26.3108 28.9103 26.3127C28.5981 26.3133 28.2858 26.3152 27.9736 26.3127C27.2609 26.3052 26.8479 25.8915 26.8442 25.1668C26.8386 23.9884 26.8429 22.81 26.8429 21.6316C26.8429 20.7185 26.8411 19.8055 26.8429 18.8924C26.8448 18.1026 27.2509 17.7021 28.0443 17.7008C28.364 17.7008 28.6844 17.6965 29.0042 17.7021C29.673 17.7134 30.096 18.1408 30.0979 18.8117C30.101 19.8806 30.0985 20.9501 30.0985 22.019L30.0992 22.0177ZM27.9911 18.8386V25.1699H28.9616V18.8386H27.9911Z" fill="#193052" />
          <path d="M30.0992 22.0177C30.0992 23.0559 30.0998 24.0935 30.0992 25.1318C30.0992 25.9028 29.6874 26.3108 28.9103 26.3127C28.5981 26.3133 28.2858 26.3152 27.9736 26.3127C27.2609 26.3052 26.8479 25.8915 26.8442 25.1668C26.8386 23.9884 26.8429 22.81 26.8429 21.6316C26.8429 20.7185 26.8411 19.8055 26.8429 18.8924C26.8448 18.1026 27.2509 17.7021 28.0443 17.7008C28.364 17.7008 28.6844 17.6965 29.0042 17.7021C29.673 17.7134 30.096 18.1408 30.0979 18.8117C30.101 19.8806 30.0985 20.9501 30.0985 22.019L30.0992 22.0177ZM27.9911 18.8386V25.1699H28.9616V18.8386H27.9911Z" fill="black" fillOpacity="0.2" />
          <path d="M30.6612 13.8051C30.6637 14.118 30.4128 14.3715 30.1005 14.3715C29.7902 14.3715 29.5342 14.113 29.5386 13.8039C29.5424 13.5047 29.7908 13.2544 30.0905 13.2482C30.4009 13.2419 30.6587 13.4935 30.6618 13.8051H30.6612Z" fill="#193052" />
          <path d="M30.6612 13.8051C30.6637 14.118 30.4128 14.3715 30.1005 14.3715C29.7902 14.3715 29.5342 14.113 29.5386 13.8039C29.5424 13.5047 29.7908 13.2544 30.0905 13.2482C30.4009 13.2419 30.6587 13.4935 30.6618 13.8051H30.6612Z" fill="black" fillOpacity="0.2" />
        </g>
        <defs>
          <clipPath id="clip0_2795_8062">
            <rect width="24" height="24" fill="white" transform="translate(10 10)" />
          </clipPath>
        </defs>
      </svg>

    </Box>
  );
};

export default StartPlaying;
