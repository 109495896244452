import { Container, Grid, Typography, Stack } from '@mui/material';

// components
import { MotionContainer, varFade } from '../animate';

import LANDING_PSM_GIFT from "../../assets/psm-gift.webp";

import useResponsive from '../../hooks/useResponsive';

const InfoImageCard = () => {
    const smDown = useResponsive('down', 'sm');

    return (
        <Container maxWidth="md" component={MotionContainer} paddingTop={4}>
            <Grid container sx={{ background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #193052", color: "#fff", borderTopLeftRadius: 30, borderBottomRightRadius: 30 }}>
                <Grid item md={6} p={{ xs: 4, md: 6 }}>
                    <Stack maxWidth={480} alignItems={'center'} >
                        <Typography variant={smDown ? 'h4' : 'h3'} color={"#62ECBC"} textAlign={'center'} >
                            Gift  <Typography component={'span'} variant={smDown ? 'h4' : 'h3'} color={"#fff"}>
                                your child a
                                new passion
                            </Typography>
                        </Typography>
                        <Typography variant='body2' textAlign={'center'} paddingTop={3} maxWidth={420}>
                            Our science-based summer camps improve children's badminton technique while promoting social interaction and fun games with friends. Ideal for kids interested in exploring badminton as a hobby and staying active.
                        </Typography>

                    </Stack>
                </Grid>
                <Grid item md={6} sx={{ backgroundImage: `url(${LANDING_PSM_GIFT})`, backgroundPosition: 'center', mixBlendMode: 'overlay', backgroundSize: 'cover', borderBottomRightRadius: 30 }} />
            </Grid>
        </Container>
    )
}

export default InfoImageCard;