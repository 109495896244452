import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
    stepper: {
        currentStepId: 'basic-details',
        steps: [],
    },
    formData: {},
    tournamentData: {
        selectedEvents: [],
        partnerDetails: {}
    },
    eventId: null,
}

const tournamentRegistrationSlice = createSlice({
    name: 'tournamentRegistration',
    initialState,
    reducers: {
        setCurrentStepById: (state, action) => {
            state.stepper.currentStepId = action.payload;
        },
        updateSteps: (state, action) => {
            state.stepper.steps = action.payload;
        },
        updateFormData: (state, action) => {
            if (action.payload.selectedEvents || action.payload.partnerDetails) {
                state.tournamentData = {
                    ...state.tournamentData,
                    ...action.payload
                };
            } else {
                state.formData = {
                    ...state.formData,
                    ...action.payload,
                };
            }
        },
        resetFormData: (state) => {
            state.formData = {};
            state.stepper.currentStepId = state.stepper.steps[0].id;
        },
        setEventId: (state, action) => {
            state.eventId = action.payload;
        },
        resetState: (state) => {
            state.stepper = initialState.stepper;
            state.formData = initialState.formData;
            state.eventId = initialState.eventId;
        },
        setTournamentData: (state, action) => {
            state.tournamentData = {
                ...state.tournamentData,
                ...action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setSteps, (state, action) => {
                state.stepper.steps = action.payload;
            });
    },
})

export const { setCurrentStepById, updateSteps, updateFormData, resetFormData, setEventId, resetState, setTournamentData } = tournamentRegistrationSlice.actions;

export const setSteps = createAction('tournamentRegistration/setSteps');

export default tournamentRegistrationSlice.reducer;