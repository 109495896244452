// src/components/FieldRenderer.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Collapse } from '@mui/material';
import fieldComponents from './fieldComponents';

/**
 * FieldRenderer Component
 *
 * Renders a form field based on the provided field schema with animated transitions.
 *
 * @param {Object} props
 * @param {Object} props.field - The field configuration object from the form schema.
 * @param {string} [props.language='en'] - The current language code for localization.
 */
const FieldRenderer = ({ field, language = 'en' }) => {
    const { control } = useFormContext();

    const watchFields = field.conditions
        ? field.conditions.map((condition) => condition.fieldName)
        : [];

    const watchedValuesArray = useWatch({
        control,
        name: watchFields,
    });

    const watchedValues = watchFields.reduce  ((acc, fieldName, index) => {
        acc[fieldName] = watchedValuesArray[index];
        return acc;
    }, {});

    const shouldRender = () => {
        if (!field.conditions || field.conditions.length === 0) return true;

        return field.conditions.every((condition) => {
            const watchedValue = watchedValues[condition.fieldName];
            switch (condition.operator) {
                case '==':
                    return watchedValue === condition.value;
                case '!=':
                    return watchedValue !== condition.value;
                case '>':
                    return watchedValue > condition.value;
                case '<':
                    return watchedValue < condition.value;
                case '>=':
                    return watchedValue >= condition.value;
                case '<=':
                    return watchedValue <= condition.value;
                case 'in':
                    return Array.isArray(condition.value) && condition.value.includes(watchedValue);
                case 'not in':
                    return Array.isArray(condition.value) && !condition.value.includes(watchedValue);
                default:
                    return true;
            }
        });
    };

    const FieldComponent = fieldComponents[field.type] || fieldComponents.text;

    const label =
        typeof field.label === 'object' ? field.label[language] || field.label.en : field.label;
    const placeholder =
        typeof field.placeholder === 'object'
            ? field.placeholder[language] || field.placeholder.en
            : field.placeholder;

    const getValidationRules = () => {
        if (!field.validations) return {};
        
        const rules = { ...field.validations };
        
        if (rules.dependsOn) {
            const { fieldName, operator, value } = rules.dependsOn;
            const watchedValue = watchedValues[fieldName];
            
            if (rules.required) {
                rules.required = (() => {
                    switch (operator) {
                        case '==':
                            return watchedValue === value;
                        case '!=':
                            return watchedValue !== value;
                        // Add other operators as needed
                        default:
                            return false;
                    }
                })();
            }
            

            delete rules.dependsOn;
        }
        
        return rules;
    };

    return (
        <Collapse in={shouldRender()} timeout="auto" unmountOnExit>
            <Box>
                <FieldComponent
                    name={field.name}
                    label={label}
                    placeholder={placeholder}
                    type={field.type}
                    options={field.options}
                    rows={field.rows}
                    {...field.otherProps}
                />
            </Box>
        </Collapse>
    );
};

FieldRenderer.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.any.isRequired,
            })
        ),
        rows: PropTypes.number,
        conditions: PropTypes.arrayOf(
            PropTypes.shape({
                fieldName: PropTypes.string.isRequired,
                operator: PropTypes.string.isRequired,
                value: PropTypes.any.isRequired,
            })
        ),
        otherProps: PropTypes.object,
    }).isRequired,
    language: PropTypes.string,
};

export default React.memo(FieldRenderer);