import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

const formatStr = {
    dateTime: 'DD MMM YYYY h:mm a',
    date: 'DD MMM YYYY',
    time: 'h:mm a',
    split: {
        dateTime: 'DD/MM/YYYY h:mm a',
        date: 'DD/MM/YYYY',
    },
    paramCase: {
        dateTime: 'DD-MM-YYYY h:mm a',
    },
};

// ----------------------------------------------------------------------

const RHFDatePicker = ({ name, slotProps, ...other }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <DatePicker
                    {...field}
                    value={moment(field.value)}
                    onChange={(newValue) => field.onChange(moment(newValue).format())}
                    format={formatStr.split.date}
                    slotProps={{
                        ...slotProps,
                        textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message ?? slotProps?.textField?.helperText,
                            ...slotProps?.textField,
                        },
                    }}
                    {...other}
                />
            )}
        />
    );
}

// ----------------------------------------------------------------------

const RHFMobileDateTimePicker = ({ name, slotProps, ...other }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <MobileDateTimePicker
                    {...field}
                    value={moment(field.value)}
                    onChange={(newValue) => field.onChange(moment(newValue).format())}
                    format={formatStr.split.dateTime}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message ?? slotProps?.textField?.helperText,
                            ...slotProps?.textField,
                        },
                        ...slotProps,
                    }}
                    {...other}
                />
            )}
        />
    );
}

export { RHFDatePicker, RHFMobileDateTimePicker };