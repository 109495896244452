// External libraries
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment/moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Autocomplete,
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';

import { parsePhoneNumber } from 'react-phone-number-input';
import { PhoneInput } from '../../../components/phone-input';
import { useSelector } from '../../../redux/store';
import MyAvatar from '../../../components/MyAvatar';
import Iconify from '../../../components/Iconify';

const schema = yup.object().shape({
  name: yup.string().required('Partner name is required'),
  dob: yup
    .date()
    .required('Date of birth is required')
    .max(moment().subtract(3, 'years').toDate(), 'Date of birth must be at least 3 years ago'),
  mobile: yup.string().required('Mobile number is required'),
  email: yup.string().email('Please enter a valid email'),
  gender: yup.string().required('Gender is required'),
  academyNameAndPlace: yup.string().required('Academy Name and Place is required'),
});

const PartnerDialog = ({ open, onClose, onSubmit, currentEvent, savedPartners, existingPartner }) => {
  const [mobileWithCode, setMobileWithCode] = useState('');
  const [mobile, setMobile] = useState('');
  const [dialCode, setDialCode] = useState('91');
  const { currentOrgProfiles, currentPlayer } = useSelector((state) => state.user);
  const [addNewProfile, setAddNewProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      dob: null,
      mobile: '',
      dialcode: '',
      email: '',
      gender: '',
      academyNameAndPlace: '',
    }  });

  useEffect(() => {
    if (mobileWithCode) {
      const phoneNumber = parsePhoneNumber(mobileWithCode);
      if (phoneNumber) {
        setMobile(phoneNumber.nationalNumber);
        setDialCode(phoneNumber.countryCallingCode);
      }
    } else {
      setMobile('');
    }
  }, [mobileWithCode]);


  const onSubmitForm = () => {
    if (selectedProfile) {
      onSubmit({
        id: selectedProfile.id,  
        firstName: selectedProfile.firstName, 
        lastName: selectedProfile.lastName
      });
    } else if (addNewProfile) {
      handleSubmit((data) => {
      const formattedData = {
        isGuest: true,
        name: data.name,
        dob: data.dob instanceof Date
          ? moment(data.dob).format('YYYY-MM-DD')
          : moment(new Date(data.dob)).format('YYYY-MM-DD'),
        dialcode: dialCode,
        mobile,
        email: data.email,
        gender: data.gender,
        academyNameAndPlace: data.academyNameAndPlace,
      };
      onSubmit(formattedData);
    })();
  };
}

  const handlePartnerSelect = (partner) => {
    if (partner) {
      setValue('name', partner.name);
      const dateValue = partner.dob ? moment(partner.dob) : null;
      setValue('dob', dateValue);
      setValue('dialcode', partner.dialcode);
      setValue('mobile', partner.mobile);
      setValue('email', partner.email);
      setValue('gender', partner.gender);
      setValue('academyNameAndPlace', partner.academyNameAndPlace);
      // Reconstruct the full phone number with + prefix
      const fullPhoneNumber = `+${partner.dialcode}${partner.mobile}`;
      setMobileWithCode(fullPhoneNumber);
      setDialCode(partner.dialcode);
      setMobile(partner.mobile);
    }
  };

  const mobileNumberField = (
    <Controller
      name="mobile"
      control={control}
      render={({ field }) => (
        <PhoneInput
          value={mobileWithCode}
          onChange={(value) => {
            setMobileWithCode(value);
            field.onChange(value); // Update form value
          }}
          label="Partner Mobile Number"
          error={!!errors.mobile}
          helperText={errors.mobile?.message}
          fullWidth
          variant="outlined"
        />
      )}
    />
  );

  useEffect(() => {
    if (open && existingPartner) {
      setValue('name', existingPartner.name || '');
      const dateValue = existingPartner.dob ? moment(existingPartner.dob) : null;
      setValue('dob', dateValue);
      setValue('email', existingPartner.email || '');
      setValue('gender', existingPartner.gender || '');
      setValue('academyNameAndPlace', existingPartner.academyNameAndPlace);
      if (existingPartner.mobile && existingPartner.dialcode) {
        const fullPhoneNumber = `+${existingPartner.dialcode}${existingPartner.mobile}`;
        setMobileWithCode(fullPhoneNumber);
        setMobile(existingPartner.mobile);
        setDialCode(existingPartner.dialcode);
        setValue('mobile', existingPartner.mobile);
      }
    } else {
      reset();
      setMobileWithCode('');
    }
  }, [open, existingPartner, setValue, reset]);

  const handleProfileSelect = (profile) => {
    if (selectedProfile?.id === profile.id) {
      setSelectedProfile(null);
    } else {
      setSelectedProfile(profile);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {existingPartner ? 'Update Partner Details' : 'Add Partner Details'} for {currentEvent?.name}
      </DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2} alignItems={'center'} justifyContent={'center'}>
          {currentOrgProfiles?.length < 5 && (
            <Card
            sx={{
              width: '200px',
              marginBottom: '20px',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              setAddNewProfile(!addNewProfile);
              setSelectedProfile(null); 
            }}
            style={{ cursor: 'pointer' }}
          >
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Stack alignItems={'center'}>
                <Iconify sx={{}} width={60} height={60} icon={'gala:add'} color='primary.main' />
                <Typography variant="subtitle2" component="div" sx={{ mt: 1 }}>
                  Add new profile
                </Typography>
              </Stack>


            </CardContent>
          </Card>
        )}

        {currentOrgProfiles
          ?.filter(profile => profile.id !== currentPlayer.id)
          .map((profile) => (
            <Card
              key={profile.id}
              sx={{
                width: '200px',
                marginBottom: '20px',
                height: '200px',
                position: 'relative',
                border: selectedProfile?.id === profile.id ? '2px solid #00AB55' : 'none',
              }}
              onClick={() => handleProfileSelect(profile)}
              style={{ cursor: 'pointer' }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <MyAvatar sx={{ width: 48, height: 48 }} user={profile} />
                <Typography variant="subtitle2" component="div" sx={{ mt: 1 }}>
                  {profile?.firstName} {profile?.lastName}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'center' }}>
                  Gender: {profile?.gender}
                </Typography>
                <Typography variant="caption" sx={{ textAlign: 'center' }}>
                  Date of Birth: {profile?.dob}
                </Typography>

                {selectedProfile?.id === profile.id && (
                  <Iconify 
                    sx={{ position: 'absolute', top: 12, right: 12 }} 
                    width={20} 
                    height={20} 
                    icon={'ep:circle-check-filled'} 
                    color='primary.main' 
                  />
                )}
              </CardContent>
            </Card>
          ))}
        </Box>

        {addNewProfile && !selectedProfile && (
          <Stack spacing={3} sx={{ mt: 2 }}>
            {!existingPartner && savedPartners?.length > 0 && (
              <Autocomplete
                options={savedPartners}
                getOptionLabel={(option) => `${option.name} (${option.mobile})`}
                onChange={(_, value) => handlePartnerSelect(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Saved Partners"
                    helperText="Select a previously saved partner or fill in new details below"
                  />
                )}
              />
            )}

            {/* Partner Name */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Partner Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                  fullWidth
                />
              )}
            />

            {/* Date of Birth */}
            <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  value={field.value || null}
                  label="Date of Birth"
                  maxDate={moment().subtract(3, 'years')}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!errors.dob,
                      helperText: errors.dob?.message,
                      required: true,
                    },
                  }}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                />
              )}
            />

            {mobileNumberField}

            {/* Email */}
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Partner Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />
              )}
            />

            {/* Gender */}
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl error={!!errors.gender} fullWidth required>
                  <InputLabel>Partner Gender</InputLabel>
                  <Select {...field} label="Partner Gender">
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {errors.gender && <FormHelperText>{errors.gender.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="academyNameAndPlace"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Academy Name and Place"
                  error={!!errors.academyNameAndPlace}
                  helperText={errors.academyNameAndPlace?.message}
                  fullWidth
                />
              )}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => onSubmitForm()} 
          variant="contained" 
          fullWidth
        >
          {existingPartner ? 'Update Partner Details' : 'Save Partner Details'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartnerDialog;
