import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, Stack, useTheme } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Iconify from '../../../components/Iconify';
import useResponsive from '../../../hooks/useResponsive';

const libraries = ['places'];

const Location = ({ locationData }) => {
  const theme = useTheme();
  const [map, setMap] = useState(null);
  const mdUp = useResponsive('up', 'md');

  const containerStyle = {
    width: {xs:'100%',md:'50%'},
     height: '250px',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2)
  };

  const center = {
    lat: locationData.latitude,
    lng: locationData.longitude
  };

  const handleMapClick = () => {
    const latitude = locationData?.latitude;
    const longitude = locationData?.longitude;
    window.open(`https://www.google.com/maps?q=${latitude},${longitude}`);
  };

  return (
    <Box sx={{ bgcolor: 'white',  m: {xs:2,md:3} }}>
      <Box 
            sx={{ display: 'flex', alignItems: 'start', }}
          >
       <Iconify
              icon={locationData.icon}
              sx={{ color: 'primary.main', width: 24, height: 24, mr: 1 }} 
            />
      <Typography variant="h5" color="text.primary" gutterBottom>
        Location
      </Typography>
      </Box>
      <Stack spacing={2} direction={mdUp ?'column' : 'column'} sx={{pl:4}}>
        <Box
          href={locationData.url}
          sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': { opacity: 0.8 }
                    }}
                    onClick={handleMapClick}
        >
          <Typography  variant={mdUp ? "body1" : "body2"}  color="text.secondary" fontWeight={400} display="inline">
            {locationData.center}            
              {locationData.address}              
              {locationData.zipCode} {locationData.city}          
          </Typography>
        </Box>

        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={libraries}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            onLoad={setMap}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </Stack>
    </Box>
  );
};

export default Location;