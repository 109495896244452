import React, { forwardRef, useImperativeHandle, useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import FieldRenderer from './FieldRenderer';
import { buildValidationSchema } from '../../utils/validationUtils';
import { getGridSizeProps } from '../../utils/layoutUtils';
import { FormProvider } from '../hook-form';

/**
 * DynamicForm Component
 *
 * @param {Object} props
 * @param {string} props.formName
 * @param {Function} props.onSubmit
 * @param {Object} props.schema
 * @param {Object} [props.defaultValues]
 * @param {string} [props.language]
 */
const DynamicForm = forwardRef(({ formName, onSubmit, schema, defaultValues = {}, language = 'en', showSubmitButton = true, onError }, ref) => {
    const validationSchema = useMemo(() => {
        if (schema) {
            return buildValidationSchema(schema.fields);
        }
        return z.object({});
    }, [schema.fields]);

    const methods = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues,
        mode: 'onSubmit',
    });


    useImperativeHandle(ref, () => ({
        getValues: methods.getValues,
        setValue: methods.setValue,
        reset: methods.reset,
        watch: methods.watch,
        formState: methods.formState,
        submitForm: () => {
            console.log('submitForm');
            methods.handleSubmit(onSubmit, onError)();
        }
    }));

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleFormSubmit = async (data) => {
        console.log('handleFormSubmit', data);
        try {
            await onSubmit(data);
            setSnackbar({ open: true, message: 'Form submitted successfully!', severity: 'success' });
            methods.reset();
        } catch (error) {
            setSnackbar({ open: true, message: 'Submission failed. Please try again.', severity: 'error' });
        }
    };

    if (!schema) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    const gridSizeProps = getGridSizeProps(schema.layout);

    return (
        <>
            <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
                    {schema.groups && schema.groups.length > 0 ? (
                        schema.groups.map((group) => (
                            <Box key={group.name} sx={{ marginBottom: 4 }}>
                                <Typography variant="h6" gutterBottom>
                                    {group.label[language] || group.label.en}
                                </Typography>
                                <Grid container spacing={3}>
                                    {group.fields.map((fieldName) => {
                                        const field = schema.fields.find((f) => f.name === fieldName);
                                        if (!field) return null;
                                        return (
                                            <Grid item key={field.name} {...gridSizeProps}>
                                                <FieldRenderer field={field} language={language} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        ))
                    ) : (
                        <Grid container spacing={3}>
                            {schema.fields.map((field) => (
                                <Grid item key={field.name} {...gridSizeProps}>
                                    <FieldRenderer field={field} language={language} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {showSubmitButton && (
                        <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={methods.formState.isSubmitting}
                            startIcon={methods.formState.isSubmitting && <CircularProgress size={20} />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                transition: 'background-color 0.3s ease',
                            }}
                        >
                            {methods.formState.isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Box>
                    )}
                </Box>
            </FormProvider>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
});

DynamicForm.propTypes = {
    formName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    defaultValues: PropTypes.object,
    language: PropTypes.string,
};

export default React.memo(DynamicForm);