import React from 'react';
import { Box, Button, Typography, Stack, Link, useTheme } from '@mui/material';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { otpValidate } from '../../../redux/slices/registration';
import useLoginFormState from '../hooks/useLoginFormState';

const OtpStep = () => {
    const theme = useTheme();
    const { mobileWithCode, otp, isLoading, isResendEnabled, timer } = useSelector((state) => state.user);
    const { actions: { updateField, submitOtp } } = useLoginFormState();

    const handleInputChange = (value) => {
        updateField('otp', value)
    };


    const handleResendOtp = () => {
        // dispatch(resendOtp());
    };

    return (
        <Box sx={{ px: 2, py: 4 }}>
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                Enter OTP
            </Typography>
            <Typography variant="subtitle1" align="center" sx={{ mb: 4 }}>
                OTP sent to {mobileWithCode}
            </Typography>
            <Stack alignItems="center" sx={{ mb: 4 }}>
                <OtpInput
                    value={otp}
                    onChange={handleInputChange}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    inputType="tel"
                    separator={<span>-</span>}
                    shouldAutoFocus
                    containerStyle={{ justifyContent: 'center' }}
                    inputStyle={{
                        width: '40px',
                        height: '40px',
                        fontSize: '20px',
                        borderRadius: '4px',
                        border: `1px solid ${theme.palette.divider}`,
                        margin: '0 5px',
                        textAlign: 'center',
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                        '&:focus': {
                            outline: 'none',
                            borderColor: theme.palette.primary.main,
                            boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
                        }
                    }}
                />
            </Stack>
            <Button
                fullWidth
                variant="contained"
                onClick={submitOtp}
                disabled={otp.length !== 6 || isLoading}
            >
                Submit
            </Button>
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                {isResendEnabled ? (
                    <>
                        <Typography variant="subtitle2">Didn't receive the OTP?</Typography>
                        <Link
                            component="button"
                            variant="subtitle2"
                            underline="none"
                            onClick={handleResendOtp}
                            sx={{ ml: 1 }}
                        >
                            Resend OTP
                        </Link>
                    </>
                ) : (
                    <Typography variant="subtitle2">
                        Resend OTP in {timer}s
                    </Typography>
                )}
            </Stack>
        </Box>
    );
};

export default OtpStep;
