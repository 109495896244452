// useRegistration.js
import { useCallback } from 'react';
import { registerEvent } from '../../../redux/slices/eventDetails';
import { useDispatch, useSelector } from '../../../redux/store';
import { 
    updateFormData as updateFormDataAction,
    resetFormData,
    setCurrentStepById,
    updateSteps,
    setEventId as setEventIdAction,
    setTournamentData as setTournamentDataAction
} from '../../../redux/slices/tournamentRegistrationSlice';


const useRegistration = () => {
    const dispatch = useDispatch();
    const { formData, tournamentData, eventId } = useSelector((state) => state.eventRegistration);
    const { currentPlayer } = useSelector((state) => state.user);
    const tournamentRegistration = useSelector((state) => state.eventRegistration);
    const { eventDetailsData } = useSelector((state) => state.eventDetails);
    
    // Initialize steps if they don't exist
    const { stepper = { currentStepId: 'basic-details', steps } } = tournamentRegistration || {};
    const { currentStepId, steps } = stepper;

    const getCurrentStepIndex = useCallback(() => 
        steps.findIndex(step => step.id === currentStepId)
    , [currentStepId, steps]);

    // Memoize navigation functions to prevent unnecessary re-renders
    const nextStep = useCallback(() => {
        const currentIndex = getCurrentStepIndex();
        if (currentIndex < steps.length - 1) {
            dispatch(setCurrentStepById(steps[currentIndex + 1].id));
        }
    }, [dispatch, getCurrentStepIndex, steps]);
    const prevStep = useCallback(() => {
        const currentIndex = getCurrentStepIndex();
        if (currentIndex > 0) {
            dispatch(setCurrentStepById(steps[currentIndex - 1].id));
        }
    }, [dispatch, getCurrentStepIndex, steps]);

    // Memoize form data updates
    const updateFormData = useCallback((data) => {
        dispatch(updateFormDataAction(data));
    }, [dispatch]);

    const updateTournamentData = useCallback((data) => {
        dispatch(setTournamentDataAction(data));
    }, [dispatch]);

    // Memoize reset function
    const resetData = useCallback(() => {
        dispatch(resetFormData());
    }, [dispatch]);

    // Memoize registration submission
    const submitRegistration = useCallback(async ( enqueueSnackbar, navigate ) => {
        if (!currentPlayer) {
            enqueueSnackbar('Player information not found', { variant: 'error' });
            return;
        }

        const registerData = {
            payload: {
                eventId,
                subEvents: eventDetailsData?.category === 'tournament' ? 
                tournamentData.selectedEvents.map((subEventId) => {
                    const subEvent = eventDetailsData?.sub_events?.find(e => e.id === subEventId);
                    
                    return {
                        id: subEventId,
                        players: subEvent.isTeamEvent ? [
                            {
                                id: currentPlayer.id,
                                formData
                            },
                            tournamentData.partnerDetails[subEventId] && {
                                ...(tournamentData.partnerDetails[subEventId]?.id ? { id: tournamentData.partnerDetails[subEventId].id } : tournamentData.partnerDetails[subEventId])
                            }
                        ] : [
                            {
                                id: currentPlayer.id,
                                formData
                            }
                        ]
                    };
                }) : [{
                    id: eventDetailsData?.sub_events?.[0]?.id,
                    players: [{
                        id: currentPlayer.id,
                        formData: {
                            formData
                        }
                    }]
                }],
                playerId: currentPlayer.id,
            },
            enqueueSnackbar
        };

        try {
            const response = await dispatch(registerEvent(registerData)).unwrap();
            if (response?.data?.redirectPath) {
                navigate(response.data.redirectPath);
            }
        } catch (error) {
            enqueueSnackbar(error?.message || 'Registration failed', { variant: 'error' });
        }
    }, [currentPlayer, dispatch, eventDetailsData, formData, tournamentData, eventId]);

    const updateStepsState = useCallback((newSteps) => {
        dispatch(updateSteps(newSteps));
    }, [dispatch]);

    const setEventId = useCallback((eventId) => {
        dispatch(setEventIdAction(eventId));
    }, [dispatch]);

    return {
        currentStep: getCurrentStepIndex(),
        nextStep,
        prevStep,
        updateFormData,
        updateTournamentData,
        resetData,
        submitRegistration,
        updateStepsState,
        setEventId,
    };
};

export default useRegistration;