import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_STATUS, KRIDA_API } from '../../config';
import axios from '../../utils/axios'

const initialState = {
    currentOrgId: '',
    orgDataLoader: API_STATUS.IDLE,
    orgData: [],
    clubDataAPIStatus: API_STATUS.IDLE,
    clubData: [],
}
const orgSlice = createSlice({
    name: 'org',
    initialState,
    reducers: {
        updateCurrentOrgId: (state, action) => {
           state.currentOrgId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrgData.pending, (state) => {
            state.orgDataLoader = API_STATUS.LOADING;
        })
        builder.addCase(fetchOrgData.fulfilled, (state, action) => {
            state.orgDataLoader = API_STATUS.SUCCEEDED;
            state.orgData = action.payload?.data;
        })
        builder.addCase(fetchOrgData.rejected, (state, action) => {
            state.orgDataLoader = API_STATUS.FAILED;
        })
        builder.addCase(fetchClubData.pending, (state) => {
            state.clubDataAPIStatus = API_STATUS.LOADING;
            state.clubData = []
        })
        builder.addCase(fetchClubData.fulfilled, (state, action) => {
            state.clubDataAPIStatus = API_STATUS.SUCCEEDED;
            state.clubData = action.payload?.data;
        })
        builder.addCase(fetchClubData.rejected, (state, action) => {
            state.clubDataAPIStatus = API_STATUS.FAILED;
        })
    }
})

export const fetchOrgData = createAsyncThunk('fetchOrgData', async (orgId, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`${KRIDA_API}kl/org/${orgId}`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
})

export const fetchClubData = createAsyncThunk('fetchClubData', async (clubId, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`${KRIDA_API}kl/club/${clubId}`, 
            { headers: { 'content-type': 'application/json' }})
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
})

export const fetchOrgCenters = createAsyncThunk('fetchOrgCenters', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}config`, data, 
            { headers: { 'content-type': 'application/json' }})
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
})

export const fetchOrgTrainers = createAsyncThunk('fetchOrgTrainers', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}config`, data, 
            { headers: { 'content-type': 'application/json' }})
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
})


export const { updateCurrentOrgId } = orgSlice.actions

export default orgSlice.reducer;