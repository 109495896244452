import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Typography, Stack } from '@mui/material';
// hooks
import { useDispatch , useSelector} from 'react-redux';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
import Logo from '../../components/Logo';
import GetAppModal from '../../components/GetApp';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import Searchbar from '../dashboard/header/Searchbar';
import useLoginFormState from '../../components/LoginForm/hooks/useLoginFormState';
import AccountPopover from '../dashboard/header/AccountPopover';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

const MainHeader = () => {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  const [openGetAppModal, setOpenGetAppModal] = useState(false);
  const dispatch = useDispatch();

  const { actions } = useLoginFormState();
  const { user } = useSelector(state => state.user);

  const onClose = (val) => {
    setOpenGetAppModal(val);
  }

  return (
  <>
    {openGetAppModal && <GetAppModal showModal={openGetAppModal} modalClose={onClose}/>}
    <AppBar position={'fixed'} sx={{ bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          // maxWidth={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
            <Stack alignItems={'center'} flexDirection={'row'}>{<Logo sx={{ mr: 0.5, transform: !isDesktop ? 'scale(0.6)' : '' }} />}<Typography pl={.5} color={'text.primary'} >Krida</Typography></Stack>

          {isDesktop && <Box sx={{ flexGrow: 1 }} />}

          {/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
          {/* {isDesktop && <Searchbar />} */}
          {isDesktop && !user?.id && <Button
            variant="text"
            target="_blank"
            rel="noopener"
            href=""
            sx={{ marginRight: 1 }}
            onClick={()=>{
              actions.setLoginFormOpen(true);
            }}
          >
            Login
          </Button>}

          {isDesktop && !user?.id && <Button
            variant="text"
            target="_blank"
            rel="noopener"
            onClick={()=>{
              actions.openAddProfilePopup();
              actions.setActiveStep(2);
            }}
            sx={{ marginRight: 1 }}
          >
            Sign up
          </Button>}

          {/* <Button
            variant="text"
            target="_blank"
            rel="noopener"
            onClick={()=>{
                 setOpenGetAppModal(true);
            }}
            href=""
          >
            Get App
          </Button> */}
            <AccountPopover />
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
    </>
  );
}
export default MainHeader
