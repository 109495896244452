import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch, store } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  couponApplied: false,
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  estimates: {},
  dataFetched: false,
  checkout: {
    activeStep: 0,
    cart: [],
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getEstimatesSuccess(state, action) {
      state.isLoading = false;
      state.estimates = action.payload;
      state.estimates.type = 'plan'
      state.dataFetched = true
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subTotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subTotal = subTotal;
      state.checkout.total = subTotal - discount;
    },

    addCart(state, action) {
      const estimates = action.payload;
      const subscriptionItems = estimates?.subscriptionItems?.[0]
      const discountItems = estimates?.discounts?.[0]

      const product = {
        "id": subscriptionItems?.itemPriceId,
        "name": subscriptionItems?.item?.externalName,
        "cover": "https://media.istockphoto.com/id/1047918166/video/people-play-badminton.jpg?s=640x640&k=20&c=S7MNa9Gxm_nktyl86Skj6T1IjjiPgGe2AtEVan6w6Wk=",
        "price": subscriptionItems?.unitPrice,
        "color": "#FFC0CB",
        "quantity": subscriptionItems?.quantity,
        "subTotal": subscriptionItems?.unitPrice,
        "itemType": 'plan',
        "discount": discountItems
      }
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted && product.itemType !== 'plan') {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
      state.checkout.total = estimates?.invoice?.total;
      state.checkout.subTotal = estimates?.invoice?.subTotal;
      state.checkout.discount = (estimates.invoice.total - estimates.invoice.subTotal) || 0;
      state.checkout.credits = estimates?.invoice?.creditsApplied;

    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCoupon(state) {
      state.couponApplied = false;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subTotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
      state.dataFetched = false
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subTotal - discount;
      state.couponApplied = true
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subTotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  resetCoupon,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function fetchInvoiceDetails() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
      dispatch(addCart(response.data.product || {}))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name },
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchEstimates(body) {
  const { enqueueSnackbar } = body
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: response } = await axios.post('/s/cart_estimate', body);
      dispatch(slice.actions.getEstimatesSuccess(response.data));

      if (response?.data?.discounts?.[0]?.entityId && response?.data?.discounts?.[0]?.entityId === body?.coupons?.[0]?.id) {
        const value = response?.data?.discounts?.[0]?.amount
        dispatch(applyDiscount(value));
      } else
        dispatch(resetCoupon())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      if(enqueueSnackbar) enqueueSnackbar('Something went wrong', { variant: 'error' })
    }
  };
}
