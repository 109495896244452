import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'

const initialState = {
    loader: false,
    invoiceData: {},
    error: {},
    similarPlanData: [],
    invoiceChangeData: {},
    similarPlanPriceError: {},
    invoiceChangeError: {},
    invoiceDetailsFetched: false,
    invoiceChangeDone: false
}
const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        resetInvoiceDetails: (state) => {
            state.error = {}
            state.loader = false
            state.invoiceData = {}
            state.similarPlanData = []
            state.invoiceChangeData = {}
            state.invoiceChangeError = {}
            state.similarPlanPriceError = {}
            state.invoiceDetailsFetched = false
            state.invoiceChangeDone = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInvoiceDetails.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(fetchInvoiceDetails.fulfilled, (state, action) => {
            state.loader = false;
            const data = action?.payload?.data;
            state.invoiceData = data;
            state.invoiceDetailsFetched = true
        });
        builder.addCase(fetchInvoiceDetails.rejected, (state, action) => {
            state.loader = false;
            state.error = action?.payload
        });
        builder.addCase(fetchInvoiceSimilarPlanPricing.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(fetchInvoiceSimilarPlanPricing.fulfilled, (state, action) => {
            state.loader = false;
            const data = action?.payload?.data?.rows;
            state.similarPlanData = data;
        });
        builder.addCase(fetchInvoiceSimilarPlanPricing.rejected, (state, action) => {
            state.loader = false;
            state.similarPlanPriceError = action?.payload
        });
        builder.addCase(invoiceChange.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(invoiceChange.fulfilled, (state, action) => {
            state.loader = false;
            state.invoiceChangeData = action.payload
            state.invoiceChangeDone = true
        });
        builder.addCase(invoiceChange.rejected, (state, action) => {
            state.loader = false;
            state.invoiceChangeError = action?.payload
        });
    }
})

export const fetchInvoiceDetails = createAsyncThunk('invoice', async (invoiceId, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`i/${invoiceId}`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error)
        return thunkApi.rejectWithValue(error)
    }
});

export const fetchInvoiceSimilarPlanPricing = createAsyncThunk('fetchInvoiceSimilarPlanPricing', async (entityId, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`p/plan_price/${entityId}/similarPlanPrice`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error)
        return thunkApi.rejectWithValue(error)
    }
});

export const invoiceChange = createAsyncThunk('invoiceChange', async (data, thunkApi) => {
    const { enqueueSnackbar, invoiceId, itemPriceId } = data
    try {
        const { data: responseData } = await axios.post(`i/${invoiceId}/invoiceChange/${itemPriceId}`,
            { headers: { 'content-type': 'application/json'} })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error)
        enqueueSnackbar('Something went wrong while changing invoice', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});


export const { resetInvoiceDetails } = invoiceSlice.actions

export default invoiceSlice.reducer;