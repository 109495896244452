import { Box, Card, Chip, Container, Divider, Grid, Icon, Link, ListItem, ListItemText, Paper, Stack, Tab, Tabs, Typography, List, ListItemIcon, Button, Fab, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { m } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DynamicAspectRatioImage from '../../components/DynamicAspectRatioImage';
import useResponsive from '../../hooks/useResponsive';
import { fNumber, fDate, fCurrency } from '../../utils/formatNumber';
import TournamentTimeline from './TournamentTimeline';
import Iconify from '../../components/Iconify';
import MainLayout from '../../layouts/main';
import { CustomTabs } from '../../components/custom-tabs';
import { fetchEventDetail,fetchRegistrationStatus } from '../../redux/slices/eventDetails';
import { fetchOrgConfig, resetConfig } from '../../redux/slices/orgConfig';
import convertToBase64 from '../../utils/convertToBase64';
import LoadingScreen from '../../components/LoadingScreen';
import Page404 from '../Page404';
import { API_STATUS } from '../../config';


const TABS = [
    {
        value: 'overview',
        label: 'Overview'
    },
    {
        value: 'draws',
        label: 'Draws'
    },
    {
        value: 'schedule',
        label: 'Schedule'
    },
    {
        value: 'results',
        label: 'Results'
    },
    {
        value: 'players',
        label: 'Players'
    },
    {
        value: 'matches',
        label: 'Matches'
    },
    {
        value: 'news',
        label: 'News'
    },
    {
        value: 'photos',
        label: 'Photos'
    },
    {
        value: 'videos',
        label: 'Videos'
    },
    {
        value: 'sponsors',
        label: 'Sponsors'
    }
];
const CategoryBox = ({ children }) => (
    <Chip
        label={children}
        color='secondary'

    />
);

const EventBox = ({ children, startDate, endDate, price }) => (
    <Tooltip
        title={
            <Box sx={{ p: 1}}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Iconify icon="mdi:calendar-month" width={16} height={16} />
                    <Typography variant="caption">
                     {startDate && endDate ? `${startDate} to ${endDate}` : 'Dates not available'}
                    </Typography>
                </Stack>
                <br />
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Iconify icon="mdi:currency-inr" width={16} height={16} />
                    <Typography variant="caption">
                        Price: {price || 'Price not available'}
                    </Typography>
                </Stack>
            </Box>
        }
        sx={{
            bgcolor: 'rgba(0, 0, 0, 0.87)'
        }}
    >
        <Chip
            label={children}
            color='secondary'
            variant="soft"
        />
    </Tooltip>
);

const CategoryRow = ({ category, events }) => (
    <Box mb={1}>
        <Grid sx={{}} flexDirection={"row"} flexWrap={"nowrap"} container alignItems="center" spacing={2}>
            <Grid item>
                <CategoryBox>{category}</CategoryBox>
            </Grid>
            <Grid item xs>
                <Box display="flex" flexWrap="nowrap" gap={.5} sx={{ overflowX: 'auto' }}>
                    {events.map((event, index) => (
                        <EventBox
                            key={index}
                            startDate={event.startDate}
                            endDate={event.endDate}
                            price={event.price}
                        >
                            {event.shortCode}
                        </EventBox>
                    ))}
                </Box>
            </Grid>
        </Grid>
        <Divider sx={{ my: 1.5 }} />
    </Box>
);
const NoticeBanner = ({ message }) => {
    const mdUp = useResponsive('up', 'md');
    return (
    <Box 
        sx={{ 
            bgcolor: 'info.lighter', 
          p:2,
          my: 2,
            borderRadius: 1,
            display: 'flex',
            gap: 1,
            textAlign: 'left'
     
        }}
    >
        <Iconify 
            icon="mdi:information" 
            sx={{ 
                color: 'info.main',
                width: 24,
                height: 24,
                flexShrink: 0
            }} 
        />
        <Typography variant={mdUp ? "subtitle2" : "subtitle2"} color="info.dark">
            {message}
        </Typography>
    </Box>
    );
};

const EventList = ({ eventDetailsData }) => {
    const ageGroups = [...new Set(eventDetailsData?.sub_events?.map(event => event.ageGroup))];

    return (
        <Box width={'100%'}>
            <Paper sx={{ mt:2, p: 3, width: '100%' }}>
                <Typography variant="h6" fontWeight={600} my={2}> Events </Typography>
                {ageGroups.map((ageGroup, index) => {
                    const events = eventDetailsData?.sub_events
                        .filter(event => event.ageGroup === ageGroup)
                        .map(event => ({
                            shortCode: event.metadata.shortCode,
                            startDate: fDate(event.startDate || eventDetailsData.startDate),
                            endDate: fDate(event.endDate || eventDetailsData.endDate),
                            price: fCurrency(event.priceConfig.price)
                        }));

                    return (
                        <CategoryRow
                            key={index}
                            category={ageGroup.toUpperCase()}
                            events={events}
                        />
                    );
                })}
            </Paper>
        </Box>
    );
};

const TOURNAMENT_RULES = [
    "Please carry age proof certificate.",
    "Reporting time 30 minutes before the scheduled time.",
    "Each player can participate in a maximum of 3 events only.",
    "Referee's decision will be final.",
    "Each player can be accompanied by 2 people only inside the badminton hall.",
    "Minimum of 6 entries required to conduct each event.",
    "Feather shuttles will be used.",
    "Trophies/Medals & Certificates will be given to all the winners & runners-up.",
    "Participation certificate will be given to all the participants on request.",
    "For doubles event first player can register and mention the partner's name and DOB.",
    "Entries accepted on Play Matches and Using Razorpay link.",
    "Withdrawal last Date: 27th November, 2024, 5 pm. (withdrawal accepted only through mail, the mail-id has been mentioned below).",
    "Fixtures will be published on Tournamentsoftware.com on 29th November 2024, 10am.",
    "Entry fees once paid will not be refunded under any circumstances.",
    "Participants must meet the eligibility criteria specified in the tournament guidelines. Proof of eligibility may be required.",
    "All registrations must be completed by the specified deadline. Late entries will not be accepted.",
];

const RegistrationCard = ({ registration }) => (
  <Paper 
    sx={{ 
      p: 2,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'background.neutral'
    }}
  >
    {/* Event */}
    <Stack spacing={0.5} mb={1}>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        EVENT
      </Typography>
      <Typography variant="subtitle2" color="text.primary">
        {registration.metadata?.subEventName}
      </Typography>
    </Stack>

    {/* Team */}
    {/* <Stack spacing={0.5} mb={1}>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        TEAM
      </Typography>
      <Typography variant="body2" color="text.primary">
        {registration.team?.name}
      </Typography>
    </Stack> */}

    {/* Players */}
    <Stack spacing={0.5}>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        PLAYERS
      </Typography>
      <Stack spacing={0.25}>
        {registration.metadata?.players?.map((player, playerIndex) => (
          <Typography 
            key={playerIndex} 
            variant="body2" 
            color="text.primary"
          >
            {player.name}
          </Typography>
        ))}
      </Stack>
    </Stack>

    {/* Status Chip */}
    <Box sx={{ mt: 'auto', pt: 1.5 }}>
      <Chip
        label={registration.status}
        color={registration.status === 'confirmed' ? 'success' : 'warning'}
        size="small"
        variant="filled"
        sx={{ 
          height: 20,
          textTransform: 'capitalize',
          '& .MuiChip-label': { px: 1, py: 0.25 }
        }}
      />
    </Box>
  </Paper>
);

const RegistrationStatus = ({ registrationData }) => {
  if (!registrationData?.count) return null;

  return (
    <Box sx={{ mt: 1.5, width: '100%' }}>
      <Grid container spacing={1.5}>
        {registrationData.rows.map((registration, index) => (
          <Grid item xs={12} md={6} key={registration.id}>
            <RegistrationCard registration={registration} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const TournamentDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tId } = useParams();
    const [currentTab, setCurrentTab] = useState('overview');
    const { eventDetailsData, eventDetailsLoader, error,registrationData } = useSelector((state) => state.eventDetails);
    const { configLoader, config, configError } = useSelector((state) => state.orgConfig);
    const [isInvalid, setIsInvalid] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    const mdUp = useResponsive('up', 'md');
    const {  registrationLoader, registrationError } = useSelector((state) => state.registration);
    const { currentPlayer } = useSelector((state) => state.user);

 

    // Initialize data
    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchEventDetail(tId)).unwrap();
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setIsInvalid(true);
            }
        };
        
        fetchData();
    }, [dispatch, tId, currentPlayer?.id]);

    useEffect(() => {
        const fetchRegistrationData =  () => {
            if (currentPlayer?.id) {
                 dispatch(fetchRegistrationStatus({
                    playerId: currentPlayer.id,
                    eventId: tId
                }));
            }
        };
    
        fetchRegistrationData();
    }, [currentPlayer?.id, dispatch, tId]);

    useEffect(() => {
        const fetchConfig = () => {
            if (eventDetailsData?.id) {
                const convertedBase64Code = convertToBase64({
                    entity: 'event',
                    entityId: eventDetailsData.id
                });

                try {
                    dispatch(fetchOrgConfig({
                        q: convertedBase64Code
                    }));
                } catch (error) {
                    console.error('Config Error:', error);
                }
            }
        };

        fetchConfig();
        return () => {
            dispatch(resetConfig());
        };
    }, [eventDetailsData?.id, dispatch]);

    // Measure content height for expandable rules section
    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, []);

    const handleChangeTab = useCallback((event, newValue) => {
        setCurrentTab(newValue);
    }, []);

    const renderContent = () => {
        if (eventDetailsLoader === 'loading') {
            return <LoadingScreen />;
        }
        if (isInvalid ||
            (error && eventDetailsLoader === 'failed') ||
            (!eventDetailsData?.id && eventDetailsLoader === 'succeeded')) {
            return <Page404 />;
        }
        const minPrice = Math.min(...eventDetailsData?.sub_events?.map(event => event.priceConfig.price) || [0]);

        return (
            <Container maxWidth={"lg"} align="center" sx={{ maxHeight: "100%", minHeight: "540"}}>
                {configLoader ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <Paper sx={{
                            paddingTop: mdUp ? 6 : 3,
                            position: "sticky",
                            top: mdUp ? 64 : 48,
                            bgcolor: 'background.paper',
                            zIndex: 1,
                            pb: 1
                        }}>
                            <Stack flexDirection={"row"} p={mdUp ? 4 : 1.5}>
                                <Stack justifyContent={"start"} alignItems={'center'}>
                                    <DynamicAspectRatioImage
                                        size={mdUp ? 100 : 64}
                                        uri={config?.organization?.metadata?.logo}
                                    />
                                </Stack>
                                <Stack style={{}} pl={mdUp ? 4 : 1.5} justifyContent={'flex-start'} alignItems={'flex-start'} height={"100%"} flexGrow={1} flexDirection={"column"} spacing={1}>
                                    <Typography textAlign={"left"} variant={mdUp ? "h4" : "h5"}>
                                        {eventDetailsData?.name}
                                    </Typography>
                                    <Stack direction={"row"} spacing={1}>
                                        <Iconify
                                            icon="mdi:map-marker"
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                color: 'text.primary',
                                                flexShrink: 0
                                            }}
                                        />
                                        <Typography 
                                            textAlign={"left"} 
                                            variant={mdUp ? "subtitle1" : "subtitle2"} 
                                            color="text.primary"
                                        >
                                            {config?.address?.fullAddress}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <Iconify
                                            icon="mdi:calendar-month"
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                color: 'text.primary'
                                            }}
                                        />
                                        <Typography 
                                            textAlign={"left"} 
                                            variant={mdUp ? "subtitle1" : "subtitle2"} 
                                            color="text.primary"
                                        >
                                            {fDate(eventDetailsData?.startDate)} to {fDate(eventDetailsData?.endDate)}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <Iconify
                                            icon="mdi:currency-inr"
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                color: 'text.primary'
                                            }}
                                        />
                                        <Typography 
                                            textAlign={"left"} 
                                            variant={mdUp ? "subtitle1" : "subtitle2"} 
                                            color="text.primary"
                                        >
                                            {fCurrency(minPrice)}
                                            <Typography variant="caption" color="text.primary">
                                                /onwards
                                            </Typography>
                                        </Typography>
                                    </Stack>
                                    <Stack direction={mdUp ? "row" : "column"} spacing={1}>
                                    <Button
                                        variant="contained"
                                        size={mdUp ? "large" : "small"}
                                        sx={{ 
                                            mt: 1, 
                                            minWidth: 240,
                                        }}
                                        fullWidth={!mdUp}
                                        onClick={() => navigate(`register`)}
                                        disabled={registrationData?.count > 0}
                                        startIcon={registrationData?.count > 0 ? <CheckCircleIcon /> : null}
                                    >
                                        {registrationData?.count > 0 ? 'Already Registered' : 'Register'}
                                    </Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Paper>

                        <Grid container>
                        {registrationData?.count > 0 && (
                            <Grid item xs={12}>
                                <RegistrationStatus registrationData={registrationData} />
                            </Grid>
                        )}
                            <Grid item xs={12} md={8}>
                                    <Grid container>
                                        <NoticeBanner message="PRACTICE TIMING FOR Tomorrow Morning (29-NOV-2024) : 05:30 AM TO 07:00 AM" />
                                        <TournamentTimeline />
                                        <EventList eventDetailsData={eventDetailsData} />
                                    </Grid>
                            </Grid>

                            <Grid item xs={12} md={4} py={4}>
                                <Paper variant="elevation" elevation={12}>
                                    <Box p={2}>
                                        <Typography variant="subtitle2">Organization</Typography>
                                        <Box pt={2}>
                                            <Typography variant={mdUp ? "h6" : "body1"} fontWeight={600}>
                                                {config?.organization?.name}
                                            </Typography>
                                            <Typography
                                                pt={1}
                                                variant="body2"
                                                display={'flex'}
                                                justifyContent={"center"}
                                                textAlign={"center"}
                                                alignItems={"center"}
                                            >
                                                <Iconify
                                                    icon={'streamline:send-email'}
                                                    width={16}
                                                    height={16}
                                                    marginRight={.5}
                                                />
                                                <a href="mailto:connect@padukonesportsmanagement.com">
                                                    <Typography variant="caption">
                                                        connect@padukonesportsmanagement.com
                                                    </Typography>
                                                </a>
                                            </Typography>
                                            <Typography
                                                pt={1}
                                                variant="body2"
                                                display={'flex'}
                                                justifyContent={"center"}
                                                textAlign={"center"}
                                                alignItems={"center"}
                                            >
                                                <Iconify
                                                    icon={"line-md:phone-call-loop"}
                                                    width={16}
                                                    height={16}
                                                    marginRight={.5}
                                                /> : +91 81974 58790
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>

                                <Paper variant="elevation" sx={{ marginTop: 4 }} elevation={12}>
                                    <Box p={2}>
                                        <Stack alignItems={"center"} position={"relative"}>
                                            <Stack flex={1}>
                                                <Typography variant="subtitle2">Venue</Typography>
                                            </Stack>
                                            <Stack right={0} direction={"row"} alignItems={"center"} position={"absolute"}>
                                                <Iconify icon={'fluent:open-16-regular'} width={16} height={16} />
                                                <Link
                                                    pl={.5}
                                                    href={"google.com"}
                                                    target="_blank"
                                                    rel="noopener"
                                                    underline="none"
                                                    color="inherit"
                                                    textAlign={"center"}
                                                >
                                                    <Typography variant="caption">View on map</Typography>
                                                </Link>
                                            </Stack>
                                        </Stack>
                                        <Box pt={2}>
                                            <Typography variant={mdUp ? "h6" : "body1"} fontWeight={600}>
                                                {config?.name}
                                            </Typography>
                                            <Typography variant="body2">
                                                {config?.address?.fullAddress}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>

                                <Paper variant="elevation" sx={{ marginTop: 4 }} elevation={12}>
                                    <Box p={2}>
                                        <Typography variant="subtitle2" gutterBottom>Contact</Typography>
                                        {eventDetailsData?.metadata?.contact?.map((contact, index) => (
                                            <Box key={index} sx={{ mt: 1 }} alignItems={"center"}>
                                                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                    {contact.name}
                                                </Typography>

                                                <Stack spacing={1} alignItems={"center"}>
                                                    <Typography
                                                        variant="body2"
                                                        display="flex"
                                                        alignItems="center"
                                                        sx={{ color: 'primary.main' }}
                                                    >
                                                        <Iconify
                                                            icon={'streamline:send-email'}
                                                            width={16}
                                                            height={16}
                                                            sx={{ mr: 1 }}
                                                        />
                                                        <a
                                                            href={`mailto:${contact.email}`}
                                                            style={{
                                                                color: 'inherit',
                                                                textDecoration: 'none'
                                                            }}
                                                        >
                                                            {contact.email}
                                                        </a>
                                                    </Typography>

                                                    <Typography
                                                        variant="body2"
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Iconify
                                                            icon={"line-md:phone-call-loop"}
                                                            width={16}
                                                            height={16}
                                                            sx={{ mr: 1 }}
                                                        />
                                                        :
                                                        <Typography variant="body2">
                                                            +91 {contact.mobile}
                                                        </Typography>
                                                    </Typography>
                                                </Stack>

                                                {index < eventDetailsData.metadata.contact.length - 1 && (
                                                    <Divider sx={{ my: 2 }} />
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                </Paper>

                                <Box sx={{ marginTop: 4 }} elevation={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        onClick={() => navigate(`register`)}
                                    >
                                        Register
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Paper variant="elevation" sx={{ my: 4 }}>
                                <Box ref={contentRef} sx={{
                                    maxHeight: expanded ? 'none' : 200,
                                    overflow: 'hidden',
                                    position: 'relative'
                                }}>
                                    <Typography variant="h6" sx={{ my: 2 }}>Rules & Regulations</Typography>
                                    <List>
                                        {TOURNAMENT_RULES.map((rule, index) => (
                                            <ListItem key={index}>
                                                <ListItemIcon>
                                                    <CheckCircleIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={rule} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                                {contentHeight > 200 && (
                                    <Button
                                        onClick={() => setExpanded(!expanded)}
                                        sx={{ mt: 2 }}
                                    >
                                        {expanded ? 'Show Less' : 'Read More'}
                                    </Button>
                                )}
                            </Paper>
                        </Grid>
                    </>
                )}
            </Container>
        );
    };

    return (
        <MainLayout>
            {renderContent()}
        </MainLayout>
    );
};

export default TournamentDetails;
