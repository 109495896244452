import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'
import { API_STATUS, KRIDA_API } from '../../config';

const initialState = {
    registrationData: {},
    eventDetailsLoader: 'idle',
    eventDetailsData: {},
    error: {},
    limit: 20,
    registrationStatus: 'idle',
    registrationError: null
}

const eventDetailsSlice = createSlice({
    name: 'eventDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(registerEvent.pending, (state) => {
                state.registrationStatus = 'loading';
                state.registrationError = null;
            })
            .addCase(registerEvent.fulfilled, (state, action) => {
                state.registrationStatus = 'succeeded';
                state.registrationError = null;
                state.registrationData = action.payload;
            })
            .addCase(registerEvent.rejected, (state, action) => {
                state.registrationStatus = 'failed';
                state.registrationError = action.payload;
                state.registrationData = null;
            })
            .addCase(fetchEventDetail.pending, (state) => {
                state.eventDetailsLoader = 'loading';
                state.error = null;
            })
            .addCase(fetchRegistrationStatus.pending, (state) => {
                state.registrationStatus = 'loading';
                state.registrationError = null;
            })
            .addCase(fetchRegistrationStatus.fulfilled, (state, action) => {
                state.registrationStatus = 'succeeded';
                state.registrationError = null;
                state.registrationData = action.payload;
            })
            .addCase(fetchRegistrationStatus.rejected, (state, action) => {
                state.registrationStatus = 'failed';
                state.registrationError = action.payload;
                state.registrationData = null;
            })
            .addCase(fetchEventDetail.fulfilled, (state, action) => {
                state.eventDetailsLoader = 'succeeded';
                state.eventDetailsData = action.payload;
                state.error = null;
            })
            .addCase(fetchEventDetail.rejected, (state, action) => {
                state.eventDetailsLoader = 'failed';
                state.error = action.payload;
            });
    }
});


export const registerEvent = createAsyncThunk('registerEvent', async (data, thunkApi) => {
    const { payload, enqueueSnackbar } = data;
    try {
        const token = localStorage.getItem('token');
        
        const { data: responseData } = await axios.post(
            `${KRIDA_API}e/r`, 
            payload,
            { 
                headers: { 
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`
                } 
            }
        );
        
        enqueueSnackbar('Almost done! Proceed to payment to secure your registration', { variant: 'success', autoHideDuration: 3000 });
        return responseData;
    } catch (e) {
        const error = e?.error;
        console.log(error);
        enqueueSnackbar(error?.message || 'Something went wrong. Try again', { variant: 'error', autoHideDuration: 10000 });
        return thunkApi.rejectWithValue(error);
    }
});

export const fetchEventDetail = createAsyncThunk('eventDetails', async (eventId, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`${KRIDA_API}/event/${eventId}`,
            {}, { headers: { 'content-type': 'application/json' } })
        return responseData.data
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const fetchRegistrationStatus = createAsyncThunk(
    'fetchRegistrationStatus', 
    async ({playerId, eventId}, thunkApi) => {
        try {
            const token = localStorage.getItem('token');
            const {data: response} = await axios.get(
                `${KRIDA_API}e/r/player?playerId=${playerId}&eventId=${eventId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            return response.data;
            
        } catch (e) {
            console.error('Registration status fetch error:', e);
            return thunkApi.rejectWithValue(e);
        }
    }
);


export default eventDetailsSlice.reducer;