import BasicDetails from './BasicDetails';
import VerifyForm from './VerifyForm';
import TournamentRegister from './TournamentRegister';

export const STEP_CONFIGURATIONS = {
  'basic-details': {
    label: 'Registration • 2 mins',
    id: 'basic-details',
    buttons: [
      {
        label: 'Next',
        variant: 'contained',
        action: 'next',
        show: true,
        flex: 1,
      },
    ],
    getComponent: (ref) => <BasicDetails ref={ref} />,
  },
  'sub-event-selection': {
    label: 'Select Event • 1 min',
    id: 'sub-event-selection',
    buttons: [
      {
        label: 'Back',
        variant: 'outlined',
        action: 'back',
        show: true,
        flex: 1,
      },
      {
        label: 'Next',
        variant: 'contained',
        action: 'next',
        show: true,
        flex: 1,
      },
    ],
    getComponent: (ref) => <TournamentRegister ref={ref} />,
  },
  'verify-form': {
    label: 'Verify • 1 min',
    id: 'verify-form',
    buttons: [
      {
        label: 'Back',
        variant: 'outlined',
        action: 'back',
        show: true,
        flex: 1,
      },
      {
        label: 'Pay Now',
        variant: 'contained',
        action: 'next',
        show: true,
        flex: 1,
      },
    ],
    getComponent: (ref) => <VerifyForm ref={ref} />,
  },
};

export const getStepsForEventType = (eventType) => {
  if (eventType === 'tournament') {
    return [
      STEP_CONFIGURATIONS['basic-details'],
      STEP_CONFIGURATIONS['sub-event-selection'],
      STEP_CONFIGURATIONS['verify-form'],
    ];
  }
  
  return [
    STEP_CONFIGURATIONS['basic-details'],
    STEP_CONFIGURATIONS['verify-form'],
  ];
}; 