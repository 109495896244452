import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import { Button, CardActionArea, Stack, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { GOOGLE_MAP_API_KEY } from '../../config';

const MapContainer = ({ locationArray, isAdding, getLocation, registerHereCallback }) => {

    const [selected, setSelected] = useState({});
    const [currentPosition, setCurrentPosition] = useState({});

    const markerRef = useRef(null);
    const defaultCenter = {
        lat: 15.379828222362582, lng: 75.13392125397225
    }

    const options = {
        imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    }

    function createKey(location) {
        return location.lat + location.lng
    }

    const onSelect = item => {
        setSelected(item);
    }

    const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const currentPosition = {
            lat: latitude,
            lng: longitude
        }
        setCurrentPosition(currentPosition);
    }
    const onMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setCurrentPosition({ lat, lng })
    };

    const footer = (
        <div className="footer">
            {/* <div className="inner-footer">
                <Button variant="contained" color="primary" onClick={() => getLocation(currentPosition)}>
                    Go to Next center
                </Button>
            </div> */}
        </div>
    );

    const mapStyles = () => {
        if (!isAdding) {
            return {
                marginTop: "-20px",
                height: "100vh",
                width: "100%",
                borderRadius: "24px"
            }
        }
        return {
            marginTop: "-20px",
            height: "80vh",
            width: "100%",
            borderRadius: "24px"
        }
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    }, [])

    return (
        <>
            <LoadScript
                id="script-loader"
                googleMapsApiKey={GOOGLE_MAP_API_KEY}
                region="IN"
            >
                <GoogleMap
                    id='krida-map'
                    mapContainerStyle={mapStyles()}
                    draggable
                    mapTypeId={'roadmap'}
                    zoom={6}
                    disableDefaultUI
                    streetViewControl={false}
                    defaultOptions={{ fullscreenControl: false, disableDefaultUI: true, streetViewControl: false }}
                    center={currentPosition.lat ? currentPosition : defaultCenter}
                >
                    <MarkerClusterer options={options} title="PSMT">
                        {(clusterer) =>
                            locationArray.map((location) => (
                                <Marker key={createKey(location.location)} position={location.location} clusterer={clusterer}
                                    icon={"https://www.linkpicture.com/q/Group-5843.svg"}
                                    onClick={() => onSelect(location)} />
                            ))
                        }
                    </MarkerClusterer>
                    {
                        selected.location ?
                            (
                                <InfoWindow
                                    position={selected.location}
                                    onCloseClick={() => setSelected({})}
                                >
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            component="img"
                                            height="160"
                                            image="https://psmsitev1.wpenginepowered.com/wp-content/uploads/2021/12/badminton-academy-coach.png"
                                            alt="PSM"
                                        />

                                        <CardContent sx={{ '&:last-child': { p: 1 }, p: 1 }}>
                                            <Box position="relative" p={0}>
                                                <Stack position={'absolute'} sx={{ bottom: 'calc( 100% + 8px )' }}>
                                                    <Typography variant="h6" color={'#fff'} >
                                                        {selected.name}
                                                    </Typography>
                                                </Stack>

                                                <CardActionArea>
                                                    <Stack justifyContent={'flex-end'}>

                                                        <Button sx={{
                                                            alignSelf: 'center', backgroundColor: '#193052', ":hover": {
                                                                background: "#193052"
                                                            }
                                                        }} variant="contained" size="medium" // disabled
                                                            onClick={(e) => registerHereCallback(selected)}
                                                        >
                                                            <Typography variant='body2'>
                                                            Register for this center
                                                            </Typography>

                                                        </Button>
                                                    </Stack>
                                                </CardActionArea>
                                            </Box>
                                        </CardContent>

                                    </Card>
                                </InfoWindow>
                            ) : null
                    }
                </GoogleMap>
            </LoadScript>
            <Stack alignItems={'center'} sx={{position: 'relative', top: '-50px'}}> 
            {
                    footer
            }
            </Stack>
        </>
    )
}

export default MapContainer;