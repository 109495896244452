import { z } from 'zod';

/**
 * Builds a Zod validation schema based on provided field configurations.
 * @param {Array} fields - Array of field configuration objects.
 * @param {string} language - Language code for localization (e.g., 'en').
 * @returns {ZodObject} - Zod validation schema.
 */
export const buildValidationSchema = (fields, language = 'en') => {
  const conditionalValidations = [];
  const shape = fields.reduce((acc, field) => {
    let validator;

    try {
      if (field.validations) {
        const rules = field.validations;

        // Extract the label for the current language, fallback to field name
        const fieldLabel = field.label?.[language] || field.name || 'This field';

        // Determine if the field type requires a simple 'Required' message
        const simpleRequiredTypes = ['select', 'date', 'phone'];
        const isSimpleRequired = simpleRequiredTypes.includes(field.type);

        switch (field.type) {
          case 'text':
          case 'textarea':
          case 'email':
          case 'phone':
          case 'select':
          case 'date':
          case 'radio':
          case 'password':
          case 'url':
            validator = z.string();
            // Handle 'minLength'
            if (rules.minLength) {
              validator = validator.min(rules.minLength, {
                message: `${fieldLabel} must be at least ${rules.minLength} characters long`,
              });
            }

            if (rules.maxLength) {
              validator = validator.max(rules.maxLength, {
                message: `${fieldLabel} must be at most ${rules.maxLength} characters long`,
              });
            }

            if (rules.pattern) {
              validator = validator.regex(new RegExp(rules.pattern), {
                message: `${fieldLabel} has an invalid format`,
              });
            }

            if (rules.required) {
              if (typeof rules.required === 'string') {
                // If 'required' is a custom message string
                validator = validator.min(1, { message: rules.required });
              } else {
                // Use 'Required' for specific types or '<Field Label> is required' otherwise
                const requiredMessage = isSimpleRequired ? 'Required' : `${fieldLabel} is required`;
                validator = validator.min(1, { message: requiredMessage });
              }
            } else {
              validator = validator.optional();
            }
            break;

          case 'number':
            validator = z.number({
              required_error: (() => {
                if (!rules.required) return undefined;
                if (typeof rules.required === 'string') return rules.required;
                return isSimpleRequired ? 'Required' : `${fieldLabel} is required`;
              })(),
              invalid_type_error: `${fieldLabel} must be a valid number`,
            });

            // Handle 'min'
            if (rules.min !== undefined) {
              validator = validator.min(rules.min, {
                message: `${fieldLabel} must be at least ${rules.min}`,
              });
            }

            // Handle 'max'
            if (rules.max !== undefined) {
              validator = validator.max(rules.max, {
                message: `${fieldLabel} must be at most ${rules.max}`,
              });
            }
            if (!rules.required) {
              validator = validator.optional();
            }
            break;

          case 'checkbox':
            validator = z.boolean();
            if (rules.required) {
              const requiredMessage = isSimpleRequired ? 'Required' : `${fieldLabel} must be checked`;
              validator = validator.refine((val) => val === true, {
                message: typeof rules.required === 'string' ? rules.required : requiredMessage,
              });
            } else {
              validator = validator.optional();
            }
            break;

          case 'multi-select':
            validator = z.array(z.string());
            if (rules.required) {
              validator = validator.nonempty({
                message: isSimpleRequired ? 'Required' : `At least one ${fieldLabel} must be selected`,
              });
            } else {
              validator = validator.optional();
            }
            break;

          case 'file':
            validator = z.any().refine((files) => files instanceof FileList && files.length > 0, {
              message: isSimpleRequired ? 'Required' : `At least one ${fieldLabel} must be uploaded`,
            });
            if (rules.maxSize) {
              validator = validator.refine(
                (files) => {
                  let valid = true;
                  for (let i = 0; i < files.length; i += 1) {
                    if (files[i].size > rules.maxSize) {
                      valid = false;
                      break;
                    }
                  }
                  return valid;
                },
                { message: `${fieldLabel} must be smaller than ${rules.maxSize / 1048576} MB each` }
              );
            }
            if (rules.allowedTypes) {
              validator = validator.refine(
                (files) => {
                  let valid = true;
                  for (let i = 0; i < files.length; i += 1) {
                    if (!rules.allowedTypes.includes(files[i].type)) {
                      valid = false;
                      break;
                    }
                  }
                  return valid;
                },
                { message: `${fieldLabel} has unsupported file type(s)` }
              );
            }
            if (!rules.required) {
              validator = validator.optional();
            }
            break;

          case 'richText':
            validator = z.string();

            if (rules.required) {
              const requiredMessage = isSimpleRequired ? 'Required' : `${fieldLabel} is required`;
              validator = validator.nonempty({
                message: typeof rules.required === 'string' ? rules.required : requiredMessage,
              });
            } else {
              validator = validator.optional();
            }

            if (rules.maxLength) {
              validator = validator.max(rules.maxLength, {
                message: `${fieldLabel} must be at most ${rules.maxLength} characters long`,
              });
            }

            break;

          default:
            console.warn(`Unhandled field type: ${field.type}. Defaulting to z.string().optional().`);
            validator = z.string().optional();
        }

        // Handle conditional validations
        if (rules.dependsOn) {
          console.log(`Adding conditional validation for field: ${field.name}`);
          let validationMessage;
          if (rules.required) {
            validationMessage = isSimpleRequired ? 'Required' : `${fieldLabel} is required when conditions are met`;
          } else {
            validationMessage = `${fieldLabel} has an invalid value based on conditions`;
          }
          conditionalValidations.push({
            fieldName: field.name,
            dependsOn: rules.dependsOn,
            validationMessage,
          });
        }

        // Handle custom validators
        if (rules.customValidator && typeof rules.customValidator === 'string') {
          const customValidators = {
            isPositive: (val) => val > 0,
            // Add other custom validators here
          };
          if (customValidators[rules.customValidator]) {
            let validationMessage;
            // First determine if we have a custom message
            if (rules.customValidatorMessage) {
              validationMessage = rules.customValidatorMessage;
            } else if (isSimpleRequired) {
              validationMessage = 'Required';
            } else {
              validationMessage = `${fieldLabel} failed custom validation`;
            }

            validator = validator.refine(customValidators[rules.customValidator], {
              message: validationMessage,
            });
          } else {
            console.warn(`Custom validator "${rules.customValidator}" is not defined.`);
          }
        }
      } else {
        // If no validations specified, default to optional with type-based default
        switch (field.type) {
          case 'number':
            validator = z.number().optional();
            break;
          case 'checkbox':
            validator = z.boolean().optional();
            break;
          case 'multi-select':
            validator = z.array(z.string()).optional();
            break;
          case 'file':
            validator = z.any().optional();
            break;
          default:
            validator = z.string().optional();
        }
      }

      acc[field.name] = validator;
    } catch (error) {
      console.error(`Error processing field "${field.name}" of type "${field.type}":`, error);
      // Fallback to z.any() to prevent schema build failure
      acc[field.name] = z.any();
    }

    return acc;
  }, {});

  let schema = z.object(shape);

  if (conditionalValidations.length > 0) {
    console.log('Applying superRefine with conditional validations');
    schema = schema.superRefine((data, ctx) => {
      console.log('superRefine data:', data); // Debugging line

      conditionalValidations.forEach((cond) => {
        const { fieldName, dependsOn, validationMessage } = cond;
        const { fieldName: depField, operator, value } = dependsOn;
        const dependentValue = data[depField];
        const targetValue = data[fieldName];

        let conditionMet = false;
        switch (operator) {
          case '==':
            conditionMet = dependentValue === value;
            break;
          case '!=':
            conditionMet = dependentValue !== value;
            break;
          case '>':
            conditionMet = dependentValue > value;
            break;
          case '<':
            conditionMet = dependentValue < value;
            break;
          case '>=':
            conditionMet = dependentValue >= value;
            break;
          case '<=':
            conditionMet = dependentValue <= value;
            break;
          case 'in':
            conditionMet = Array.isArray(value) && value.includes(dependentValue);
            break;
          case 'not in':
            conditionMet = Array.isArray(value) && !value.includes(dependentValue);
            break;
          default:
            conditionMet = false;
        }

        if (conditionMet) {
          const field = fields.find((f) => f.name === fieldName);
          if (field) {
            const fieldType = field.type;
            const fieldLabel = field.label?.[language] || field.name || 'This field';
            let isValid = false;

            switch (fieldType) {
              case 'checkbox':
                isValid = targetValue === true;
                break;
              case 'number':
                isValid = typeof targetValue === 'number' && !Number.isNaN(targetValue);
                break;
              case 'file':
                isValid = targetValue instanceof FileList && targetValue.length > 0;
                break;
              default:
                isValid = typeof targetValue === 'string' && targetValue.trim() !== '';
            }

            if (!isValid) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: validationMessage,
                path: [fieldName],
              });
            }
          }
        }
      });
    });
  }

  return schema;
};
