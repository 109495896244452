import React from 'react';
import { Box, Button, Typography, Stack, Link, CircularProgress, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useSnackbar } from 'notistack';

import { PhoneInput } from '../../phone-input';
import useLoginFormState from '../hooks/useLoginFormState';

const LoginStep = () => {
    const theme = useTheme();
    const { mobileWithCode, isLoading } = useSelector((state) => state.user);
    const { actions: { updateField, setActiveStep, handleLogin } } = useLoginFormState();
    
    const handleInputChange = (value) => {
        updateField('mobileWithCode', value || '' )
    };

    const handleSignUpRedirect = () => {
        setActiveStep(2);
    };

    return (
        <Box sx={{ 
            px: 2, 
            py: 4,
            bgcolor: theme.palette.background.paper,
            borderRadius: 1
        }}>
            <PhoneInput
                label="Mobile Number"
                value={mobileWithCode}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{ 
                    mb: 2,
                    '& .MuiInputBase-root': {
                        color: theme.palette.text.primary,
                    },
                    '& .MuiInputLabel-root': {
                        color: theme.palette.text.secondary,
                    },
                }}
                inputProps={{
                    autoFocus: true,
                }}
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={!isValidPhoneNumber(mobileWithCode) || isLoading}
            >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
            </Button>
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                <Typography 
                    variant="subtitle2" 
                    sx={{ color: theme.palette.text.secondary }}
                >
                    Don't have an account?
                </Typography>
                <Link
                    component="button"
                    variant="subtitle2"
                    underline="none"
                    onClick={handleSignUpRedirect}
                    sx={{ 
                        ml: 1,
                        color: theme.palette.primary.main,
                        '&:hover': {
                            color: theme.palette.primary.dark,
                        }
                    }}
                >
                    Sign Up
                </Link>
            </Stack>
        </Box>
    );
};

export default LoginStep;
