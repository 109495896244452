import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch, store } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  cartMeta: {},
  estimates: {},
  cartItems: [],
  total: 0,
  subTotal: 0,
  discount: 0,
  shipping: 0,
  credits: 0,
  couponApplied: false,
  activeStep: 0,
  error: null,
  initialLoad: true,
  referralDiscount: 0
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    setInitialLoad(state) {
      state.initialLoad = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateCartMeta(state, action) {
      state.cartMeta = action.payload;
    },

    getEstimatesSuccess(state, action) {
      state.estimates = action.payload;
      state.cartItems = (action.payload?.subscriptionItems || []).map((item) => {

        const lineItem = action.payload.invoiceLineItems.find(lineItem => lineItem.entityId === item?.itemPriceId);
        const quantity = lineItem ? lineItem.quantity : item?.quantity;
        return {
          id: item?.itemPriceId,
          name: item?.item?.fullName || item?.item?.externalName || item?.item?.name,
          cover: "https://media.istockphoto.com/id/1047918166/video/people-play-badminton.jpg?s=640x640&k=20&c=S7MNa9Gxm_nktyl86Skj6T1IjjiPgGe2AtEVan6w6Wk=",
          price: item?.unitPrice,
          invAmount: lineItem?.amount,
          color: "#FFC0CB",
          quantity: item?.quantity,
          invQuantity: quantity,
          itemType: item.itemType,
        }
      });
      const discount = action.payload.discounts?.filter(discount => discount.description !== 'Referral Discount').reduce((acc, discount) => acc + discount.amount, 0);
      state.referralDiscount = action.payload.discounts?.find(discount => discount.description === 'Referral Discount')?.amount || 0;
      state.total = action.payload?.invoice?.total;
      state.subTotal = action.payload?.invoice?.subTotal;
      state.discount = discount || 0;
      state.credits = action.payload?.invoice?.creditsApplied;
      if (discount > 0) {
        state.couponApplied = true;
      } else {
        state.couponApplied = false;
      }
      state.isLoading = false;

    },

    deleteCart(state, action) {
      // handle later

    },

    resetCoupon(state) {
      state.couponApplied = false;
    },

    resetCart(state) {
      state.activeStep = 0;
      state.cartItems = [];
      state.total = 0;
      state.subTotal = 0;
      state.discount = 0;
      state.shipping = 0;
      state.error = null;

    },

    onBackStep(state) {
      state.activeStep -= 1;
    },

    onNextStep(state) {
      state.activeStep += 1;
    },
    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.cartItems.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.cartItems = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.cartItems.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.cartItems = updateCart;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.discount = discount;
      state.couponApplied = true
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.shipping = shipping;
    },
  },
});

// Reducer
export default cartSlice.reducer;

// Actions
export const {
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  resetCoupon,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  setInitialLoad,
  startLoading,
} = cartSlice.actions;

// ----------------------------------------------------------------------

export function fetchEstimates(body) {
  // const { enqueueSnackbar } = body
  return async () => {
    dispatch(cartSlice.actions.startLoading());
    try {
      const { data: response } = await axios.post('/s/cart_estimate', body);
      dispatch(cartSlice.actions.updateCartMeta(body));
      dispatch(cartSlice.actions.getEstimatesSuccess(response.data, body));
      dispatch(setInitialLoad())
    } catch (error) {
      console.error(error);
      dispatch(cartSlice.actions.hasError(error));
      // if (enqueueSnackbar) enqueueSnackbar('Something went wrong', { variant: 'error' })
    }
  };
}
