import React, { forwardRef } from 'react';
import { Box, Card, Typography, Grid } from '@mui/material';
import CarouselV2 from '../../../krida/carousel/carousel-v2';
import BOOK_FREE_TRIAL_BACKGROUND from '../../../assets/Event.svg';
import Iconify from '../../../components/Iconify';
import EventListCard from '../EventListCard';
import BG_BLUR from '../../../assets/cyan-blur.png';
import BG_BLUR2 from '../../../assets/red-blur.png';
import SportLabel from '../../../krida/label/SportLabel';
import useResponsive from '../../../hooks/useResponsive';

const MoreEvents = forwardRef(({ moreEvents }, ref) => {
  const mdUp = useResponsive('up', 'md');
  const smDown = useResponsive('down', 'sm');

  return (
  <Card
      ref={ref}
      sx={{ m: { xs: 0, md: 4 }, px: { xs: 1, md: 8 }, py: { xs: 2, md: 4 }, bgcolor: 'white', mb: { xs: 3 } }}
    >
      <Typography variant="h6" component="h2" gutterBottom>
        More Events
      </Typography>
      <Grid container spacing={2}>
        {moreEvents?.data?.map((event) => (
          <Grid item xs={12} sm={6} md={4} key={event.id}>
            <Card
              sx={{
                p: 2,
                display: 'flex',
                backgroundImage: `url(${BG_BLUR}), url(${BG_BLUR2})`,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignContent: 'center',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                py: 2,
                pb: 5
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <CarouselV2
                  data={event?.images?.length > 0 && event?.images?.map((img) => ({
                    url: img.url,
                    alt: img.alt,
                  })) || [
                      {
                        url: BOOK_FREE_TRIAL_BACKGROUND,
                      },
                    ]}
                  containerSx={{
                    '& .slick-list': {
                      borderRadius: '16px',
                    },
                  }} />
                <Typography variant="h6" sx={{ mt: 3 }}>
                  {event.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" mb={1}>
                  {event.organiser}
                </Typography>
                {event.details.map((detail) => (
                  <Box sx={{ display: 'flex' }} key={detail.id}>
                    <Iconify
                      icon={detail.icon}
                      sx={{ color: 'black', width: 20, height: 20, mr: 1 }} />
                    {detail.label === 'Price' ? (
                      <Typography
                        sx={{ fontWeight: '400', display: smDown && 'flex', alignSelf: smDown && 'center' }}
                        align={'start'}
                      >
                        <Typography display={'inline'} fontWeight={'bold'} variant={mdUp ? 'body1' : 'body2'}>
                          {detail.value}
                        </Typography>
                        /onwards
                      </Typography>
                    ) : (
                      <Typography variant="body1" fontWeight={400}>
                        {detail.value}
                      </Typography>
                    )}
                  </Box>
                ))}
                <SportLabel isId data={event?.sportsId || ['Badminton']} />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
)
});

export default MoreEvents;
// <img src={event.images[0].url} alt={event.images[0].alt} style={{ width: '100%', borderRadius: '8px' }} />

// id, images[id,alt,url],organiser,location,price,title,date
