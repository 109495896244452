import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
} from '@mui/material';

import useResponsive from '../../../hooks/useResponsive';

import DynamicAspectRatioImage from '../../../components/DynamicAspectRatioImage';
import CarouselV2 from '../../../krida/carousel/carousel-v2';
import BOOK_FREE_TRIAL_BACKGROUND from '../../../assets/Event.svg';
import { API_STATUS } from '../../../config';
import LoadingScreen from '../../../components/LoadingScreen';

const Hero = ({ config }) => {
  const { currentUser } = useSelector((state) => state.user);
  const {  eventDetailsLoader, } = useSelector(
    (state) => state.event
  );
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');

  const [isExpanded, setIsExpanded] = useState(false);

  const heroDetails = config?.data;
  // useEffect(() => {
  //     dispatch(fetchEventDetails({ eventId, clubId }))
  // }, []);

  if (eventDetailsLoader === API_STATUS.LOADING) {
    return <LoadingScreen />;
  }

  const MAX_DESCRIPTION_LENGTH = 250; 

  const renderDescription = () => {
    if (!heroDetails?.description) return '';
    
    const descLength = heroDetails.description.length;
    
    if (descLength <= MAX_DESCRIPTION_LENGTH) {
      return (
        <Typography color="text.secondary" variant='body1' sx={{ mb: 2, px: 2 }}>
          {heroDetails.description}
        </Typography>
      );
    }
    
    return (
      <Typography color="text.secondary" variant='body1' sx={{ mb: 2, px: 2 }}>
        {isExpanded 
          ? heroDetails.description 
          : `${heroDetails.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`}
        <Button 
          onClick={() => setIsExpanded(!isExpanded)} 
          sx={{ color: 'text.secondary', padding: 0, ml: 1 }}
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </Button>
      </Typography>
    );
  };

  return (
    <Box sx={{ mt: 4, px: { md: 8 }, py: 2 }}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'center' },
            }}
          >
            <Box sx={{ mb: 4, display: 'flex', alignItems: { xs: 'center', md: 'start' }, flexDirection: 'column', gap: 2 }}>
              <Stack 
                direction="row" 
                spacing={2} 
                alignItems="flex-start" 
                sx={{ 
                  mb: 2,
                  maxWidth: '100%'
                }}
              >
                {heroDetails?.logo && (
                  <Box sx={{ 
                    width: { xs: '60px', md: '80px' }, 
                    height: { xs: '60px', md: '80px' },
                    flexShrink: 0,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    bgcolor: 'background.neutral'
                  }}>
                    <DynamicAspectRatioImage 
                      uri={heroDetails?.logo} 
                      size={{md: 80, xs: 60}}
                      sx={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </Box>
                )}
                <Typography 
                  variant='h3' 
                  color="black"
                  sx={{
                    fontSize: { xs: '1.5rem', md: '2rem' },
                    lineHeight: { xs: 1.3, md: 1.4 },
                    fontWeight: 600,
                    mt: 0.5,
                    flex: 1,
                    wordBreak: 'break-word'
                  }}
                >
                  {heroDetails?.name}
                </Typography>
              </Stack>
              {renderDescription()}

              {heroDetails?.cta.isVisible &&
                heroDetails?.cta?.buttons?.map((button) => (
                  <Button
                    key={button.id}
                    variant={button.primary ? 'contained' : 'outlined'}
                    size="large"
                    disabled={currentUser}
                    fullWidth
                    sx={{
                      bgcolor: button.primary ? '#00A76F' : '#fff',
                      color: button.primary ? '#FFFFF' : '#00A76F',
                      py: 1.5,
                      fontSize: { xs: '0.9rem', md: '1.1rem' },
                      fontWeight: 600,
                      maxWidth: '300px',
                      mb: 2,
                    }}
                    onClick={() => {
                      navigate(`${button.url}`);
                    }}
                  >
                    {currentUser ? 'Registered' : button.label}
                  </Button>
                ))}

              {currentUser?.id && (
                  <><Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CheckCircleIcon sx={{ color: '#00A76F' }} />
                  {/* <Typography sx={{ color: '#00A76F', fontWeight: 500 }}>
    You're registered for this program
  </Typography> */}
                </Box><Typography
                  sx={{ color: '#00A76F', px: 1.5, py: 0.5, borderRadius: 1 }}
                >
                    Registered ID: {currentUser?.id}
                  </Typography></>
              )}
            </Box>

          </Box>
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box mb={mdUp ? 0 : 3}>
            <CarouselV2
              data={
                heroDetails?.images?.map((img) => ({
                  url: img.url,
                  alt: img.alt,
                  ratio: img.ratio,
                })) || [
                  {
                    url: BOOK_FREE_TRIAL_BACKGROUND,
                  },
                ]
              }
              containerSx={{
                '& .slick-list': {
                  borderRadius: '16px',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};

export default Hero;
