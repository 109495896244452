import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

import { KRIDA_API } from '../../config';
import axios from '../../utils/axios'

export const fetchProfileData = createAsyncThunk('profile', async (data, thunkApi) => {
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.get(`${KRIDA_API}user/kl/profile`,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
});

export const createProfileForOrg = createAsyncThunk('profileForOrg', async (data, thunkApi) => {
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}user/kl/profile`, data.payload ,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
});

export const makePayment = createAsyncThunk('makePayment', async (data, thunkApi) => {
    const { enqueueSnackbar, invoiceId, paymentMethod = 'non_upi' } = data
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}i/${invoiceId}/initiatepg`, { paymentMethod },
            {
                headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` }
            })
        return responseData
    } catch (e) {
        console.log(e)
        enqueueSnackbar('Something went wrong while making the payment', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const addSubscription = createAsyncThunk('addSubscription', async (data, thunkApi) => {
    const { enqueueSnackbar, payload } = data
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}s`, payload,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        return responseData.data
    } catch (e) {
        console.log(e)
        enqueueSnackbar('Something went wrong while making the payment', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});