import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { memo, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';



import ResultItem from './result-item';
import { applyFilter, groupedData, getAllItems } from './utils';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { Label } from '../../components/Label';
import { useBoolean } from '../../hooks/use-boolean';
import useResponsive from '../../hooks/useResponsive';
import { useEventListener } from '../../hooks/useEventListener';
import { useRouter } from '../../routes/hooks/use-router';

// ----------------------------------------------------------------------

const Searchbar = () => {
  const theme = useTheme();

  const router = useRouter();

  const search = useBoolean();

  const lgUp = useResponsive('up', 'lg');

  const [searchQuery, setSearchQuery] = useState('');


  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleClick = useCallback(
    (path) => {
      if (path.includes('http')) {
        window.open(path);
      } else {
        router.push(path);
      }
      handleClose();
    },
    [handleClose, router]
  );

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const dataFiltered = applyFilter({
    inputData: getAllItems({
      data: [
        // OVERVIEW
        // ----------------------------------------------------------------------
        {
          subheader: 'overview',
          items: [
            {
              title: "app",
              path: "paths.dashboard.root",
              icon: "ICONS.dashboard",
            },
            {
              title: "ecommerce",
              path: "paths.dashboard.general.ecommerce",
              icon: "ICONS.ecommerce",
            },
            {
              title: "analytics",
              path: "paths.dashboard.general.analytics",
              icon: "ICONS.analytics",
            },
            {
              title: "banking",
              path: "paths.dashboard.general.banking",
              icon: "ICONS.banking",
            },
            {
              title: "booking",
              path: "paths.dashboard.general.booking",
              icon: "ICONS.booking",
            },
            {
              title: "file",
              path: "paths.dashboard.general.file",
              icon: "ICONS.file",
            },
          ],
        },

        // MANAGEMENT
        // ----------------------------------------------------------------------

        // DEMO MENU STATES
        {
          subheader: 'other_cases',
          items: [
            {
              // default roles : All roles can see this entry.
              // roles: ['user'] Only users can see this item.
              // roles: ['admin'] Only admin can see this item.
              // roles: ['admin', 'manager'] Only admin/manager can see this item.
              // Reference from 'src/guards/RoleBasedGuard'.
              title: "item_by_roles",
              path: "paths.dashboard.permission",
              icon: "ICONS.lock",
              roles: ['admin', 'manager'],
              caption: 'only_admin_can_see_this_item',
            },
            {
              title: "menu_level",
              path: "'#/dashboard/menu_level'",
              icon: "ICONS.menuItem",
              children: [
                {
                  title: "menu_level_1a",
                  path: "'#/dashboard/menu_level/menu_level_1a'",
                },
                {
                  title: "menu_level_1b",
                  path: "'#/dashboard/menu_level/menu_level_1b'",
                  children: [
                    {
                      title: "menu_level_2a",
                      path: "'#/dashboard/menu_level/menu_level_1b/menu_level_2a'",
                    },
                    {
                      title: "menu_level_2b",
                      path: "'#/dashboard/menu_level/menu_level_1b/menu_level_2b'",
                      children: [
                        {
                          title: "menu_level_3a",
                          path: "'#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a'",
                        },
                        {
                          title: "menu_level_3b",
                          path: "'#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b'",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: "item_disabled",
              path: "'#disabled'",
              icon: "ICONS.disabled",
              disabled: true,
            },
            {
              title: "item_label",
              path: "'#label'",
              icon: "ICONS.label",
              info: (
                <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                  NEW
                </Label>
              ),
            },
            {
              title: "item_caption",
              path: "'#caption'",
              icon: "ICONS.menuItem",
              caption:
                'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
            },
            {
              title: "item_external_link",
              path: "'https://www.google.com/'",
              icon: "ICONS.external",
            },
            {
              title: "blank",
              path: "paths.dashboard.blank",
              icon: "ICONS.blank",
            },
          ],
        },
      ]
    }),
    query: searchQuery,
  });

  const notFound = searchQuery && !dataFiltered.length;

  const renderItems = () => {
    const data = groupedData(dataFiltered);

    return Object.keys(data)
      .sort((a, b) => -b.localeCompare(a))
      .map((group, index) => (
        <List key={group || index} disablePadding>
          {data[group].map((item) => {
            const { title, path } = item;

            const partsTitle = parse(title, match(title, searchQuery));

            const partsPath = parse(path, match(path, searchQuery));

            return (
              <ResultItem
                path={partsPath}
                title={partsTitle}
                key={`${title}${path}`}
                groupLabel={searchQuery && group}
                onClickItem={() => handleClick(path)}
              />
            );
          })}
        </List>
      ));
  };

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {lgUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>}
    </Stack>
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="xs"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>
        {searchQuery}
        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? <SearchNotFound searchQuery={searchQuery} sx={{ py: 10 }} /> : renderItems()}
        </Scrollbar>
      </Dialog>
    </>
  );
};

export default memo(Searchbar);
