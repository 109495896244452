// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PSM = '/psm';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  profile: path(ROOTS_PSM, '/profile'),

};
export const PATH_PAGE = {
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
};

export const LEGAL = {
  refund: '/policy/refund',
  tnc: '/policy/TnC',
  privacy : 'policy/privacy'
} 

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_PSM),
    app: path(ROOTS_PSM, '/app'),
    register: path(ROOTS_PSM, '/register'),
    checkout: path(ROOTS_PSM, '/checkout'),
    registrationConfirmation: path(ROOTS_PSM, '/registrationConfirmation'),
    playerAnalytics: path(ROOTS_DASHBOARD, '/playerAnalytics'),
    playerAssessment: path(ROOTS_PSM, '/playerAssessment'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
  },
  transactions: path(ROOTS_DASHBOARD, '/banking/allTransactions'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
};


