import { useLocation, matchPath } from 'react-router-dom';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';


const EVENT_ROUTE_PATTERNS = [
  '/e/:eid/*',
  '/event/:eid/*',
  '/events/:eid/*',
  '/:orgId/e/:eid/*',
  '/:orgId/event/:eid/*',
  '/:orgId/events/:eid/*',
  '/t/:tid/*',
  '/tournament/:tid/*',
  '/tournaments/:tid/*',
  '/:orgId/t/:tid/*',
  '/:orgId/tournament/:tid/*',
  '/:orgId/tournaments/:tid/*'
];

const useRouteType = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { currentOrgId } = useSelector((state) => state.org);

  return useMemo(() => {
    const tournamentMatch = EVENT_ROUTE_PATTERNS.some(pattern => 
      matchPath({ path: pattern }, pathname)
    );

    if (tournamentMatch) {
      return { routeType: 'event', routeTypeFilterId: 'KCSE' };
    }

    return { routeType: 'subscription', routeTypeFilterId: currentOrgId };
  }, [pathname, currentOrgId]);
};

export { useRouteType };