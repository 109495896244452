import React from 'react';
import { Box, Typography, Button, Stack, Grid, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import DynamicAspectRatioImage from '../../DynamicAspectRatioImage';
import { fCurrency } from '../../../utils/formatNumber';
import useLoginFormState from '../hooks/useLoginFormState';

const SubscriptionStep = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { config } = useSelector((state) => state.orgConfig);
    const { currentPlayer, isLoading } = useSelector((state) => state.user);
    const { actions: { setActiveStep, handleContinueWithPayment, redirectToPayment } } = useLoginFormState();

    const handleSelectNewProfile = () => {
        setActiveStep(3);
    };

    return (
        <Box sx={{ px: 2, py: 4 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
                {currentPlayer?.firstName} {currentPlayer?.lastName} has active subscriptions
            </Typography>
            <Button
                variant="text"
                color="error"
                onClick={handleSelectNewProfile}
                sx={{ mb: 4 }}
            >
                Select / Add new profile?
            </Button>
            <Stack spacing={2}>
                {currentPlayer?.subscriptions?.map((subscription) => (
                    <Grid container key={subscription.id} sx={{ border: '1px solid #ddd', p: 2, borderRadius: 2 }}>
                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <DynamicAspectRatioImage uri={config?.organization?.metadata?.logo} size={48} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="subtitle2">{subscription.name}</Typography>
                            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                                Current term: {moment.unix(subscription.currentTermStart).format('DD MMM')} -{' '}
                                {moment.unix(subscription.currentTermEnd).format('DD MMM YYYY')}{' '}
                                <span
                                    style={{
                                        color: subscription.totalDues > 0 ? '#FF4747' : '#00AB55',
                                    }}
                                >
                                    ({subscription.totalDues > 0 ? 'Payment due' : 'Paid'})
                                </span>
                            </Typography>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle2">
                                    ₹ {fCurrency(subscription.metadata.amount)}
                                </Typography>
                                <Typography variant="caption" color="primary">
                                    {subscription.status}
                                </Typography>
                            </Stack>
                            {subscription.totalDues > 0 && (
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    onClick={() => redirectToPayment(subscription?.nextTermInvoiceId ? subscription?.nextTermInvoiceId : subscription?.currentTermInvoiceId)}
                                    sx={{ mt: 1 }}
                                >
                                    Complete pending payment
                                </Button>
                            )}
                        </Grid>

                    </Grid>
                ))}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Button
                fullWidth
                mb={2}
                sx={{
                    fontSize: 'small',
                    width: '100%'
                }}
                onClick={handleContinueWithPayment}
                disabled={isLoading}
                variant="contained"
            >
                Add new subscription
            </Button>
        </Box>
    );
};

export default SubscriptionStep;
