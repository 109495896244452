export const API_STATUS = {
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

export const DEFAULT_TIMER = 30;

export const ONBOARDING_T_C = {
  "enabled": true,
  "sections": [
    {
      "title": "Terms and Conditions",
      "list": [
        {
          "title": "Acceptance of Terms",
          "text": "By registering, the participant agrees to abide by all terms and conditions."
        },
        {
          "title": "Fees and Payments",
          "text": "All registration fees are due at the time of registration. Additional costs may arise, which will be communicated in advance."
        },
        {
          "title": "Cancellation and Refund Policy",
          "text": "No Refunds after enrollments."
        },
        {
          "title": "Training Schedule and Attendance",
          "text": "Participants are expected to attend all scheduled training sessions unless excused for valid reasons such as illness or emergency."
        },
        {
          "title": "Health and Safety",
          "text": "The academy is not responsible for injuries sustained during training but will provide basic first aid."
        },
        {
          "title": "Equipment and Clothing",
          "text": "Participants must wear appropriate attire and use the equipment as instructed. The academy is not liable for personal belongings."
        },
        {
          "title": "Code of Conduct",
          "text": "Participants are expected to behave respectfully and follow the academy's code of conduct. Misconduct may result in suspension or expulsion."
        },
        {
          "title": "Data Protection",
          "text": "Personal data provided during registration will be used in accordance with the academy's privacy policy."
        },
        {
          "title": "Amendments to Terms",
          "text": "The academy reserves the right to amend the terms and conditions at any time."
        }
      ]
    },
    {
      "title": "Declarations",
      "list": [
        {
          "title": "Medical Fitness",
          "text": "The participant declares that they are medically and physically fit to engage in sports activities."
        },
        {
          "title": "Acknowledgement of Risk",
          "text": "The participant acknowledges the inherent risks involved in sports training and accepts these risks."
        },
        {
          "title": "Liability Waiver",
          "text": "The participant waives any claims against the academy for any injury, loss, or damage incurred during participation, except in cases of gross negligence by the academy."
        },
        {
          "title": "Parent/Guardian Consent (for minors)",
          "text": "The parent/guardian consents to the child’s participation and agrees to the terms and conditions on their behalf."
        },
        {
          "title": "Photography and Media Consent",
          "text": "Consent for the use of photographs or videos taken during sessions for promotional purposes."
        },
        {
          "title": "Compliance with Rules and Regulations",
          "text": "The participant agrees to comply with the rules and regulations of the academy and the sports governing bodies."
        },
        {
          "title": "Emergency Medical Treatment",
          "text": "In the event of an emergency, the academy has the consent to seek medical treatment for the participant."
        }
      ]
    }
  ],
  "declarations": [
    {
      "ismandatory": "true",
      "text": "I have read, understood, and agree to the Terms and Conditions and Declarations outlined above."
    }
  ]
}