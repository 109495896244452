import React, { forwardRef } from 'react';
import { Box, Card, Typography, Divider } from '@mui/material';
import Iconify from '../../../components/Iconify';

const Timeline = forwardRef(({ timeline }, ref) => (
  <Card
    ref={ref}
    sx={{ m: { xs: 0, md: 4 }, px: { xs: 1, md: 8 }, py: { xs: 2, md: 4 }, bgcolor: 'white', mb: { xs: 3 } }}
  >
    <Box>
      <Typography variant="h4" sx={{ mb: 3, color: '#1B1B1B' }}>
        Stages and Timelines
      </Typography>

      {timeline?.data?.events?.map((data, index) => (
        <Box key={index} sx={{ display: 'flex', mb: 3, alignItems: 'center' }}>
          <Box sx={{ flex: 1, bgcolor: 'background.paper', borderRadius: 2, p: 2, border: '1px solid #E0E0E0' }}>
            <Box sx={{ borderLeft: '3px solid #00A76F', pl: 3 }}>
              <Box sx={{ display: 'flex', flexDirection:{xs: 'column', md:'row'}, justifyContent: 'space-between', alignItems: {xs:'start',md:'center'} ,mb:2}}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify icon="solar:calendar-bold" style={{ height: '1.2rem', width: '1.2rem', marginRight: '8px' }} />
                  <Typography variant="body1">{data.date}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify icon="solar:clock-circle-bold" style={{ height: '1.2rem', width: '1.2rem', marginRight: '8px' }} />
                  <Typography variant="body1">{data.time}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify icon="solar:map-point-bold" style={{ height: '1.2rem', width: '1.2rem', marginRight: '8px' }} />
                  <Typography>{data.location}</Typography>
                </Box>
              </Box>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>{data.title}</Typography>
              <Divider />
              <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.6 }}>{data.description}</Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  </Card>
));

export default Timeline;
