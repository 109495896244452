import React from 'react';
import {
    Box,
    Button,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Stack,
    Link,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { PhoneInput } from '../../phone-input';
import useLoginFormState from '../hooks/useLoginFormState';

const SignUpStep = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {
        firstName,
        lastName,
        mobileWithCode,
        dateOfBirth,
        gender,
        haveReferralCode,
        referralCode,
        isLoading,
    } = useSelector((state) => state.user);
    const { actions: { toggleHaveReferralCode,
        setActiveStep, updateField, handleRegister } } = useLoginFormState();

    const handleToggleReferralCode = () => {
        toggleHaveReferralCode();
    };

    const handleLoginRedirect = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ px: 2, py: 4 }}>
            <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => updateField('firstName', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                inputProps={{
                    autoFocus: true,
                }}
            />
            <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => updateField('lastName', e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <PhoneInput
                label="Mobile Number"
                value={mobileWithCode}
                onChange={(value) => updateField('mobileWithCode', value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            />
            <DatePicker
                label="Date of Birth"
                value={dateOfBirth}
                onChange={(value) => updateField('dateOfBirth', value)}
                maxDate={moment().subtract(3, 'years')}
                slotProps={{
                    textField: {
                        fullWidth: true,
                    },
                }}
                renderInput={(params) => (
                    <TextField {...params} fullWidth variant="outlined" sx={{ mb: 2 }} />
                )}
            />
            <RadioGroup
                aria-label="gender"
                name="gender"
                value={gender}
                onChange={(e) => updateField('gender', e.target.value)}
                row
                sx={{ mb: 2 }}
            >
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
            </RadioGroup>
            {!haveReferralCode && (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                    <Typography variant="caption">Have a referral code?</Typography>
                    <Link
                        component="button"
                        variant="caption"
                        underline="none"
                        onClick={handleToggleReferralCode}
                        sx={{ ml: 1 }}
                    >
                        Click here
                    </Link>
                </Stack>
            )}
            {haveReferralCode && (
                <TextField
                    label="Referral Code"
                    value={referralCode}
                    onChange={(e) => updateField('referralCode', e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
            )}
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleRegister}
                disabled={
                    !firstName ||
                    !lastName ||
                    !gender ||
                    !dateOfBirth ||
                    isLoading
                }
            >
                Continue
            </Button>
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">Already have an account?</Typography>
                <Link
                    component="button"
                    variant="subtitle2"
                    underline="none"
                    onClick={handleLoginRedirect}
                    sx={{ ml: 1 }}
                >
                    Login
                </Link>
            </Stack>
        </Box>
    );
};

export default SignUpStep;
