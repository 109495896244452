import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';

const ActionButton = styled(Button)(({ theme, variant }) => ({
    width: '100%',
    borderRadius: '8px',
    padding: '12px',
    marginTop: '24px',
    textTransform: 'none',
    backgroundColor: theme.palette.text.primary,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
    },
  }));
  
const Success = ({ bookingId, userName, eventDetails, eventDate, onButtonClick }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent:'center'
      }}
    >
      <Iconify icon="mdi:check-circle" sx={{ fontSize: 64,color:'#00A76F'  }} />
      <Typography variant="h4" gutterBottom>
        Registration Successful
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Your Registration id: {bookingId}
      </Typography>
      <Typography variant="body2" sx={{ mb: 4 }}>
      Registration details:
      </Typography>
      <Typography variant="body2">
        eventDetails
        </Typography>
        <ActionButton
        variant="contained"
        onClick={onButtonClick}
      >
        Back to home
      </ActionButton>
    </Box>
  );

export default Success;
