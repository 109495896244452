
import {
    RHFTextField,
    RHFSelect,
    RHFCheckbox,
    RHFRadioGroup,
    RHFDatePicker,
    RHFPhoneInput,
    RHFTextarea,
    RHFMultiSelect,
} from '../hook-form';

const fieldComponents = {
    text: RHFTextField,
    email: RHFTextField,
    password: RHFTextField,
    number: RHFTextField,
    phone: RHFPhoneInput,
    date: RHFDatePicker,
    select: RHFSelect,
    textarea: RHFTextarea,
    checkbox: RHFCheckbox,
    radio: RHFRadioGroup,
    'multi-select': RHFMultiSelect,
};

export default fieldComponents;