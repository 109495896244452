import { Stack, Typography, Card, CardHeader, CardContent, Paper, Accordion, AccordionSummary, AccordionDetails, Button, Chip, AccordionActions } from '@mui/material';
import React, { useCallback, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Upload } from '../../krida/upload';


const TEventDocUpload = () => {
    const [file, setFile] = useState(null);
    const [expanded, setExpanded] = useState('panel1');

    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };


    const handleDropSingleFile = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            setFile(
                Object.assign(newFile, {
                    preview: URL.createObjectURL(newFile),
                })
            );
        }
    }, []);

    return (
        <Paper elevation={12}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography variant='subtitle1' pr={1}>Documents</Typography> <Chip color='error' label="Document not uploaded" size='small' variant="outlined" />
                </AccordionSummary>
                <AccordionDetails>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="paneli-content"
                            id="paneli-header"
                        >
                            <Typography variant="overline" pr={1}>DOB Certificate</Typography> <Chip color='success' label="Verified" size='small' variant="outlined" />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">Uploaded 10 July 2024</Typography>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button>View</Button>
                            <Button>Re Upload</Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='subtitle2' pr={1}>School NOC</Typography>
                                {/* <Chip color='error' label="Verified" size='small' variant="outlined" /> */}
                                <Button variant='outlined' color='error'>Upload</Button>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Button>Upload</Button>

                        </AccordionDetails>
                    </Accordion>
                    {/* <Stack direction={"row"} spacing={2}> */}
                    {/* <Typography variant="body1">NOC from School</Typography>
            <Typography textAlign={"center"} alignItems={"center"} variant="caption">Uploaded 10 July 2024</Typography> */}

                    {/* <Card>
            <CardHeader title="Upload Single File" />
            <CardContent>
                <Upload file={file} onDrop={handleDropSingleFile} onDelete={() => setFile(null)} />
            </CardContent>
        </Card> */}
                    {/* </Stack> */}
                </AccordionDetails>
            </Accordion>
        </Paper>
        // <Stack spacing={2} sx={{ backgroundColor: "white" }}>


        //     <Card>
        //         <CardHeader title="Upload Single File" />
        //         <CardContent>
        //             <Upload file={file} onDrop={handleDropSingleFile} onDelete={() => setFile(null)} />
        //         </CardContent>
        //     </Card>
        // </Stack>
    )
};

export default TEventDocUpload;