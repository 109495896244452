import PropTypes from 'prop-types';
import { FormProvider as RHFForm } from 'react-hook-form';

// ----------------------------------------------------------------------

const FormProvider = ({ children, onSubmit, methods }) => (
  <RHFForm {...methods}>
    <form onSubmit={onSubmit} noValidate autoComplete="off">
      {children}
    </form>
  </RHFForm>
);
FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  methods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

export default FormProvider;
