import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

const MainLayout = ({ children, enableFooter = true }) => {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <MainHeader />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...({
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {children}
      </Box>

      {enableFooter && <MainFooter />}
    </Box>
  );
};

export default MainLayout;




// ----------------------------------------------------------------------

// export default function MainLayout({ children }) {
//   const pathname = usePathname();

//   const homePage = pathname === '/';

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
//       <Header />

//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           ...(!homePage && {
//             pt: { xs: 8, md: 10 },
//           }),
//         }}
//       >
//         {children}
//       </Box>

//       <Footer />
//     </Box>
//   );
// }

// MainLayout.propTypes = {
//   children: PropTypes.node,
// };
