import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'
import { API_STATUS, KRIDA_API } from '../../config';

const initialState = {
    registrationPageLoader: API_STATUS.IDLE,
    registrationPageData: {},
}
const eventSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        resetRegistrationPageData: (state) => {
            state.registrationPageLoader = API_STATUS.IDLE
            state.registrationPageData = {}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClubPlanRegistraionPage.pending, (state) => {
            state.registrationPageLoader = API_STATUS.LOADING
        });
        builder.addCase(fetchClubPlanRegistraionPage.fulfilled, (state, action) => {
            state.registrationPageLoader = API_STATUS.SUCCEEDED
            state.registrationPageData = action?.payload?.data;
        });
        builder.addCase(fetchClubPlanRegistraionPage.rejected, (state) => {
            state.registrationPageLoader = API_STATUS.FAILED
        });
    }
})

export const fetchClubPlanRegistraionPage = createAsyncThunk('fetchClubPlanRegistraionPage', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}kl/club/${data?.clubId}`, { page: 'club_plan_register_page' },
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error);
        return thunkApi.rejectWithValue(error)
    }
});




export const { resetRegistrationPageData } = eventSlice.actions

export default eventSlice.reducer;