import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { ONBOARDING_T_C } from '../utils/constants';
import useLoginFormState from '../hooks/useLoginFormState';

const TermsAndConditionsStep = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.user);
    const { actions } = useLoginFormState();

    return (
        <Box sx={{ px: 2, py: 4 }}>
            <Stack spacing={2} sx={{ maxHeight: 300, overflowY: 'auto', mb: 4 }}>
                {ONBOARDING_T_C?.sections?.map((section, index) => (
                    <Box key={index}>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                            {section.title}
                        </Typography>
                        {section.list.map((item, idx) => (
                            <Box key={idx} sx={{ mb: 2 }}>
                                <Typography variant="body1" fontWeight="bold">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {item.text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                ))}
            </Stack>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 2 }}>
                By proceeding, you confirm that you have read and agree to the above terms and conditions.
            </Typography>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={actions.acceptTerms}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
            </Button>
        </Box>
    );
};

export default TermsAndConditionsStep;
