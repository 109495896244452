import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { KRIDA_API } from '../../config';
import axios from '../../utils/axios'

const initialState = {
    config: {},
    configLoader: false,
    configError: {}
}
const orgConfigSlice = createSlice({
    name: 'orgConfig',
    initialState,
    reducers: {
        resetConfig: (state, action) => {
            state.config = {}
            state.configLoader = false
            state.configError = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrgConfig.pending, (state) => {
            state.configLoader = true
        })
        builder.addCase(fetchOrgConfig.fulfilled, (state, action) => {
            state.configLoader = false
            state.config = action.payload?.data
        })
        builder.addCase(fetchOrgConfig.rejected, (state, action) => {
            state.configLoader = false
            state.configError = action.payload
        })
    }
})

export const fetchOrgConfig = createAsyncThunk('fetchOrgConfig', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}config`, data, 
            { headers: { 'content-type': 'application/json' }})
        return responseData
    } catch (e) {
        console.log(e)
        if (e === 'Unauthorized') {
            return thunkApi.rejectWithValue({
                error: {
                    code: 401,
                },
            })
        }
        return thunkApi.rejectWithValue(e)
    }
})


export const { resetConfig } = orgConfigSlice.actions

export default orgConfigSlice.reducer;