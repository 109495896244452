import React from 'react';
import { Box, Typography, Stack, Card, CardContent } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Iconify from '../../Iconify';
import MyAvatar from '../../MyAvatar';
import useLoginFormState from '../hooks/useLoginFormState';

const ProfileSelectionStep = () => {
    const { currentOrgProfiles, currentPlayer } = useSelector((state) => state.user);
    const { actions: { handleProfileSelection, setActiveStep } } = useLoginFormState();

    const handleAddNewProfile = () => {
        setActiveStep(6);
    };

    return (
        <Box sx={{ px: 2, py: 4 }}>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                {currentOrgProfiles.length < 5 && (
                    <Card
                        sx={{
                            width: 200,
                            height: 200,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={handleAddNewProfile}
                    >
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Stack alignItems="center">
                                <Iconify icon="gala:add" width={60} height={60} color="primary.main" />
                                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                                    Add new profile
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                )}
                {currentOrgProfiles.map((profile) => (
                    <Card
                        key={profile.id}
                        sx={{
                            width: 200,
                            height: 200,
                            position: 'relative',
                            cursor: 'pointer',
                            border:
                                currentPlayer?.id === profile.id
                                    ? '2px solid rgba(98, 236, 188, 0.8)'
                                    : '1px solid #ddd',
                            boxShadow:
                                currentPlayer?.id === profile.id
                                    ? '0 0 10px rgba(98, 236, 188, 0.5)'
                                    : 'none',
                        }}
                        onClick={() => handleProfileSelection(profile.id)}
                    >
                        <CardContent
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                            <MyAvatar user={profile} sx={{ width: 48, height: 48 }} />
                            <Typography variant="subtitle2" sx={{ mt: 1 }}>
                                {profile.firstName} {profile.lastName}
                            </Typography>
                            <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                {profile.subscriptions?.length || 0} active subscriptions
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Stack>
        </Box>
    );
};

export default ProfileSelectionStep;
