import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

import { Label } from '../../components/Label/index';
import Iconify from '../../components/Iconify';


const KRIDA_TAG_ICON = {
  'kridaVerified': 'ph:seal-check-fill',
  'ageGroup': 'fa6-solid:child-reaching',
  'girlSafe': 'noto:girl'
}


const KridaLabel = ({ kridaTags = [] }) => {

  return <Stack sx={{
    flexDirection: 'row',
    overflowX: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }}> {kridaTags.map(tag => <Label color="primary" sx={{ fontWeight: 500, color: '#00A76F', marginRight: 2, fontSize: '12px' }} startIcon={<Iconify color={'#00A76F'} icon={KRIDA_TAG_ICON[tag.id]} />}><Typography variant='caption'>{tag.text}</Typography>
  </Label>)}</Stack>

};

export default KridaLabel;

KridaLabel.propTypes = {
  kridaTags: PropTypes.array,
};
