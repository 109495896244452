
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

const RHFTextField = ({ name, ...other }) => {
  const { register, formState } = useFormContext();

  return (
    <TextField
      {...register(name, { valueAsNumber: other.type === 'number' })}
      fullWidth
      error={!!formState.errors[name]}
      helperText={formState.errors[name]?.message}
      {...other}
    />
  );
};

RHFTextField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFTextField;