import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Stack, Grid, Autocomplete, Container } from '@mui/material';
import moment from 'moment';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Label } from '../../components/Label';
import BG_BLUR from '../../assets/cyan-blur.png';
import BG_BLUR2 from '../../assets/red-blur.png';

import useResponsive from '../../hooks/useResponsive';
import KridaLabel from '../../krida/label/KridaLabel';
import Iconify from '../../components/Iconify';
import SportLabel from '../../krida/label/SportLabel';

const getDistance = (distance) => {
  distance = Math.ceil(distance / 1000);
  return distance;
}

const EventListCard = ({ item, mobileView }) => {
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');
  const smDown = useResponsive('down', 'sm');
  const mdDown = useResponsive('down', 'md');
  const mdUp = useResponsive('up', 'md');
  return (
    <Container disableGutters maxWidth={'lg'} sx={{ marginBottom: 2 }}>
      <Grid
        columnSpacing={2}
        container
        sx={{
          backgroundImage: `url(${BG_BLUR}), url(${BG_BLUR2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          py: 2,
        }}
      >
        <Grid xs={12} md={2} item>
          <Box
            sx={{
              minHeight: mdUp ? 'auto' : '180px',
              backgroundImage: `url(${item?.images?.[0]})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 1,
              height: '100%',
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={12} md={8} sx={{ flexGrow: 1, paddingTop: mdUp ? 0 : 1 }}>
          <Box>
            <Stack justifyContent={'flex-start'} flexDirection={'row'} flexWrap={'wrap'}>
              <Typography sx={{ fontSize: '16px', fontWeight: '600', marginRight: '8px' }}>
                {item?.orgName}
              </Typography>
              {item?.club?.distance && (
                <Label sx={{ color: '#FFFFFF', marginRight: 2, fontSize: '12px' }}>
                  <Typography fontWeight={600} color={'black'} variant='caption'>
                    {getDistance(item?.club?.distance)} km
                  </Typography>
                </Label>
              )}
              <Box pl={mdUp ? 1 : 0}>
                <KridaLabel
                  kridaTags={[
                    { id: 'kridaVerified', text: "Krida Verified" },
                    { id: 'ageGroup', text: "3+ years" },
                  ]}
                />
              </Box>
            </Stack>
            <Typography sx={{ lineHeight: 1.2 }} fontWeight={'400'} variant='h3'>
              {item?.eventName}, {item?.club?.address?.locality}
            </Typography>
            <Stack
              sx={
                smDown
                  ? { flexDirection: 'column', justifyContent: 'space-between' }
                  : { flexDirection: 'row', justifyContent: 'space-between' }
              }
            >
              <Stack flexDirection={'row'}>
                <Iconify color={'#00A76F'} icon={'fluent-emoji:spiral-calendar'} />
                <Typography
                  pl={1}
                  variant={!mdUp ? 'body2' : 'subtitle2'}
                  sx={{ color: smDown ? 'black' : 'black', fontWeight: '500' }}
                  align={'start'}
                >
                  {moment.unix(item?.startDate).format('Do MMM yy')} - {moment.unix(item?.endDate).format('Do MMM yy')}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon={'material-symbols:currency-rupee'} />
                <Typography
                  variant={'caption'}
                  sx={{ fontWeight: '500', display: smDown && 'flex', alignSelf: smDown && 'center' }}
                  align={'start'}
                >
                  <Typography display={'inline'} fontWeight={'bold'} variant={mdUp ? 'body1' : 'body2'}>
                    {Number(item?.price) / 100}
                  </Typography>
                  /onwards
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection={'row'} marginBottom={'2px'} alignItems={'center'}>
              <Iconify color={'#00A76F'} icon={'logos:google-maps'} />
              <Typography
                pl={1}
                variant={!mdUp ? 'body2' : 'subtitle2'}
                sx={{ textTransform: 'capitalize' }}
                align={'start'}
              >
                {item?.club?.address?.fullAddress}
              </Typography>
            </Stack>
            <Stack>
              <SportLabel isId data={item?.sportsId || []} />
            </Stack>
          </Box>
        </Grid>
        <Grid display={'flex'} alignItems={'flex-end'} item xs={mdUp ? 2 : 12}>
          <Stack width={'100%'} justifyContent={'flex-end'}>
            <Button
              fullWidth
              sx={{ backgroundColor: '#193052', marginY: 1, fontSize: 'small' }}
              variant='contained'
              onClick={() => {
                navigate(`/${item?.orgId}/event/${item?.eventId}?city=${item?.club?.city}&club=${item?.club?.id}`);
              }}
            >
              Register Now
            </Button>
            <Button
              fullWidth
              sx={smUp ? { fontSize: 'small' } : { fontSize: 'small', width: '100%' }}
              onClick={() => {
                navigate(`/event/${item?.eventId}/${item?.clubId}`);
              }}
              variant='outlined'
            >
              View Details
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventListCard;