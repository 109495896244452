import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

import QRCode from '../assets/KridaPlayStore.png';
import { APP_STORE } from '../config';

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
}));

const QRCodeImage = styled('img')({
  width: '80%',
  margin: '20px auto',
});

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const GetAppModal = ({ showModal, modalClose }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    modalClose(false);
  };

  useEffect(() => {
    if (showModal) {
      handleOpen();
    }
  }, [showModal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <CloseButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </CloseButton>
          <Typography variant="h6" component="h2">
            Download Our App
          </Typography>
          <QRCodeImage src={QRCode} alt="QR Code" />
          <Typography variant="body1" gutterBottom>
            Scan the QR code above to download our app!
          </Typography>
          <ButtonContainer>
            <Button
              variant="contained"
              color="primary"
              href={APP_STORE.ANDROID}
              target="_blank"
            >
              Get on Play Store
            </Button>
            <Button
              variant="contained"
              color="primary"
              href={APP_STORE.APPLE}
              target="_blank"
              style={{ marginLeft: '10px' }}
            >
              Get on App Store
            </Button>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default GetAppModal;