import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

const RHFTextarea = ({ name, label, placeholder, rows = 2, ...other }) => {
    const { control } = useFormContext();

    return (
        <TextField
            {...control.register(name)}
            fullWidth
            label={label}
            placeholder={placeholder}
            error={!!control?.formState?.errors[name]}
            helperText={control?.formState?.errors[name]?.message}
            multiline
            rows={rows}
            {...other}
        />
    );
};

RHFTextarea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
};

export default RHFTextarea; 