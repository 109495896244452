import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import { Label } from '../../components/Label/index';
import Iconify from '../../components/Iconify';

const KRIDA_TAG_ICON = {
  'badminton': 'noto:badminton',
  'swimming': 'fluent-emoji:woman-swimming',
  'cricket': 'noto:cricket-game',
  'football': 'fluent-emoji:soccer-ball',
  'hockey': 'noto:field-hockey',
  'skating': 'twemoji:skateboard',
  'boxing': 'noto-v1:boxing-glove',
  'taekwondo': 'noto:martial-arts-uniform',
  'table-tennis': 'fa6-solid:table-tennis-paddle-ball'
}


const SportLabel = ({ data = [], isId = false, color = '#00A76F' }) => {
  return <Stack sx={{
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }}> {data.map(tag => <Label color="primary" variant={'outlined'} sx={{ color, marginRight: 2, borderWidth: 1, borderColor: color, fontWeight: '600' }} startIcon={<Iconify color={color}
    icon={KRIDA_TAG_ICON[isId ? tag : tag.id]} />}>{isId ? tag : tag.name}
  </Label>)} </Stack>

};

export default SportLabel;

SportLabel.propTypes = {
  data: PropTypes.array,
};
