import React, { useState, useEffect } from 'react';
import { Container, Typography, Stack, Pagination, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventList } from '../redux/slices/event';
import LoadingScreen from './LoadingScreen';
import EventListCard from '../pages/dashboard/EventListCard';
import MainFooter from '../layouts/main/MainFooter';
import FilterComponent from './FilterComponent';
import useResponsive from '../hooks/useResponsive';
import LocationPermissionDialog from './google-map/LocationPermissionDialog';
import MainLayout from '../layouts/main';

const SportEvents = () => {
  const dispatch = useDispatch();
  const { eventListLoader, eventListData, pageCount, limit } = useSelector((state) => state.event);
  const [page, setPage] = useState(0);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [currentPosition, setCurrentPosition] = useState({});
  const [sportsId, setSportsId] = useState('');
  const [distance, setDistance] = useState(0);
  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    if (currentPosition.longitude) {
      dispatch(fetchEventList({ coordinates: currentPosition, offset: page * limit }));
    }
  }, [dispatch, page, currentPosition]);

  useEffect(() => {
    if (filterData.type === 'sports') {
      setSportsId(filterData.value.toLowerCase());
      if (distance) {
        dispatch(fetchEventList({ coordinates: currentPosition, maxDistance: distance, sportsId: [filterData.value.toLowerCase()], offset: page * 20 }));
      } else {
        dispatch(fetchEventList({ coordinates: currentPosition, sportsId: [filterData.value.toLowerCase()], offset: page * 20 }));
      }
    } else if (filterData.type === 'distance') {
      const distanceInMeters = getDistance(filterData.value)
      setDistance(distanceInMeters)
      if (sportsId) {
        dispatch(fetchEventList({ coordinates: currentPosition, maxDistance: distanceInMeters, sportsId: [sportsId], offset: page * 20 }));
      } else {
        dispatch(fetchEventList({ coordinates: currentPosition, maxDistance: distanceInMeters, offset: page * 20 }));
      }
    }
  }, [filterData])

  useEffect(() => {
    dispatch(fetchEventList());

  }, [])

  function getDistance(option) {
    if (option === 'Others') option = '1000';
    const distanceInKm = Number(option.replace(/\D/g, ''));
    const distanceInMeters = distanceInKm * 1000;
    return distanceInMeters;
  }


  const setPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const currentPosition = {
      latitude,
      longitude
    }
    setCurrentPosition(currentPosition);
  }

  const handleFilterChange = (data) => {
    setFilterData(data);
  };

  console.log(currentPosition, 'currentPositioncurrentPosition')
  return (
    <MainLayout>
      <Container maxWidth={false}>
        {!currentPosition.longitude && <LocationPermissionDialog onLocationUpdate={(location) => { setPosition(location) }} />}
        <Stack sx={lgUp ? { marginLeft: '80px' } : {}}>
          <Typography sx={{ fontSize: '24px', fontWeight: '400', fontFamily: 'Poppins', marginTop: '20px' }}>Sports Events</Typography>
          <FilterComponent dropDownValueChange={handleFilterChange} />
        </Stack>
        {eventListData.length > 0 && eventListData.map((item) => <EventListCard key={item.id} item={item} />)}
        {eventListData.length === 0 && (eventListLoader === 'loading' || eventListLoader === 'idle') && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <CircularProgress color="primary" />
            <Typography variant="body1" sx={{ marginTop: '8px', fontWeight: '600' }}>Loading...</Typography>
          </div>
        )}
        {eventListData.length === 0 && eventListLoader !== 'loading' && eventListLoader !== 'idle' && (
          <Typography sx={{ display: 'flex', justifyContent: 'center' }}>No events found for selected {filterData.type}.</Typography>
        )}
        <Stack my={2} alignItems="center">
          <Pagination
            shape="rounded"
            onChange={(event, page) => setPage(page - 1)}
            page={page + 1}
            count={pageCount}
            variant="text"
          />
        </Stack>
      </Container>
    </MainLayout>
  );
};

export default SportEvents;
