import PropTypes from 'prop-types';
import Page400 from '../pages/Page400';
// ----------------------------------------------------------------------

const ErrorBoundaryFallback = ({ error }) => {
  if (window.cwr) window.cwr('Uncaught Error', error);
  return <Page400 />
};

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.node,
};

export default ErrorBoundaryFallback;
