import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const InputDropDown = ({ data, onChange }) => {
  const [val, setVal] = useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setVal(selectedValue);
    onChange(selectedValue);
  };

  return (
    <div style={{ marginRight:'20px'}}>
      <FormControl sx={{ m: '10px 2px 20px 2px', minWidth: 240, width: '100%' }}>
        <InputLabel sx={{ color: '#1E85FF', marginBottom:'10px' }}>{data.title}</InputLabel>
        <Select
          sx={{ color: '#000000', backgroundColor: '#1E85FF1A', width: '100%', height:'44px' }}
          value={val}
          onChange={handleChange}
          autoWidth={false}
          label={data.title}
        >
          {data.options.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default InputDropDown;
