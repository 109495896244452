import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, RadioGroup, CardActionArea } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
//
import Iconify from '../Iconify';
import { BoxMask } from '.';

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

const SettingMode = () => {
  const { themeMode, onChangeMode } = useSettings();
  const [mode, setMode] = useState('light');
  const isSelected = themeMode === mode;

  const toggleMode = (mode) => {
    if (mode === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  return (
    <RadioGroup name="themeMode" value={themeMode} onClick={(e) => {
      toggleMode(themeMode)
      onChangeMode(e)
      }}>
            <Grid item xs={3}>
              <BoxStyle
                sx={{
                  bgcolor: mode === 'light' ? 'common.white' : 'grey.800',
                  ...(isSelected && {
                    color: 'primary.main',
                    boxShadow: (theme) => theme.customShadows.z20,
                  }),
                }}
              >
                <Iconify icon={themeMode === 'light' ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={50} height={25} />
                <BoxMask value={mode} />
              </BoxStyle>
            </Grid>
    </RadioGroup>
  );
};

export default SettingMode;
