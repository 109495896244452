import { m } from 'framer-motion';

import { Container, Grid, Typography, Stack } from '@mui/material';

// components
import { MotionContainer, varFade } from '../animate';
import { IconTiming, CustomTraining, FunGames, StartPlaying } from '../../assets';
import useResponsive from '../../hooks/useResponsive';

const DetailIconList = () => {
    const smDown = useResponsive('down', 'sm');

    return (
        <m.div variants={varFade().inRight}
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                textAlign: 'center',
                background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #193052",
                color: "#fff"
            }}>
            <Container xs={{ justifyContent: 'center' }} component={MotionContainer} width={"100%"} sx={{ backgroundColor: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #193052", py: { xs: 8, md: 12 } }}>
                <Stack alignItems={'center'} width={'100%'}>
                    <Typography variant={smDown ? 'h4' : 'h3'} paddingBottom={3}>
                        The best way to spend the summer
                    </Typography>
                    <Grid container>
                        <Grid item md={6} py={3}>
                            <Stack alignItems={'center'}>
                                <IconTiming />
                                <Typography variant={smDown ? 'h6' : 'h5'}>
                                    Flexible Timings
                                </Typography>
                                <Typography variant='body2' maxWidth={300}>
                                    Join a fun-filled training program that matches your goals and schedule
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={6} p={3}>

                            <Stack alignItems={'center'}>
                                <CustomTraining />
                                <Typography variant={smDown ? 'h6' : 'h5'}>
                                    Custom Training Options
                                </Typography>
                                <Typography variant='body2' maxWidth={300}>
                                    We offer customized training programs for children in different age groups, irrespective of whether they have played before or not
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={6} p={3}>

                            <Stack alignItems={'center'}>
                                <FunGames />
                                <Typography variant={smDown ? 'h6' : 'h5'}>
                                    Fun, Games and More
                                </Typography>
                                <Typography variant='body2' maxWidth={300}>
                                    Our summer camp focuses on badminton drills, friendly tournaments, and regular exercise to keep kids happy and healthy
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={6} p={3}>
                            <Stack alignItems={'center'}>
                                <StartPlaying />
                                <Typography variant={smDown ? 'h6' : 'h5'}>
                                    Start Playing Anytime
                                </Typography>
                                <Typography variant='body2' maxWidth={300}>
                                    PSM offers suitable training programs for both kids and adults. Our training programs are ideal for beginners and expert
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </m.div>
    )
}

export default DetailIconList;