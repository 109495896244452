// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography, Stack } from '@mui/material';

// components
import { MotionContainer } from '../animate';

import Image from '../Image';

import LANDING_PAGE_COACH from "../../assets/coach.webp";
import LANDING_PAGE_REPORT from "../../assets/analysis.webp";
import LANDING_PAGE_NUTRITION from "../../assets/smoothie.webp";
import LANDING_PAGE_EXERCISE from "../../assets/excercise.webp";
import LANDING_PAGE_TOURNAMENT from "../../assets/trophy.webp";
import LANDING_PAGE_GAME from "../../assets/shuttlecock.webp";
import LANDING_PAGE_INJURY from "../../assets/ankle-brace.webp";

import useResponsive from '../../hooks/useResponsive';

const DetailIconListSmall = () => {
    const smDown = useResponsive('down', 'sm');
    
    return (
        <Container maxWidth="md" component={MotionContainer}>
            <Box>
                <Typography variant={smDown ? 'h6' : 'h4'} py={{ xs: 4, md: 8 }} align="center" >
                    All Program level includes
                </Typography>
                <Grid container justifyContent={'center'} p={1}>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', pb: 4, alignItems: 'center' }}>
                        <Image
                            alt="login"
                            src={LANDING_PAGE_COACH}
                            sx={{
                                height: 40,
                                width: 40,
                                alignSelf: 'center',
                            }}
                        />
                        <Typography variant={'body2'} align="center" >
                            Coach to player <Typography variant={'body2'}>ratio of 1:6 </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', pb: 4, alignItems: 'center' }}>
                        <Image
                            alt="login"
                            src={LANDING_PAGE_REPORT}
                            sx={{
                                height: 40,
                                width: 40,
                                alignSelf: 'center',
                            }}
                        />
                        <Typography variant={'body2'} align="center" >
                            Report Cards <Typography variant={'body2'}> with progress </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', pb: 4, alignItems: 'center' }}>
                        <Image
                            alt="login"
                            src={LANDING_PAGE_NUTRITION}
                            sx={{
                                height: 40,
                                width: 40,
                                alignSelf: 'center',
                            }}
                        />
                        <Typography variant={'body2'} align="center" >
                            Nutrition <Typography variant={'body2'}>Tips </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', pb: 4, alignItems: 'center' }}>
                        <Image
                            alt="login"
                            src={LANDING_PAGE_EXERCISE}
                            sx={{
                                height: 40,
                                width: 40,
                                alignSelf: 'center',
                            }}
                        />
                        <Typography variant={'body2'} align="center" >
                            Reaction specific <Typography variant={'body2'}>Excercises </Typography>
                        </Typography>
                    </Grid><Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', pb: 4, alignItems: 'center' }}>
                        <Image
                            alt="login"
                            src={LANDING_PAGE_TOURNAMENT}
                            sx={{
                                height: 40,
                                width: 40,
                                alignSelf: 'center',
                            }}
                        />
                        <Typography variant={'body2'} align="center" >
                            Access to <Typography variant={'body2'}>Tournaments </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', pb: 4, alignItems: 'center' }}>
                        <Image
                            alt="login"
                            src={LANDING_PAGE_GAME}
                            sx={{
                                height: 40,
                                width: 40,
                                alignSelf: 'center',
                            }}
                        />
                        <Typography variant={'body2'} align="center" >
                            Internal Games <Typography variant={'body2'}>day </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', pb: 4, alignItems: 'center', alignSelf: 'center', justifySelf: 'center' }}>
                        <Image
                            alt="login"
                            src={LANDING_PAGE_INJURY}
                            sx={{
                                height: 40,
                                width: 40,
                                alignSelf: 'center',
                            }}
                        />
                        <Typography variant={'body2'} align="center" >
                            Injury Prevention <Typography variant={'body2'}>and recovery </Typography>
                            <Typography variant={'body2'}> guidance </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container >


    )
}

export default DetailIconListSmall;