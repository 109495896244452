import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'

const playersSlice = createSlice({
    name: 'players',
    initialState: {
        isLoading: false,
        loader: false,
        playerData: {},
        playerGrowthData: {},
        playerChurnData: {},
        playerBatchData: {},
        subscriptionPaymentStatusData: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPlayerDataAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPlayerDataAction.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action?.payload?.data
            const type = action?.payload?.type
            if(type === 'SUBSCRIPTION'){
            state.playerData = data
            }
            else if(type === 'GROWTH'){
            state.playerGrowthData = data
            }
            else if(type === 'CHURN'){
            state.playerChurnData = data
            }
            else{
            state.playerBatchData = data
            }
        });
        builder.addCase(fetchPlayerDataAction.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchSubscriptionPaymentStatus.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(fetchSubscriptionPaymentStatus.fulfilled, (state, action) => {
            state.loader = false;
            const data = action?.payload?.data;
            state.subscriptionPaymentStatusData = data;
        });
        builder.addCase(fetchSubscriptionPaymentStatus.rejected, (state) => {
            state.loader = false;
        });
    }
})

export const fetchPlayerDataAction = createAsyncThunk('players', async (type, thunkApi) => {
    try {
        const queryParam = '1'
        if (queryParam?.length) {
            const { data: responseData } = await axios.get(`/admin/report/player?clubId=PSM_CUCT&clubId=PSM_HSR&type=${type}`,
                { headers: { 'content-type': 'application/json' } })
                if (responseData)
            responseData.type = type
            return responseData
        }
            return []
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const fetchSubscriptionPaymentStatus = createAsyncThunk('subscriptionPaymentStatus', async ( thunkApi) => {
    try {
        const queryParam = '1'
        if (queryParam?.length) {
            const { data: responseData } = await axios.get(`/admin/report/revenue?clubId=PSM_HSR&type=SUBSCRIPTION&clubId=PSM_CUCT`,
                { headers: { 'content-type': 'application/json' } })

            return responseData
        }
            return []
    } catch (e) {
        return thunkApi.rejectWithValue(e)
    }
});

export default playersSlice.reducer;