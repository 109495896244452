import { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Box, Typography, Card, CardContent, Button, Stack, List, Chip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { fCurrency } from '../../../utils/formatNumber';
import PartnerDialog from './PartnerDialog';
import LoadingScreen from '../../../components/LoadingScreen';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/Scrollbar';
import useRegistration from './useRegistration';
import { setTournamentData } from '../../../redux/slices/tournamentRegistrationSlice';

const TournamentRegister = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const mdUp = useResponsive('up', 'md');
  const { eventDetailsData, eventDetailsLoader } = useSelector((state) => state.eventDetails);
  const { tournamentData } = useSelector((state) => state.eventRegistration);
  const [openPartnerDialog, setOpenPartnerDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [savedPartners, setSavedPartners] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { updateTournamentData, nextStep } = useRegistration();

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      if (tournamentData.selectedEvents?.length === 0) {
        enqueueSnackbar('Please select at least one event', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }

      updateTournamentData(tournamentData);
      nextStep();
    }
  }));

  useEffect(() => {
    const savedPartnersData = localStorage.getItem('savedPartners');
    if (savedPartnersData) {
      setSavedPartners(JSON.parse(savedPartnersData));
    }
  }, []);

  const handleSelect = useCallback(
    (event) => {
      if (tournamentData.selectedEvents?.includes(event.id)) {
        dispatch(setTournamentData({
          selectedEvents: tournamentData.selectedEvents.filter((id) => id !== event.id),
          partnerDetails: {
            ...tournamentData.partnerDetails,
            [event.id]: undefined
          }
        }));
      } else {
        if (tournamentData.selectedEvents?.length >= eventDetailsData?.config?.maxSubEventRegistraionAllowed) {
          enqueueSnackbar(`You can only select up to ${eventDetailsData?.config?.maxSubEventRegistraionAllowed} events`, {
            variant: 'error',
            autoHideDuration: 3000,
          });
          return;
        }

        if (event.isTeamEvent || event.type === 'team_of_two') {
          setCurrentEvent(event);
          setOpenPartnerDialog(true);
        } else {
          dispatch(setTournamentData({
            selectedEvents: [...(tournamentData.selectedEvents || []), event.id]
          }));
        }
      }
    },
    [tournamentData, dispatch]
  );

  const handlePartnerSubmit = (data) => {
    if (currentEvent) {
      dispatch(setTournamentData({
        selectedEvents: [...(tournamentData.selectedEvents || []), currentEvent.id],
        partnerDetails: {
          ...tournamentData.partnerDetails,
          [currentEvent.id]: data
        }
      }));
      setOpenPartnerDialog(false);
      setCurrentEvent(null);
    }
  };

  const handleAddPartner = useCallback((event) => {
    setCurrentEvent(event);
    setOpenPartnerDialog(true);
  }, []);

  if (eventDetailsLoader === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100%',
    }}>
      <Box 
        sx={{ 
          overflowY: 'auto', 
          position: 'relative',
          p:1,
          backgroundColor: 'background.neutral',
          borderRadius: 2
        }}
      >
        <Scrollbar
          sx={{ height: 1 }}
          slotProps={{
            content: { height: 1, display: 'flex', alignItems: 'center' },
          }}
        >
          <List sx={{ width: '100%' }}>
            {eventDetailsData?.sub_events?.map((event) => (
          <Card key={event.id} sx={{ mb: 2 }}>
            <CardContent>
              <Stack spacing={0}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <GroupsIcon color="action" sx={{ fontSize: '1.2rem' }} />
                  <Typography variant="h6">{event.name}</Typography>
                  {event.isTeamEvent ? (
                    <Chip
                      label="Doubles"
                      size="small"
                      sx={{
                        ml: 1,
                      }} />
                  ) : null}
                </Stack>

                {event.isTeamEvent
                  ? tournamentData.selectedEvents?.includes(event.id) && (
                    <Stack spacing={0} sx={{ mt: 0 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0 }}>
                        Partner
                      </Typography>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{tournamentData.partnerDetails[event.id]?.name || tournamentData.partnerDetails[event.id]?.firstName || 'Add your partner'}</Typography>
                        <Button
                          startIcon={tournamentData.partnerDetails[event.id] ? <EditIcon /> : <PersonAddIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddPartner(event);
                          } }
                        >
                          {tournamentData.partnerDetails[event.id] ? 'Edit Partner' : 'Add Partner'}
                        </Button>
                      </Stack>
                    </Stack>
                  )
                  : null}

                <>
                  <Typography variant="subtitle2" color="text.secondary">
                    Registration Fee
                  </Typography>
                  <Typography variant="h6">{fCurrency(event.priceConfig?.price)}</Typography>
                </>

                <Button
                  startIcon={tournamentData.selectedEvents?.includes(event.id) ? <CheckCircleOutlineIcon /> : null}
                  variant={tournamentData.selectedEvents?.includes(event.id) ? 'contained' : 'outlined'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(event);
                  } }
                  fullWidth
                  sx={{ mt: 1 }}
                >
                  {tournamentData.selectedEvents?.includes(event.id) ? 'Selected' : 'Select'}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        ))}
          </List>
        </Scrollbar>
      </Box>
      <PartnerDialog
        open={openPartnerDialog}
        onClose={() => {
          setOpenPartnerDialog(false);
        } }
        onSubmit={handlePartnerSubmit}
        currentEvent={currentEvent}
        savedPartners={savedPartners}
        existingPartner={currentEvent ? tournamentData.partnerDetails[currentEvent.id] : null} />
    </Box>
  );
});

export default TournamentRegister;
