import PropTypes from 'prop-types';
import { Box, Button, Paper, Typography } from '@mui/material';
import { m } from "framer-motion";


// ----------------------------------------------------------------------

const SearchNotFound = ({ searchQuery = '', ...other }) => {
  return searchQuery ? (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography>
      <Typography variant="body2" align="center">
        No partners found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete names.
      </Typography>
      <Box animate={{
        // scale: [1, 1.01, 1, 1.01, 1],
        // rotate: [0, 1, 0, -1, 0],
        x: [0, -3, 0, 3, 0]
      }} transition={{
        duration: .5,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: .5
      }} component={m.div} pt={2}>
        <Button variant="contained" color='error' fullWidth> Add new partner</Button>
      </Box>
    </Paper>
  ) : (
    <Typography variant="body2"> Please enter keywords</Typography>
  );
};

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};


export default SearchNotFound;
