// VerifyForm.js
import React, { forwardRef, useImperativeHandle } from 'react';
import { Box, Typography, Stack, Divider, Card, CardContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fCurrency } from '../../../utils/formatNumber';
import useRegistration from './useRegistration';

const VerifyForm = forwardRef((_, ref) => {
  const { formData, submitRegistration } = useRegistration();
  const { tournamentData, eventId} = useSelector((state) => state.eventRegistration);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { eventDetailsData } = useSelector((state) => state.eventDetails);

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      submitRegistration(enqueueSnackbar, navigate );
    }
  }));

  const calculateTotalPrice = () => {
    if (eventId === 'psm-dec-24-tournament') {
      return tournamentData.selectedEvents.reduce((total, eventId) => {
        const event = eventDetailsData.sub_events.find(e => e.id === eventId);
        return total + (event?.priceConfig?.price || 0);
      }, 0);
    }
    return eventDetailsData?.sub_events?.[0]?.priceConfig?.price || 0;
  };
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" gutterBottom>
        Verify Registration Details
      </Typography>

      <Card sx={{ mb: 2, overflowY: 'scroll' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Selected Events
          </Typography>
          {eventId === 'psm-dec-24-tournament' ? (
            <Stack spacing={2}>
             {tournamentData.selectedEvents?.map((eventId) => {
              const event = eventDetailsData.sub_events.find(e => e.id === eventId);
              const partner = tournamentData.partnerDetails?.[eventId];

              return (
                <Box key={eventId}>
                  <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="subtitle1">
                        {event?.name}
                      </Typography>
                      <Typography>
                        {fCurrency(event?.priceConfig?.price || 0)}
                      </Typography>
                    </Stack>
                    
                    {partner && (
                      <Typography variant="body2" color="text.secondary">
                        Partner: {partner.firstName || partner.name} {partner.lastName || ''} 
                      </Typography>
                    )}
                    
                  </Stack>
                  <Divider sx={{ my: 1 }} />
                </Box>
              );
            })}
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">
                {eventDetailsData?.sub_events?.[0]?.name}
              </Typography>
              <Typography>
                {fCurrency(eventDetailsData?.sub_events?.[0]?.priceConfig?.price || 0)}
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>

      <Card sx={{ mt: 'auto' }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Total Amount</Typography>
            <Typography variant="h6" color="primary">
              {fCurrency(calculateTotalPrice())}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
});

export default VerifyForm;