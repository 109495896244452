import React, { useEffect , useState } from 'react';
import { useDispatch } from 'react-redux';
import  InputDropDown  from './InputDropdown';
import useResponsive from '../hooks/useResponsive';

const FilterComponent = ({dropDownValueChange}) => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState('');

    const smUp = useResponsive('up', 'sm');

    const dropDownData = [
        {
            title: 'All sports',
            options: ['Badminton', 'Cricket', 'Swimming' ,'Football', 'Hockey','Table Tennis', 'Skating', 'Boxing'],
        },
        {
            title: 'Distance',
            options: ["< 1 km", "< 5 km", "< 10 km", "< 30 km", "< 50 km", "Others"],
        },
    ];

    const handleDropDownChange = (value) => {
        setSelectedValue(value); 
        let type = null;
        const allSportsOptions = dropDownData.find(data => data.title === 'All sports').options;
        if (allSportsOptions.includes(value)) {
            type = 'sports';
        } else {
            const distanceOptions = dropDownData.find(data => data.title === 'Distance').options;
            if (distanceOptions.includes(value)) {
                type = 'distance';
            }
        }
        dropDownValueChange({ type, value });
    };
    

    return (
        <div style={ smUp ? { display: 'flex' , justifyContent:'flex-start'} : { display: 'flex' , flexDirection:'column', justifyContent:'flex-start'}}>
            <InputDropDown data={dropDownData[0]} onChange={handleDropDownChange}/>
            <InputDropDown data={dropDownData[1]} onChange={handleDropDownChange}/>
        </div>
    );
};

export default FilterComponent;
