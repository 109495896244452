/**
 * Maps layout types to MUI Grid item sizes.
 *
 * @param {string} layout - The layout type (e.g., 'single-column', 'two-column').
 * @returns {Object} - An object containing Grid size props.
 */
export const getGridSizeProps = (layout) => {
    const layoutToGridSize = {
        'single-column': { xs: 12 },
        'two-column': { xs: 12, sm: 6 },
        'three-column': { xs: 12, sm: 4 },
        'four-column': { xs: 12, sm: 3 },
    };

    return layoutToGridSize[layout] || { xs: 12 };
};