import React from 'react';
import { Box, Card, Typography, Stack, Avatar } from '@mui/material';
import Iconify from '../../../components/Iconify';


const ContactDetails = ({ contactData }) => (
    <Card sx={{ p: 2, bgcolor: 'white', mb: 3 }}>
      <Typography variant="h6" color="text.primary" gutterBottom>
        Contact Details
      </Typography>
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          component="a"
          href={`tel:${contactData.contactDetails.phone}`}
          sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': { opacity: 0.8 },
          }}
        >
          <Iconify
            icon="solar:phone-bold"
            sx={{
              color: 'black',
              width: 20,
              height: 20,
              flexShrink: 0,
            }}
          />
          <Typography variant="body1" color="text.primary" sx={{ wordBreak: 'break-all' }}>
            {contactData.contactDetails.phone}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          component="a"
          href={`mailto:${contactData.contactDetails.email}`}
          sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': { opacity: 0.8 },
          }}
        >
          <Iconify
            icon="solar:letter-bold"
            sx={{
              color: 'black',
              width: 20,
              height: 20,
              flexShrink: 0,
            }}
          />
          <Typography variant="body1" color="text.primary" sx={{ wordBreak: 'break-all' }}>
            {contactData.contactDetails.email}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );

export default ContactDetails;