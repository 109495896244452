import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';

import {
  login,
  signup,
  otpValidate,
  resetCheckOutData,
} from '../../../redux/slices/registration';
import {
  createProfileForOrg,
} from '../../../redux/actions/userActions';
import {
  setActiveStep,
  updateField,
  toggleHaveReferralCode,
  startTimer,
  decrementTimer,
  setLoginFormOpen,
  selectProfile,
  continueWithPayment,
  resetShouldNavigateToCart,
  setRegistrationFlowFilter,
  refreshCurrentOrgProfiles,
} from '../../../redux/slices/user';

const useLoginFormState = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    user,
    currentOrgProfiles,
    currentPlayer,
    loader,
    mobileWithCode,
    dialCode,
    firstName,
    lastName,
    gender,
    dateOfBirth,
    referralCode,
    mobile,
    activeStep,
    profileName,
    otp,
    filterType,
  } = useSelector((state) => state.user);

  const { otpGenerated, referenceCode } = useSelector(
    (state) => state.registration
  );
  const { config } = useSelector((state) => state.orgConfig);
  const { estimates } = useSelector((state) => state.cart);
  const timerId = useRef(null);

  const handleStartTimer = () => {
    dispatch(startTimer());
    clearInterval(timerId.current);
    timerId.current = setInterval(() => {
      dispatch(decrementTimer());
    }, 1000);
  };

  const handleLogin = () => {
    if (isValidPhoneNumber(mobileWithCode)) {
      dispatch(
        login({
          payload: { mobile, dialCode },
          enqueueSnackbar,
        })
      );
    } else {
      enqueueSnackbar('Please enter a valid mobile number', {
        variant: 'error',
      });
    }
  };

  const handleRegister = () => {
    if (firstName && lastName) {
      if (referralCode) {
        localStorage.setItem(
          'rc',
          btoa(
            JSON.stringify({
              promoPromoterCode: referralCode,
              addedDate: new Date(),
            })
          )
        );
      }
      dispatch(
        signup({
          payload: {
            firstName,
            lastName,
            mobile,
            dialCode,
            gender,
            dob: dateOfBirth,
            promoPromoterCode: referralCode,
          },
          enqueueSnackbar,
        })
      );
    } else {
      enqueueSnackbar('Please enter name and mobile', { variant: 'error' });
    }
  };

  const submitOtp = () => {
    dispatch(
      otpValidate({
        payload: { mobile, otp, referenceCode, dialCode },
        enqueueSnackbar,
      })
    );
  };

  const handleProfileSelection = (playerId) => {
    dispatch(
      selectProfile({ profileId: playerId, profileSelectedBySelf: true })
    );
  };

  const resendOtp = () => {
    dispatch(startTimer());
    clearInterval(timerId.current);
    timerId.current = setInterval(() => {
      dispatch(decrementTimer());
    }, 1000);
    dispatch(login({ payload: { mobile, dialCode }, enqueueSnackbar }));
  };

  const acceptTerms = () => {
    dispatch(
      createProfileForOrg({
        payload: {
          clubId: filterType === 'subscription' ? estimates?.subscription?.clubId : 'KCSE',
          utmParams: {},
          promoPromoterCode: referralCode,
        },
        enqueueSnackbar,
      })
    );
  };

  const handleAddProfile = () => {
    dispatch(
      createProfileForOrg({
        payload: {
          firstName: profileName,
          lastName: '',
          gender,
          dob: dateOfBirth,
          promoPromoterCode: referralCode,
          clubId: filterType === 'subscription' ?  estimates?.subscription?.clubId : 'KCSE',
          addDuplicatePlayer: true,
        },
        enqueueSnackbar,
      })
    );
  };

  const handleBack = () => {
    switch (activeStep) {
      case 1:
      case 2:
        dispatch(setActiveStep(0));
        clearInterval(timerId.current);
        dispatch(updateField({ field: 'otp', value: '' }));
        dispatch(resetCheckOutData());
        break;
      case 5:
        dispatch(setActiveStep(3));
        break;
      default:
        break;
    }
  };

  const openAddProfilePopup = () => {
    dispatch(setLoginFormOpen(true));
    dispatch(setActiveStep(3));
  };

  const handleSetLoginFormOpen = (isOpen) => {
    dispatch(setLoginFormOpen(isOpen));
    dispatch(refreshCurrentOrgProfiles());
  };

  const handleContinueWithPayment = () => {
    dispatch(continueWithPayment());
  };

  const redirectToPayment = (invoiceId) => {
    dispatch(setLoginFormOpen(false));
    navigate(`/${currentPlayer?.club?.organization?.id}/invoice/${invoiceId}`);
  };


  return {
    actions: {
      setActiveStep: (step) => dispatch(setActiveStep(step)),
      updateField: (field, value) => dispatch(updateField({ field, value })),
      toggleHaveReferralCode: () => dispatch(toggleHaveReferralCode()),
      handleLogin,
      handleRegister,
      submitOtp,
      handleProfileSelection,
      handleAddProfile,
      acceptTerms,
      resendOtp,
      handleBack,
      setLoginFormOpen: handleSetLoginFormOpen,
      startTimer: handleStartTimer,
      handleContinueWithPayment,
      resetShouldNavigateToCart: () => dispatch(resetShouldNavigateToCart()),
      redirectToPayment,
      openAddProfilePopup,
    },
  };
};

export default useLoginFormState;
