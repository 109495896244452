import React from 'react';
import { Box, Card, Typography, Stack, Avatar } from '@mui/material';
import DynamicAspectRatioImage from '../../../components/DynamicAspectRatioImage';

const Organiser = ({ organiser }) => (
    <Card sx={{ p: 2, bgcolor: 'white', mb: 3 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        {organiser.logo ? (
          <DynamicAspectRatioImage size={56} uri={organiser.logo} />
        ) : (
          <Avatar
            sx={{
              width: 56,
              height: 56,
              bgcolor: 'primary.main',
              fontSize: '1.5rem',
            }}
          >
            {organiser.name.charAt(0)}
          </Avatar>
        )}
        <Box>
          <Typography variant="subtitle2" color="text.secondary">
            Organized by
          </Typography>
          <Typography variant="h6" color="text.primary" fontWeight={600}>
            {organiser.name}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
export default Organiser;