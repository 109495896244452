import React, { forwardRef } from 'react'
import {
    Box,
    Card,
Typography,
   Accordion,
    AccordionSummary,
    AccordionDetails,
  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Faq = forwardRef(({ faq }, ref) => (
        <Card 
            ref={ref}
            sx={{ m: {xs: 0, md:4}, px: { xs:2, md: 8 }, py: 4, bgcolor: 'white', mb:3 }}
        >
            <Box>
                <Typography variant="h6" sx={{ mb: 3, color: '#1B1B1B' }}>
                    FAQ
                </Typography>

                {faq?.data?.questions?.map((faqItem, index) => (
                    <Accordion key={index} sx={{ mb: {md:2} }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ '& .MuiTypography-root':{ fontSize: { md: '1.1rem' }, fontWeight: 600, color: '#333' } }}>
                            <Typography>{faqItem.label}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{pl:{md:3}}}>
                            <Typography>{faqItem.value}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Card>
));

export default Faq;
