import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_STATUS, KRIDA_API } from '../../config';
import axios from '../../utils/axios'
// import { updateUserData } from './user';
import { dispatch } from '../store';

const initialState = {
    isLoading: false,
    loader: false,
    otpGenerated: false,
    eventData: {},
    selectedEvents: {},
    referenceCode: '',
    playerVerifiedData: {},
    paymentData: {},
    leadGenerated: false,
    registrationLoader: false,
    clubs: [],
    acceptanceFormOpen: false,
    registeredData: {},
    addSubscriptionLoader: false,
    eventTimings: [],
    eventClubDataAPIStatus: API_STATUS.IDLE,
    OTPValidationAPIStatus: API_STATUS.IDLE,
}
const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        handleAcceptanceForm: (state, action) => {
            state.acceptanceFormOpen = action.payload
        },
        selectedEventsAction: (state, action) => {
            let selectedEvents = state.selectedEvents;
            selectedEvents = action.payload;
            localStorage.setItem('selectedEvents', JSON.stringify(selectedEvents))
            state.selectedEvents = selectedEvents;

        },
        resetData: () => initialState,
        resetCheckOutData: (state) => {
            return { ...initialState }
        },
        resetEventClubData: (state) => {
            state.eventClubDataAPIStatus = API_STATUS.IDLE;
            state.eventTimings = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEventData.pending, (state, action) => {
            state.isLoading = true;
            state.clubs = []
        });
        builder.addCase(fetchEventData.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action?.payload?.data
            state.eventData = data;
            try {
                state.clubs = Object.values(data?.clubs).reduce((prev, curr) => {
                    return [...prev, ...curr]
                })
            } catch (error) {
                console.log(error)
            }
        });
        builder.addCase(fetchEventData.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchEventClubData.pending, (state, action) => {
            state.eventClubDataAPIStatus = API_STATUS.LOADING;
            state.clubs = []
        });
        builder.addCase(fetchEventClubData.fulfilled, (state, action) => {
            state.eventClubDataAPIStatus = API_STATUS.SUCCEEDED;
            const data = action?.payload?.data
            state.eventTimings = data?.eventDetails
        });
        builder.addCase(fetchEventClubData.rejected, (state) => {
            state.eventClubDataAPIStatus = API_STATUS.FAILED;
        });

        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.otpGenerated = false;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action?.payload?.data
            state.referenceCode = data?.referenceCode
            state.otpGenerated = true;
        });
        builder.addCase(login.rejected, (state) => {
            state.isLoading = false;
            state.otpGenerated = false;
        });
        builder.addCase(signup.pending, (state) => {
            state.isLoading = true;
            state.otpGenerated = false;
        });
        builder.addCase(signup.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action?.payload?.data
            state.referenceCode = data?.referenceCode
            state.otpGenerated = true;
        });
        builder.addCase(signup.rejected, (state) => {
            state.isLoading = false;
            state.otpGenerated = false;
        });
        builder.addCase(otpValidate.pending, (state) => {
            state.OTPValidationAPIStatus = API_STATUS.LOADING;
        });
        builder.addCase(otpValidate.fulfilled, (state, action) => {
            state.OTPValidationAPIStatus = API_STATUS.SUCCEEDED;
            const data = action?.payload?.data
            const token = data?.token
            localStorage.setItem('token', token)
        });
        builder.addCase(otpValidate.rejected, (state, data) => {
            state.OTPValidationAPIStatus = API_STATUS.FAILED;
            if (data?.payload?.error?.code === 401) {
                localStorage.removeItem('token')
            }
        });
        builder.addCase(makePayment.pending, (state) => {
            state.registrationLoader = true;
        });
        builder.addCase(makePayment.fulfilled, (state, action) => {
            state.registrationLoader = false;
            const data = action?.payload?.data
            state.paymentData = data
        });
        builder.addCase(makePayment.rejected, (state, action) => {
            state.registrationLoader = false;
        });
        builder.addCase(generateLead.pending, (state) => {
            state.registrationLoader = true;
            state.leadGenerated = false;
        });
        builder.addCase(generateLead.fulfilled, (state, action) => {
            state.registrationLoader = false;
            state.leadGenerated = true;
        });
        builder.addCase(generateLead.rejected, (state, action) => {
            state.registrationLoader = false;
            state.leadGenerated = false;
        });
        builder.addCase(addSubscription.pending, (state) => {
            state.registrationLoader = true;
            state.addSubscriptionLoader = true
        });
        builder.addCase(addSubscription.fulfilled, (state, action) => {
            state.registrationLoader = false;
            state.registeredData = action.payload;
            state.addSubscriptionLoader = false
        });
        builder.addCase(addSubscription.rejected, (state, action) => {
            state.registrationLoader = false;
            state.leadGenerated = false;
            state.addSubscriptionLoader = false
        });
    }
})

export const fetchEventData = createAsyncThunk('fetchEventData', async (eventId, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}kl/event/${eventId}`,
            {}, { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const fetchEventClubData = createAsyncThunk('fetchEventClubData', async ({ eventId, clubId }, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}kl/event/${eventId}`,
            { includeEventDetails: true, clubId }, { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const generateLead = createAsyncThunk('generateLead', async (data, thunkApi) => {
    const { payload, enqueueSnackbar } = data
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}/lead/offline-registration`, payload,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        enqueueSnackbar('Error while registering', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const login = createAsyncThunk('login', async (data, thunkApi) => {
    const { payload, enqueueSnackbar } = data
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}user/kl/login`, payload,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        enqueueSnackbar(e?.error?.message || 'Something went wrong. Try again', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const signup = createAsyncThunk('signup', async (data, thunkApi) => {
    const { payload, enqueueSnackbar } = data
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}user/kl/register`, payload,
            { headers: { 'content-type': 'application/json' } })
        enqueueSnackbar('OTP sent successfully', { variant: 'success' })
        return responseData
    } catch (e) {
        console.log(e)
        // enqueueSnackbar(e?.error?.message || 'Something went wrong. Try again', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const otpValidate = createAsyncThunk('otpValidate', async (data, thunkApi) => {
    const { payload, enqueueSnackbar } = data
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}user/kl/otp`, payload,
            { headers: { 'content-type': 'application/json' } })
        enqueueSnackbar('OTP validated successfully', { variant: 'success' })
        return responseData
    } catch (e) {
        console.log(e)
        // enqueueSnackbar(e?.error?.message || 'Error while validating OTP', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const makePayment = createAsyncThunk('makePayment', async (data, thunkApi) => {
    const { enqueueSnackbar, invoiceId } = data
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}i/${invoiceId}/initiatepg`,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        return responseData
    } catch (e) {
        console.log(e)
        enqueueSnackbar('Something went wrong while making the payment', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const addSubscription = createAsyncThunk('addSubscription', async (data, thunkApi) => {
    const { enqueueSnackbar, payload } = data
    try {
        const token = localStorage.getItem('token')
        const { data: responseData } = await axios.post(`${KRIDA_API}s`, payload,
            { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` } })
        return responseData.data
    } catch (e) {
        console.log(e)
        enqueueSnackbar('Something went wrong while making the payment', { variant: 'error' })
        return thunkApi.rejectWithValue(e)
    }
});

export const { handleAcceptanceForm, selectedEventsAction, resetData, resetCheckOutData, resetEventClubData } = registrationSlice.actions

export default registrationSlice.reducer;