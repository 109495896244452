import { Link as RouterLink, useHref, useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

const Page400 = () => {
  const routerLocation = useLocation();
  const navigate = useNavigate();

  return (
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 600, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              Something went wrong.
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>There was an error, please try again later.</Typography>

            <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

            <Button to="/" size="large" variant="contained" onClick={()=>{
              const redirectHome = routerLocation.pathname.trim('/').split('/')
              if(redirectHome.length > 1) {
                navigate(redirectHome[1], {replace: true})
              } else {
                navigate('/', {replace: true})
              }

              navigate(0)

            }}>
              Go to Home
            </Button>
          </Box>
        </Container>
      </RootStyle>
  );
};

export default Page400;
