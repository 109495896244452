import React, { forwardRef } from 'react';
import { Box, Card, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Highlights = forwardRef(({ highlights }, ref) => (
        <Card ref={ref} sx={{ m: { xs: 0, md: 4 }, px: { xs: 1, md: 8 }, py: { xs: 2, md: 4 }, bgcolor: 'white', mb: { xs: 3 } }}>
            <Typography variant="h6"  >
                Program Highlights
            </Typography>
            <List>
                {highlights.data.map((highlight) => (
                    <ListItem key={highlight.id}>
                        <CheckCircleIcon color="black" sx={{ marginRight: 2 }} />
                        <ListItemText primary={highlight.label} />
                    </ListItem>
                ))}
            </List>
        </Card>
    ));
export default Highlights; 