import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Modal,
  TextField,
  IconButton,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useResponsive from '../../../hooks/useResponsive';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { selectProfile, userLogout } from '../../../redux/slices/user';
import { resetCart } from '../../../redux/slices/product';
import { createProfileForOrg } from '../../../redux/actions/userActions';
import useLoginFormState from '../../../components/LoginForm/hooks/useLoginFormState';

// ----------------------------------------------------------------------

const AccountPopover = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const smUp = useResponsive('up', 'sm');
  const smDown = useResponsive('down', 'sm');

  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState('');
  const [openAddProfileModal, setOpenAddProfileModal] = useState(false);
  const [openProfiles, setOpenProfiles] = useState(true);

  const { currentPlayer, currentOrgProfiles, loader, createProfileData } = useSelector(
    (state) => state.user
  );

  const { actions: { openAddProfilePopup } } = useLoginFormState();

  useEffect(() => {
    if (createProfileData && Object.keys(createProfileData)?.length) {
      setOpenAddProfileModal(false);
      const player = findLatestObjectByTimestamp(createProfileData?.players || []);
      dispatch(selectProfile({ profileId: player?.id }));
    }
  }, [createProfileData, dispatch]);

  const findLatestObjectByTimestamp = (array) => {
    return array.reduce((latestObject, currentObject) => {
      const latestTimestamp = latestObject ? new Date(latestObject.createdAt) : new Date(0);
      const currentTimestamp = new Date(currentObject.createdAt);
      return currentTimestamp > latestTimestamp ? currentObject : latestObject;
    }, null);
  };

  const handleOpen = (event) => {
    if (currentPlayer && Object.keys(currentPlayer).length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      dispatch(userLogout());
      dispatch(resetCart());
      navigate(0);

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const toggleProfiles = () => {
    setOpenProfiles(!openProfiles);
  };

  const handleAddProfile = () => {
    const data = {
      addDuplicatePlayer: true,
      firstName: name.split(' ')[0] || '',
      lastName: name.split(' ')[1] || '',
    };
    dispatch(createProfileForOrg(data));
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(anchorEl && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar user={currentPlayer} />
      </IconButton>

      <MenuPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {currentPlayer && Object.keys(currentPlayer).length && (
          <>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" noWrap>
                {currentPlayer.firstName} {currentPlayer.lastName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {currentPlayer.mobile}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={toggleProfiles} sx={{ m: 1 }}>
              My Profile
            </MenuItem>

            {openProfiles && (
              <>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <ProfileList
                  profiles={currentOrgProfiles}
                  currentPlayerId={currentPlayer.id}
                  onSelectProfile={(id) => dispatch(selectProfile({ profileId: id }))}
                />
                <MenuItem onClick={openAddProfilePopup} sx={{ mx: 1 }}>
                  <AddCircleOutlineIcon
                    sx={{
                      fontSize: 24,
                      color: 'green',
                      mx: 1,
                    }}
                  />
                  Add new profile
                </MenuItem>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </>
            )}

            <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
              Logout
            </MenuItem>
          </>
        )}
      </MenuPopover>
    </>
  );
};

export default AccountPopover;

// ----------------- Extracted Components -----------------

const ProfileList = ({ profiles, currentPlayerId, onSelectProfile }) => {
  return profiles?.map((item) => (
    <MenuItem key={item.id} onClick={() => onSelectProfile(item.id)}>
      {currentPlayerId !== item.id ? (
        <Box component="span" sx={{ mx: 1 }}>
          -
        </Box>
      ) : (
        <CheckCircleIcon
          sx={{
            fontSize: 24,
            color: 'green',
            mx: 1,
          }}
        />
      )}
      {item.firstName} {item.lastName}
    </MenuItem>
  ));
};