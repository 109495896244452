import { m } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Button, Box, Link, Container, Grid, Typography, Stack, Card, CardMedia,
    CardActions, CardContent, Chip, CardHeader
} from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player'
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { MotionContainer, varFade } from '../../components/animate';
import { MapContainer } from '../../components/google-map';
import MainFooter from '../../layouts/main/MainFooter';

import Image from '../../components/Image';
import useResponsive from '../../hooks/useResponsive';
import { fetchEventData } from '../../redux/slices/registration';

import { HeroLanding } from '../../components/hero';
import { DetailIconListSmall, DetailIconList } from '../../components/detail-icon-list';
import { InfoImageCard } from '../../components/image-info-card';
import CarouselThumbnail from './carousel-thumbnail';



// ----------------------------------------------------------------------


const VideoContainer = styled(m.div)(({ theme }) => ({
    position: 'relative',
    paddingTop: '56.25%',
    '.react-player': {
        position: 'absolute',
        top: 0,
        left: 0
    }
}));

const SasaLanding = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const smUp = useResponsive('down', 'up');

    const mdUp = useResponsive('up', 'md');
    useEffect(() => {
        dispatch(fetchEventData())
    }, [])
    const { clubs } = useSelector((state) => state.registration)

    const registerHereCallback = (selectedClub) => {
        navigate(`${PATH_DASHBOARD.general.register}/event/PSM_SUMMER_CAMP?city=${selectedClub.city}&club=${selectedClub.id}`);
        localStorage.clear();
    }

    return (
        <MotionContainer>
            <Container component={MotionContainer} maxWidth={false} sx={{
                padding: smUp ? 5 : 0, background: `linear-gradient(to right, #000101, #00204F)`,
                minHeight: '100vh', display: 'flex', alignItems: 'center'
            }}>
                <Grid container sx={{ alignItems: 'center' }} >
                    <Grid item xs={12} lg={6} sx={{ padding: smUp ? 8 : 4, paddingX: 4 }}>
                        <Box raised sx={{ margin: smUp ? 2 : 0, background: `linear-gradient(to right, #202b39, #1a2639)`, borderRadius: 3, padding: 4, }}>
                            <Stack>
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        borderRadius: 5,
                                        width: 80, // 80px content + 5px padding on each side
                                        height: 80, // Same for height
                                        padding: '5px',
                                        backgroundImage: `url('https://ik.imagekit.io/krida/SASA%20(1).png?updatedAt=1703835687367')`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center', // Ensure background image doesn't fill the padding
                                    }}
                                />
                                <Typography variant='h2' sx={{}} fontWeight={600} color={"white"}>Southern Alpha Sports Academy</Typography>
                                <Typography variant='h6' pb={3} fontWeight={600} color={"white"}>Excellence in sports</Typography>
                                <Typography variant='body2' color={"white"}>Start your sporting journey today. Choose from our wide range of Coaching programs at our multiple locations across India</Typography>

                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ padding: smUp ? 8 : 4 }}>
                        <Image raised sx={{ borderRadius: 5 }} src={'https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/247187545_113687274427576_6152879709503008782_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=fK-vI2Zsge4AX_9NKmh&_nc_ht=scontent.fblr1-6.fna&oh=00_AfCAivC_4gPKBa7z_HZGkoJ3mRvDD--8kdHKBRAsX2S8AA&oe=6593D397'} />
                    </Grid>
                </Grid>

            </Container>

            <Container maxWidth="lg" component={MotionContainer}>
                <Box py={5} textAlign={'center'}>
                    <Typography variant={smUp ? 'h4' : 'h3'}>
                        Locate your nearest center
                    </Typography>
                </Box>
                <Grid container justifyContent={'space-around'}>
                    <Card sx={{ maxWidth: 345, marginY: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                            <CardMedia
                                component="img"
                                height="140"
                                src='https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/264429291_131058019357168_9162241381766349920_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=c83dfd&_nc_ohc=lLFsumYQHbUAX-VkmCS&_nc_ht=scontent.fblr1-7.fna&oh=00_AfCpVswPMAZowv-I9q8_h1KqtbGA7-GjknN26o5nVHkTCg&oe=6593851D'
                                alt="Running Track"
                                sx={{ borderRadius: 1, marginRight: 1 }}
                            />

                            <CardMedia
                                component="img"
                                height="140"
                                src='https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/257838866_124380723358231_1596852635162562251_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=Fe7JIWqmTwcAX9SkF3N&_nc_oc=AQm0OpQ0ubzGfQmKp4RieOEjY1fB4fbnjhIxCKf9A1g9kknyMfx1KC5gh-Qz_8uSu5sTldE-uXAPePMX1297Fdzt&_nc_ht=scontent.fblr1-6.fna&oh=00_AfBaqbk8z7bBenGpLvIEL22iYYHaC-QdUUFxdU0C417dmQ&oe=65943136'
                                alt="Badminton Courts"
                                sx={{ borderRadius: 1, marginLeft: 1 }}

                            />
                        </Box>

                        <CardContent>
                            <Stack direction="row" spacing={1}>
                                <Chip label="Hockey" variant="outlined" />
                                <Chip label="Football" variant="outlined" />
                            </Stack>

                            <Typography gutterBottom variant="h5" component="div">
                                Southern Alpha Sports Academy
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                Rajasree Layout
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                Bengaluru
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <Button size="small">View Direction</Button>

                        </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 345, marginY: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                            <CardMedia
                                component="img"
                                height="140"
                                src='https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/264429291_131058019357168_9162241381766349920_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=c83dfd&_nc_ohc=lLFsumYQHbUAX-VkmCS&_nc_ht=scontent.fblr1-7.fna&oh=00_AfCpVswPMAZowv-I9q8_h1KqtbGA7-GjknN26o5nVHkTCg&oe=6593851D'
                                alt="Running Track"
                                sx={{ borderRadius: 1, marginRight: 1 }}
                            />

                            <CardMedia
                                component="img"
                                height="140"
                                src='https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/257838866_124380723358231_1596852635162562251_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=Fe7JIWqmTwcAX9SkF3N&_nc_oc=AQm0OpQ0ubzGfQmKp4RieOEjY1fB4fbnjhIxCKf9A1g9kknyMfx1KC5gh-Qz_8uSu5sTldE-uXAPePMX1297Fdzt&_nc_ht=scontent.fblr1-6.fna&oh=00_AfBaqbk8z7bBenGpLvIEL22iYYHaC-QdUUFxdU0C417dmQ&oe=65943136'
                                alt="Badminton Courts"
                                sx={{ borderRadius: 1, marginLeft: 1 }}

                            />
                        </Box>

                        <CardContent>
                            <Stack direction="row" spacing={1}>
                                <Chip label="Hockey" variant="outlined" />
                                <Chip label="Football" variant="outlined" />
                            </Stack>

                            <Typography gutterBottom variant="h5" component="div">
                                Southern Alpha Sports Academy
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                Langford Town
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                Bengaluru
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <Button size="small">View Direction</Button>

                        </CardActions>
                    </Card>
                    {/* <Card sx={{ maxWidth: 345, marginY: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                            <CardMedia
                                component="img"
                                height="140"
                                src='https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/264429291_131058019357168_9162241381766349920_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=c83dfd&_nc_ohc=lLFsumYQHbUAX-VkmCS&_nc_ht=scontent.fblr1-7.fna&oh=00_AfCpVswPMAZowv-I9q8_h1KqtbGA7-GjknN26o5nVHkTCg&oe=6593851D'
                                alt="Running Track"
                                sx={{ borderRadius: 1, marginRight: 1 }}
                            />

                            <CardMedia
                                component="img"
                                height="140"
                                src='https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/257838866_124380723358231_1596852635162562251_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=Fe7JIWqmTwcAX9SkF3N&_nc_oc=AQm0OpQ0ubzGfQmKp4RieOEjY1fB4fbnjhIxCKf9A1g9kknyMfx1KC5gh-Qz_8uSu5sTldE-uXAPePMX1297Fdzt&_nc_ht=scontent.fblr1-6.fna&oh=00_AfBaqbk8z7bBenGpLvIEL22iYYHaC-QdUUFxdU0C417dmQ&oe=65943136'
                                alt="Badminton Courts"
                                sx={{ borderRadius: 1, marginLeft: 1 }}

                            />
                        </Box>

                        <CardContent>
                            <Stack direction="row" spacing={1}>
                                <Chip label="Hockey" variant="outlined" />
                                <Chip label="Football" variant="outlined" />
                            </Stack>

                            <Typography gutterBottom variant="h5" component="div">
                                Southern Alpha Sports Academy
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                Langford Town
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                Bengaluru
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <Button size="small">View Direction</Button>

                        </CardActions>
                    </Card> */}
                </Grid>
            </Container>
            <Container maxWidth="lg" component={MotionContainer}>
                <Box py={5} textAlign={'center'}>
                    <Typography variant={'h3'}>
                        Our offerings
                    </Typography>
                </Box>
                <Grid container spacing={6} justifyContent={'center'}>
                    <Grid item xs={12} sm={6} md={3}>
                        <ProgramCard
                            title="Junior Coaching Program"
                            description="Give your child the right start with step-by-step beginner coaching."
                            imageUrl="https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/373062155_17949609440674265_4930849731189780379_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=3635dc&_nc_ohc=VGVyCTwheFoAX8o0lUE&_nc_ht=scontent.fblr1-7.fna&oh=00_AfBos03yuyGgHJF9fyfkSrtO7SuTkW8i94Tnp-KcxHZTnw&oe=65937C2F"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <ProgramCard
                            title="Adult Hockey Coaching"
                            description="Start playing today with the finest of coaches of hockey in India."
                            imageUrl="https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/400746905_354430690440359_5196727078907881756_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=g22F6VbUIbEAX_Rvg4M&_nc_ht=scontent.fblr1-6.fna&oh=00_AfDRrbwyI6YjNN78mbiLPNvXd7Tm4gfVEQSFfHlA1UlZ9g&oe=65945CB0"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <ProgramCard
                            title="High Performance Coaching"
                            description="Give your child the right start with step-by-step beginner coaching."
                            imageUrl="https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/246875963_113523277777309_8409689972994144930_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=9534ce&_nc_ohc=QXb88SZRriQAX_efzLy&_nc_ht=scontent.fblr1-7.fna&oh=00_AfCUP1xk_q1lqjYoS3En4er5Ovw_5gNoxYM8Ld8S8sNYXQ&oe=6593C100"
                        />
                    </Grid>
                    {/* Repeat Grid items for each card with respective props */}
                </Grid>
            </Container>

            <DetailIconListSmall />

            <DetailIconList />
            {/* <Container maxWidth="md" component={MotionContainer} pb={4}>
                <Stack alignItems={'center'} paddingTop={8} paddingBottom={5}>
                    <Typography variant={smUp ? 'h4' : 'h3'}>
                        A sneak peek into our Summer Camp
                    </Typography>
                    <Typography variant='body2' maxWidth={600} textAlign={'center'}>
                        Summer camp kids can enjoy a fun day of badminton games, coaching, and tournaments to fuel their passion

                    </Typography>

                </Stack>
                <Stack>
                    <VideoContainer>
                        <ReactPlayer className={'react-player'} width={'100%'} height={'100%'}
                            playing={false} config={{ youtube: { playerVars: { disablekb: 1, controls: 1, rel: 0 } } }}
                            url='https://www.youtube.com/embed/GOIQkROA2ec' />
                    </VideoContainer>
                </Stack>

            </Container> */}

            {/* <InfoImageCard /> */}
            <Container maxWidth="lg" component={MotionContainer} py={4}>
                <Card>
                    <CardHeader title="Gallery" />
                    <CardContent>
                        <CarouselThumbnail data={[{
                            id: 1,
                            coverUrl: 'https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/246875963_113523277777309_8409689972994144930_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=9534ce&_nc_ohc=QXb88SZRriQAX_efzLy&_nc_ht=scontent.fblr1-7.fna&oh=00_AfCUP1xk_q1lqjYoS3En4er5Ovw_5gNoxYM8Ld8S8sNYXQ&oe=6593C100'
                        },
                        {
                            id: 2,
                            coverUrl: 'https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/400746905_354430690440359_5196727078907881756_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=g22F6VbUIbEAX_Rvg4M&_nc_ht=scontent.fblr1-6.fna&oh=00_AfDRrbwyI6YjNN78mbiLPNvXd7Tm4gfVEQSFfHlA1UlZ9g&oe=65945CB0'
                        },
                        {
                            id: 3,
                            coverUrl: 'https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/373062155_17949609440674265_4930849731189780379_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=3635dc&_nc_ohc=VGVyCTwheFoAX8o0lUE&_nc_ht=scontent.fblr1-7.fna&oh=00_AfBos03yuyGgHJF9fyfkSrtO7SuTkW8i94Tnp-KcxHZTnw&oe=65937C2F'
                        },
                        {
                            id: 4,
                            coverUrl: 'https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/257838866_124380723358231_1596852635162562251_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=Fe7JIWqmTwcAX9SkF3N&_nc_oc=AQm0OpQ0ubzGfQmKp4RieOEjY1fB4fbnjhIxCKf9A1g9kknyMfx1KC5gh-Qz_8uSu5sTldE-uXAPePMX1297Fdzt&_nc_ht=scontent.fblr1-6.fna&oh=00_AfBaqbk8z7bBenGpLvIEL22iYYHaC-QdUUFxdU0C417dmQ&oe=65943136'
                        },
                        {
                            id: 5,
                            coverUrl: 'https://scontent.fblr1-7.fna.fbcdn.net/v/t39.30808-6/264429291_131058019357168_9162241381766349920_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=c83dfd&_nc_ohc=lLFsumYQHbUAX-VkmCS&_nc_ht=scontent.fblr1-7.fna&oh=00_AfCpVswPMAZowv-I9q8_h1KqtbGA7-GjknN26o5nVHkTCg&oe=6593851D'
                        },
                        {
                            id: 6,
                            coverUrl: 'https://scontent.fblr1-6.fna.fbcdn.net/v/t39.30808-6/247187545_113687274427576_6152879709503008782_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=fK-vI2Zsge4AX_9NKmh&_nc_ht=scontent.fblr1-6.fna&oh=00_AfCAivC_4gPKBa7z_HZGkoJ3mRvDD--8kdHKBRAsX2S8AA&oe=6593D397'
                        }
                        ]} />
                    </CardContent>
                </Card>
            </Container>
            <Container maxWidth="md" component={MotionContainer} pb={4}>
                <Box>
                    <Typography variant={'h6'} pt={{ xs: 4, md: 8 }} pb={2} align="center" >
                        Not sure about which course to join?
                    </Typography>
                    <Stack alignItems='center'>
                        <Box maxWidth={320} width={'80%'} textAlign={'center'}>
                            <Typography align="center" variant='caption'>
                                If you’re feeling confused, give us a call on 9901490016 to speak with one of our expert badminton coaches to pick the right training program.
                            </Typography>
                        </Box>

                    </Stack>

                </Box>
            </Container>
            <Card raised sx={{ py: 2, display: 'flex', justifyContent: 'center' }}  >
                <Grid container spacing={1} maxWidth={'lg'}>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Stack alignItems={'center'} justifyContent={'center'}>
                                <Typography variant='subtitle2'>
                                    Call to enquire
                                </Typography>

                                <Typography variant='h6'>
                                    9901490016
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack justifyContent={'flex-end'}>
                                <Button sx={{
                                    width: '75%', maxWidth: '280px', alignSelf: 'center', backgroundColor: '#193052', ":hover": {
                                        background: "#193052"
                                    }
                                }} variant="contained" size="large" // disabled
                                    onClick={(e) => {
                                        // localStorage.clear()
                                    }}
                                >
                                    Enquire Now
                                </Button>
                            </Stack></Grid>

                    </Grid>

                </Grid>
            </Card>
            <Card raised sx={{ py: 2, display: 'flex', justifyContent: 'center' }}  >
                <Grid container spacing={1} maxWidth={'lg'}>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} md={5}>
                            <Stack alignItems={'center'} justifyContent={'center'}>
                                <Typography variant='h6'>
                                    Address
                                </Typography>

                                <Typography variant='h6'>
                                Godrej Aqua, Hebbal, Bangalore, 560025
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Stack justifyContent={'flex-end'}>
                            <Typography variant='h6'>
                                    Phone
                                </Typography>

                                <Typography variant='h6'>
                                99014 90016
                                </Typography>
                            </Stack></Grid>
                        <Grid item xs={12} md={4}>
                            <Stack justifyContent={'flex-end'}>
                            <Typography variant='h6'>
                                    Email
                                </Typography>

                                <Typography variant='h6'>
                                Southernalphasports@gmail.com
                                </Typography>
                            </Stack></Grid>
                    </Grid>

                </Grid>
            </Card>
            <MainFooter />

        </MotionContainer>
    )
}

export default SasaLanding;

const ProgramCard = ({ title, description, imageUrl }) => {
    return (
        <Card sx={{ maxWidth: 345, borderRadius: 2 }}>
            <CardMedia
                component="img"
                height="140"
                image={imageUrl}
                alt={title}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};