// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const IconTiming = ({ ...other }) => {
  const theme = useTheme();

  return (
    <Box {...other}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="22" fill="#62ECBC" />
        <g clipPath="url(#clip0_2795_8060)">
          <path d="M21.2312 10C21.8029 10.1909 22.0342 10.5922 21.9967 11.1788C21.9776 11.4837 21.9935 11.7905 21.9935 12.0671C22.7031 12.2145 23.4088 12.3015 24.0752 12.51C28.3334 13.8444 30.9436 16.6908 31.8946 21.0165C32.0172 21.5741 32.0445 22.155 32.0852 22.727C32.122 23.243 31.8019 23.6003 31.3426 23.6072C30.8928 23.6141 30.5961 23.2821 30.5644 22.7749C30.3211 18.8537 28.4286 15.9965 24.8223 14.3805C19.4385 11.9694 13.051 15.1996 11.7538 20.9183C10.6173 25.9294 13.6157 30.8486 18.6235 32.1818C19.5573 32.43 20.5102 32.5201 21.4764 32.4861C21.6174 32.481 21.7661 32.4804 21.9001 32.5188C22.2298 32.6127 22.4426 32.9403 22.4198 33.2799C22.3969 33.622 22.1523 33.9275 21.8023 33.9597C21.3614 34 20.9136 34.012 20.4714 33.988C18.4812 33.8797 16.6326 33.3082 14.9777 32.2007C12.18 30.327 10.4858 27.7313 10.0901 24.3979C9.62568 20.4835 10.9578 17.21 13.9607 14.6357C15.5984 13.232 17.5289 12.4312 19.6805 12.1673C19.8063 12.1515 19.9315 12.1326 20.0877 12.1112C20.0877 11.7956 20.103 11.4742 20.0845 11.1548C20.0509 10.5752 20.2916 10.1871 20.85 10L21.2312 10Z" fill="#193052" />
          <path d="M21.2312 10C21.8029 10.1909 22.0342 10.5922 21.9967 11.1788C21.9776 11.4837 21.9935 11.7905 21.9935 12.0671C22.7031 12.2145 23.4088 12.3015 24.0752 12.51C28.3334 13.8444 30.9436 16.6908 31.8946 21.0165C32.0172 21.5741 32.0445 22.155 32.0852 22.727C32.122 23.243 31.8019 23.6003 31.3426 23.6072C30.8928 23.6141 30.5961 23.2821 30.5644 22.7749C30.3211 18.8537 28.4286 15.9965 24.8223 14.3805C19.4385 11.9694 13.051 15.1996 11.7538 20.9183C10.6173 25.9294 13.6157 30.8486 18.6235 32.1818C19.5573 32.43 20.5102 32.5201 21.4764 32.4861C21.6174 32.481 21.7661 32.4804 21.9001 32.5188C22.2298 32.6127 22.4426 32.9403 22.4198 33.2799C22.3969 33.622 22.1523 33.9275 21.8023 33.9597C21.3614 34 20.9136 34.012 20.4714 33.988C18.4812 33.8797 16.6326 33.3082 14.9777 32.2007C12.18 30.327 10.4858 27.7313 10.0901 24.3979C9.62568 20.4835 10.9578 17.21 13.9607 14.6357C15.5984 13.232 17.5289 12.4312 19.6805 12.1673C19.8063 12.1515 19.9315 12.1326 20.0877 12.1112C20.0877 11.7956 20.103 11.4742 20.0845 11.1548C20.0509 10.5752 20.2916 10.1871 20.85 10L21.2312 10Z" fill="black" fillOpacity="0.2" />
          <path d="M24.3758 28.5083C24.3758 27.2168 24.379 25.9252 24.3746 24.6337C24.3727 24.0238 24.6077 23.5431 25.1464 23.2401C25.687 22.9364 26.2244 22.9912 26.7473 23.3075C28.8951 24.6047 31.0429 25.9013 33.1932 27.1947C33.5699 27.4215 33.8405 27.7246 33.9492 28.1523C34.1188 28.8183 33.8539 29.4452 33.2269 29.8276C32.0987 30.5162 30.9641 31.1947 29.8321 31.877C28.8017 32.4982 27.7738 33.1232 26.7409 33.74C25.8344 34.2818 24.7532 33.9158 24.4533 32.9632C24.3974 32.7868 24.379 32.5921 24.3777 32.4056C24.3714 31.1065 24.3746 29.8068 24.3746 28.5077L24.3758 28.5083ZM25.9119 24.5751V32.4718C28.1004 31.1519 30.2615 29.849 32.4595 28.5234C30.2628 27.1991 28.1023 25.8956 25.9119 24.5751Z" fill="#193052" />
          <path d="M24.3758 28.5083C24.3758 27.2168 24.379 25.9252 24.3746 24.6337C24.3727 24.0238 24.6077 23.5431 25.1464 23.2401C25.687 22.9364 26.2244 22.9912 26.7473 23.3075C28.8951 24.6047 31.0429 25.9013 33.1932 27.1947C33.5699 27.4215 33.8405 27.7246 33.9492 28.1523C34.1188 28.8183 33.8539 29.4452 33.2269 29.8276C32.0987 30.5162 30.9641 31.1947 29.8321 31.877C28.8017 32.4982 27.7738 33.1232 26.7409 33.74C25.8344 34.2818 24.7532 33.9158 24.4533 32.9632C24.3974 32.7868 24.379 32.5921 24.3777 32.4056C24.3714 31.1065 24.3746 29.8068 24.3746 28.5077L24.3758 28.5083ZM25.9119 24.5751V32.4718C28.1004 31.1519 30.2615 29.849 32.4595 28.5234C30.2628 27.1991 28.1023 25.8956 25.9119 24.5751Z" fill="black" fillOpacity="0.2" />
          <path d="M21.0971 21.3413C21.3861 21.4963 21.5278 21.2456 21.6987 21.0351C22.5975 19.925 23.5015 18.8187 24.4023 17.7093C24.6081 17.456 24.8635 17.3205 25.1926 17.3886C25.4969 17.4516 25.6982 17.6418 25.7795 17.943C25.8539 18.2177 25.7618 18.4495 25.5864 18.6637C24.6526 19.8066 23.7232 20.9539 22.7875 22.0948C22.6649 22.2441 22.6128 22.3651 22.6801 22.573C22.9056 23.2686 22.6179 24.0303 22.0118 24.4417C21.4007 24.8568 20.5978 24.8392 20.0089 24.3969C19.4175 23.9521 19.1811 23.1955 19.4175 22.4987C19.6525 21.8057 20.3094 21.3388 21.0971 21.3407V21.3413Z" fill="#193052" />
          <path d="M21.0971 21.3413C21.3861 21.4963 21.5278 21.2456 21.6987 21.0351C22.5975 19.925 23.5015 18.8187 24.4023 17.7093C24.6081 17.456 24.8635 17.3205 25.1926 17.3886C25.4969 17.4516 25.6982 17.6418 25.7795 17.943C25.8539 18.2177 25.7618 18.4495 25.5864 18.6637C24.6526 19.8066 23.7232 20.9539 22.7875 22.0948C22.6649 22.2441 22.6128 22.3651 22.6801 22.573C22.9056 23.2686 22.6179 24.0303 22.0118 24.4417C21.4007 24.8568 20.5978 24.8392 20.0089 24.3969C19.4175 23.9521 19.1811 23.1955 19.4175 22.4987C19.6525 21.8057 20.3094 21.3388 21.0971 21.3407V21.3413Z" fill="black" fillOpacity="0.2" />
          <path d="M14.7606 11.7213C14.6989 11.8228 14.6259 12.0489 14.4709 12.1787C13.605 12.9045 12.7227 13.6126 11.8378 14.3158C11.5061 14.5791 11.0526 14.5199 10.7908 14.2112C10.519 13.8911 10.5412 13.4325 10.8772 13.1546C11.7552 12.4301 12.642 11.7163 13.5326 11.0069C13.7753 10.8135 14.0567 10.7939 14.3381 10.9382C14.6094 11.0774 14.7364 11.3087 14.7612 11.7213H14.7606Z" fill="#193052" />
          <path d="M14.7606 11.7213C14.6989 11.8228 14.6259 12.0489 14.4709 12.1787C13.605 12.9045 12.7227 13.6126 11.8378 14.3158C11.5061 14.5791 11.0526 14.5199 10.7908 14.2112C10.519 13.8911 10.5412 13.4325 10.8772 13.1546C11.7552 12.4301 12.642 11.7163 13.5326 11.0069C13.7753 10.8135 14.0567 10.7939 14.3381 10.9382C14.6094 11.0774 14.7364 11.3087 14.7612 11.7213H14.7606Z" fill="black" fillOpacity="0.2" />
          <path d="M31.4747 13.7388C31.4531 14.0292 31.3229 14.2668 31.037 14.4016C30.7416 14.5415 30.4627 14.4961 30.215 14.2945C29.6382 13.8258 29.0671 13.3513 28.4934 12.8788C28.2311 12.6627 27.9674 12.4485 27.7063 12.2305C27.3309 11.9168 27.2693 11.4657 27.552 11.1274C27.8378 10.7846 28.292 10.7538 28.6726 11.0644C29.499 11.7391 30.3204 12.4202 31.1456 13.0968C31.3464 13.2613 31.4664 13.4622 31.4747 13.7388Z" fill="#193052" />
          <path d="M31.4747 13.7388C31.4531 14.0292 31.3229 14.2668 31.037 14.4016C30.7416 14.5415 30.4627 14.4961 30.215 14.2945C29.6382 13.8258 29.0671 13.3513 28.4934 12.8788C28.2311 12.6627 27.9674 12.4485 27.7063 12.2305C27.3309 11.9168 27.2693 11.4657 27.552 11.1274C27.8378 10.7846 28.292 10.7538 28.6726 11.0644C29.499 11.7391 30.3204 12.4202 31.1456 13.0968C31.3464 13.2613 31.4664 13.4622 31.4747 13.7388Z" fill="black" fillOpacity="0.2" />
        </g>
        <defs>
          <clipPath id="clip0_2795_8060">
            <rect width="24" height="24" fill="white" transform="translate(10 10)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};

export default IconTiming;
