import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'

const initialState = {
    fetchPlayerAssessmentDetailAPI: 'idle',
    playerAssessmentDetail: {},
    error: {},
}
const assessmentSlice = createSlice({
    name: 'assessment',
    initialState,
    reducers: {
        resetAssessmentData: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAssessmentDetailsForPlayer.pending, (state) => {
            state.fetchPlayerAssessmentDetailAPI = 'loading';
        });
        builder.addCase(fetchAssessmentDetailsForPlayer.fulfilled, (state, action) => {
            state.fetchPlayerAssessmentDetailAPI = 'succeeded';
            state.playerAssessmentDetail = action?.payload?.data;
        });
        builder.addCase(fetchAssessmentDetailsForPlayer.rejected, (state, action) => {
            state.fetchPlayerAssessmentDetailAPI = 'failed';
            state.error = action?.payload
        });
    }
})

export const fetchAssessmentDetailsForPlayer = createAsyncThunk('playerAssessment', async ({playerAssessmentId}, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`assessment_players/${playerAssessmentId}`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error)
        return thunkApi.rejectWithValue(error)
    }
});


export const { resetAssessmentData } = assessmentSlice.actions

export default assessmentSlice.reducer;