import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';


// Import flag images
import ad from '../assets/icons/flagpack/ad.webp';
import ae from '../assets/icons/flagpack/ae.webp';
import af from '../assets/icons/flagpack/af.webp';
import ag from '../assets/icons/flagpack/ag.webp';
import ai from '../assets/icons/flagpack/ai.webp';
import al from '../assets/icons/flagpack/al.webp';
import am from '../assets/icons/flagpack/am.webp';
import ao from '../assets/icons/flagpack/ao.webp';
import aq from '../assets/icons/flagpack/aq.webp';
import ar from '../assets/icons/flagpack/ar.webp';
import as from '../assets/icons/flagpack/as.webp';
import at from '../assets/icons/flagpack/at.webp';
import au from '../assets/icons/flagpack/au.webp';
import aw from '../assets/icons/flagpack/aw.webp';
import ax from '../assets/icons/flagpack/ax.webp';
import az from '../assets/icons/flagpack/az.webp';
import ba from '../assets/icons/flagpack/ba.webp';
import bb from '../assets/icons/flagpack/bb.webp';
import bd from '../assets/icons/flagpack/bd.webp';
import be from '../assets/icons/flagpack/be.webp';
import bf from '../assets/icons/flagpack/bf.webp';
import bg from '../assets/icons/flagpack/bg.webp';
import bh from '../assets/icons/flagpack/bh.webp';
import bi from '../assets/icons/flagpack/bi.webp';
import bj from '../assets/icons/flagpack/bj.webp';
import bl from '../assets/icons/flagpack/bl.webp';
import bm from '../assets/icons/flagpack/bm.webp';
import bn from '../assets/icons/flagpack/bn.webp';
import bo from '../assets/icons/flagpack/bo.webp';
import br from '../assets/icons/flagpack/br.webp';
import bs from '../assets/icons/flagpack/bs.webp';
import bt from '../assets/icons/flagpack/bt.webp';
import bv from '../assets/icons/flagpack/bv.webp';
import bw from '../assets/icons/flagpack/bw.webp';
import by from '../assets/icons/flagpack/by.webp';
import bz from '../assets/icons/flagpack/bz.webp';
import ca from '../assets/icons/flagpack/ca.webp';
import cc from '../assets/icons/flagpack/cc.webp';
import cd from '../assets/icons/flagpack/cd.webp';
import cf from '../assets/icons/flagpack/cf.webp';
import cg from '../assets/icons/flagpack/cg.webp';
import ch from '../assets/icons/flagpack/ch.webp';
import ci from '../assets/icons/flagpack/ci.webp';
import ck from '../assets/icons/flagpack/ck.webp';
import cl from '../assets/icons/flagpack/cl.webp';
import cm from '../assets/icons/flagpack/cm.webp';
import cn from '../assets/icons/flagpack/cn.webp';
import co from '../assets/icons/flagpack/co.webp';
import cr from '../assets/icons/flagpack/cr.webp';
import cu from '../assets/icons/flagpack/cu.webp';
import cv from '../assets/icons/flagpack/cv.webp';
import cw from '../assets/icons/flagpack/cw.webp';
import cx from '../assets/icons/flagpack/cx.webp';
import cy from '../assets/icons/flagpack/cy.webp';
import cz from '../assets/icons/flagpack/cz.webp';
import de from '../assets/icons/flagpack/de.webp';
import dj from '../assets/icons/flagpack/dj.webp';
import dk from '../assets/icons/flagpack/dk.webp';
import dm from '../assets/icons/flagpack/dm.webp';
import doImage from '../assets/icons/flagpack/do.webp';
import dz from '../assets/icons/flagpack/dz.webp';
import ec from '../assets/icons/flagpack/ec.webp';
import ee from '../assets/icons/flagpack/ee.webp';
import eg from '../assets/icons/flagpack/eg.webp';
import eh from '../assets/icons/flagpack/eh.webp';
import er from '../assets/icons/flagpack/er.webp';
import es from '../assets/icons/flagpack/es.webp';
import et from '../assets/icons/flagpack/et.webp';
import fi from '../assets/icons/flagpack/fi.webp';
import fj from '../assets/icons/flagpack/fj.webp';
import fk from '../assets/icons/flagpack/fk.webp';
import fm from '../assets/icons/flagpack/fm.webp';
import fo from '../assets/icons/flagpack/fo.webp';
import fr from '../assets/icons/flagpack/fr.webp';
import ga from '../assets/icons/flagpack/ga.webp';
import gb from '../assets/icons/flagpack/gb.webp';
import gd from '../assets/icons/flagpack/gd.webp';
import ge from '../assets/icons/flagpack/ge.webp';
import gf from '../assets/icons/flagpack/gf.webp';
import gg from '../assets/icons/flagpack/gg.webp';
import gh from '../assets/icons/flagpack/gh.webp';
import gi from '../assets/icons/flagpack/gi.webp';
import gl from '../assets/icons/flagpack/gl.webp';
import gm from '../assets/icons/flagpack/gm.webp';
import gn from '../assets/icons/flagpack/gn.webp';
import gp from '../assets/icons/flagpack/gp.webp';
import gq from '../assets/icons/flagpack/gq.webp';
import gr from '../assets/icons/flagpack/gr.webp';
import gs from '../assets/icons/flagpack/gs.webp';
import gt from '../assets/icons/flagpack/gt.webp';
import gu from '../assets/icons/flagpack/gu.webp';
import gw from '../assets/icons/flagpack/gw.webp';
import gy from '../assets/icons/flagpack/gy.webp';
import hk from '../assets/icons/flagpack/hk.webp';
import hm from '../assets/icons/flagpack/hm.webp';
import hn from '../assets/icons/flagpack/hn.webp';
import hr from '../assets/icons/flagpack/hr.webp';
import ht from '../assets/icons/flagpack/ht.webp';
import hu from '../assets/icons/flagpack/hu.webp';
import id from '../assets/icons/flagpack/id.webp';
import ie from '../assets/icons/flagpack/ie.webp';
import il from '../assets/icons/flagpack/il.webp';
import im from '../assets/icons/flagpack/im.webp';
import flagIn from '../assets/icons/flagpack/in.webp';
import io from '../assets/icons/flagpack/io.webp';
import iq from '../assets/icons/flagpack/iq.webp';
import ir from '../assets/icons/flagpack/ir.webp';
import is from '../assets/icons/flagpack/is.webp';
import it from '../assets/icons/flagpack/it.webp';
import je from '../assets/icons/flagpack/je.webp';
import jm from '../assets/icons/flagpack/jm.webp';
import jo from '../assets/icons/flagpack/jo.webp';
import jp from '../assets/icons/flagpack/jp.webp';
import ke from '../assets/icons/flagpack/ke.webp';
import kg from '../assets/icons/flagpack/kg.webp';
import kh from '../assets/icons/flagpack/kh.webp';
import ki from '../assets/icons/flagpack/ki.webp';
import km from '../assets/icons/flagpack/km.webp';
import kn from '../assets/icons/flagpack/kn.webp';
import kp from '../assets/icons/flagpack/kp.webp';
import kr from '../assets/icons/flagpack/kr.webp';
import kw from '../assets/icons/flagpack/kw.webp';
import ky from '../assets/icons/flagpack/ky.webp';
import kz from '../assets/icons/flagpack/kz.webp';
import la from '../assets/icons/flagpack/la.webp';
import lb from '../assets/icons/flagpack/lb.webp';
import lc from '../assets/icons/flagpack/lc.webp';
import li from '../assets/icons/flagpack/li.webp';
import lk from '../assets/icons/flagpack/lk.webp';
import lr from '../assets/icons/flagpack/lr.webp';
import ls from '../assets/icons/flagpack/ls.webp';
import lt from '../assets/icons/flagpack/lt.webp';
import lu from '../assets/icons/flagpack/lu.webp';
import lv from '../assets/icons/flagpack/lv.webp';
import ly from '../assets/icons/flagpack/ly.webp';
import ma from '../assets/icons/flagpack/ma.webp';
import mc from '../assets/icons/flagpack/mc.webp';
import md from '../assets/icons/flagpack/md.webp';
import me from '../assets/icons/flagpack/me.webp';
import mf from '../assets/icons/flagpack/mf.webp';
import mg from '../assets/icons/flagpack/mg.webp';
import mh from '../assets/icons/flagpack/mh.webp';
import mk from '../assets/icons/flagpack/mk.webp';
import ml from '../assets/icons/flagpack/ml.webp';
import mm from '../assets/icons/flagpack/mm.webp';
import mn from '../assets/icons/flagpack/mn.webp';
import mo from '../assets/icons/flagpack/mo.webp';
import mp from '../assets/icons/flagpack/mp.webp';
import mq from '../assets/icons/flagpack/mq.webp';
import mr from '../assets/icons/flagpack/mr.webp';
import ms from '../assets/icons/flagpack/ms.webp';
import mt from '../assets/icons/flagpack/mt.webp';
import mu from '../assets/icons/flagpack/mu.webp';
import mv from '../assets/icons/flagpack/mv.webp';
import mw from '../assets/icons/flagpack/mw.webp';
import mx from '../assets/icons/flagpack/mx.webp';
import my from '../assets/icons/flagpack/my.webp';
import mz from '../assets/icons/flagpack/mz.webp';
import na from '../assets/icons/flagpack/na.webp';
import nc from '../assets/icons/flagpack/nc.webp';
import ne from '../assets/icons/flagpack/ne.webp';
import nf from '../assets/icons/flagpack/nf.webp';
import ng from '../assets/icons/flagpack/ng.webp';
import ni from '../assets/icons/flagpack/ni.webp';
import nl from '../assets/icons/flagpack/nl.webp';
import no from '../assets/icons/flagpack/no.webp';
import np from '../assets/icons/flagpack/np.webp';
import nr from '../assets/icons/flagpack/nr.webp';
import nu from '../assets/icons/flagpack/nu.webp';
import nz from '../assets/icons/flagpack/nz.webp';
import om from '../assets/icons/flagpack/om.webp';
import pa from '../assets/icons/flagpack/pa.webp';
import pe from '../assets/icons/flagpack/pe.webp';
import pf from '../assets/icons/flagpack/pf.webp';
import pg from '../assets/icons/flagpack/pg.webp';
import ph from '../assets/icons/flagpack/ph.webp';
import pk from '../assets/icons/flagpack/pk.webp';
import pl from '../assets/icons/flagpack/pl.webp';
import pm from '../assets/icons/flagpack/pm.webp';
import pn from '../assets/icons/flagpack/pn.webp';
import pr from '../assets/icons/flagpack/pr.webp';
import ps from '../assets/icons/flagpack/ps.webp';
import pt from '../assets/icons/flagpack/pt.webp';
import pw from '../assets/icons/flagpack/pw.webp';
import py from '../assets/icons/flagpack/py.webp';
import qa from '../assets/icons/flagpack/qa.webp';
import re from '../assets/icons/flagpack/re.webp';
import ro from '../assets/icons/flagpack/ro.webp';
import rs from '../assets/icons/flagpack/rs.webp';
import ru from '../assets/icons/flagpack/ru.webp';
import rw from '../assets/icons/flagpack/rw.webp';
import sa from '../assets/icons/flagpack/sa.webp';
import sb from '../assets/icons/flagpack/sb.webp';
import sc from '../assets/icons/flagpack/sc.webp';
import sd from '../assets/icons/flagpack/sd.webp';
import se from '../assets/icons/flagpack/se.webp';
import sg from '../assets/icons/flagpack/sg.webp';
import sh from '../assets/icons/flagpack/sh.webp';
import si from '../assets/icons/flagpack/si.webp';
import sj from '../assets/icons/flagpack/sj.webp';
import sk from '../assets/icons/flagpack/sk.webp';
import sl from '../assets/icons/flagpack/sl.webp';
import sm from '../assets/icons/flagpack/sm.webp';
import sn from '../assets/icons/flagpack/sn.webp';
import so from '../assets/icons/flagpack/so.webp';
import sr from '../assets/icons/flagpack/sr.webp';
import ss from '../assets/icons/flagpack/ss.webp';
import st from '../assets/icons/flagpack/st.webp';
import sv from '../assets/icons/flagpack/sv.webp';
import sx from '../assets/icons/flagpack/sx.webp';
import sy from '../assets/icons/flagpack/sy.webp';
import sz from '../assets/icons/flagpack/sz.webp';
import tc from '../assets/icons/flagpack/tc.webp';
import td from '../assets/icons/flagpack/td.webp';
import tf from '../assets/icons/flagpack/tf.webp';
import tg from '../assets/icons/flagpack/tg.webp';
import th from '../assets/icons/flagpack/th.webp';
import tj from '../assets/icons/flagpack/tj.webp';
import tk from '../assets/icons/flagpack/tk.webp';
import tl from '../assets/icons/flagpack/tl.webp';
import tm from '../assets/icons/flagpack/tm.webp';
import tn from '../assets/icons/flagpack/tn.webp';
import to from '../assets/icons/flagpack/to.webp';
import tr from '../assets/icons/flagpack/tr.webp';
import tt from '../assets/icons/flagpack/tt.webp';
import tv from '../assets/icons/flagpack/tv.webp';
import tw from '../assets/icons/flagpack/tw.webp';
import tz from '../assets/icons/flagpack/tz.webp';
import ua from '../assets/icons/flagpack/ua.webp';
import ug from '../assets/icons/flagpack/ug.webp';
import um from '../assets/icons/flagpack/um.webp';
import us from '../assets/icons/flagpack/us.webp';
import uy from '../assets/icons/flagpack/uy.webp';
import uz from '../assets/icons/flagpack/uz.webp';
import va from '../assets/icons/flagpack/va.webp';
import vc from '../assets/icons/flagpack/vc.webp';
import ve from '../assets/icons/flagpack/ve.webp';
import vg from '../assets/icons/flagpack/vg.webp';
import vi from '../assets/icons/flagpack/vi.webp';
import vn from '../assets/icons/flagpack/vn.webp';
import vu from '../assets/icons/flagpack/vu.webp';
import wf from '../assets/icons/flagpack/wf.webp';
import ws from '../assets/icons/flagpack/ws.webp';
import ye from '../assets/icons/flagpack/ye.webp';
import yt from '../assets/icons/flagpack/yt.webp';
import za from '../assets/icons/flagpack/za.webp';
import zm from '../assets/icons/flagpack/zm.webp';
import zw from '../assets/icons/flagpack/zw.webp';



// Add more imports as needed

// Create a mapping of country codes to images
const flagImages = {
  ad,
  ae,
  af,
  ag,
  ai,
  al,
  am,
  ao,
  aq,
  ar,
  as,
  at,
  au,
  aw,
  ax,
  az,
  ba,
  bb,
  bd,
  be,
  bf,
  bg,
  bh,
  bi,
  bj,
  bl,
  bm,
  bn,
  bo,
  br,
  bs,
  bt,
  bv,
  bw,
  by,
  bz,
  ca,
  cc,
  cd,
  cf,
  cg,
  ch,
  ci,
  ck,
  cl,
  cm,
  cn,
  co,
  cr,
  cu,
  cv,
  cw,
  cx,
  cy,
  cz,
  de,
  dj,
  dk,
  dm,
  do: doImage,
  dz,
  ec,
  ee,
  eg,
  eh,
  er,
  es,
  et,
  fi,
  fj,
  fk,
  fm,
  fo,
  fr,
  ga,
  gb,
  gd,
  ge,
  gf,
  gg,
  gh,
  gi,
  gl,
  gm,
  gn,
  gp,
  gq,
  gr,
  gs,
  gt,
  gu,
  gw,
  gy,
  hk,
  hm,
  hn,
  hr,
  ht,
  hu,
  id,
  ie,
  il,
  im,
  in: flagIn,
  io,
  iq,
  ir,
  is,
  it,
  je,
  jm,
  jo,
  jp,
  ke,
  kg,
  kh,
  ki,
  km,
  kn,
  kp,
  kr,
  kw,
  ky,
  kz,
  la,
  lb,
  lc,
  li,
  lk,
  lr,
  ls,
  lt,
  lu,
  lv,
  ly,
  ma,
  mc,
  md,
  me,
  mf,
  mg,
  mh,
  mk,
  ml,
  mm,
  mn,
  mo,
  mp,
  mq,
  mr,
  ms,
  mt,
  mu,
  mv,
  mw,
  mx,
  my,
  mz,
  na,
  nc,
  ne,
  nf,
  ng,
  ni,
  nl,
  no,
  np,
  nr,
  nu,
  nz,
  om,
  pa,
  pe,
  pf,
  pg,
  ph,
  pk,
  pl,
  pm,
  pn,
  pr,
  ps,
  pt,
  pw,
  py,
  qa,
  re,
  ro,
  rs,
  ru,
  rw,
  sa,
  sb,
  sc,
  sd,
  se,
  sg,
  sh,
  si,
  sj,
  sk,
  sl,
  sm,
  sn,
  so,
  sr,
  ss,
  st,
  sv,
  sx,
  sy,
  sz,
  tc,
  td,
  tf,
  tg,
  th,
  tj,
  tk,
  tl,
  tm,
  tn,
  to,
  tr,
  tt,
  tv,
  tw,
  tz,
  ua,
  ug,
  um,
  us,
  uy,
  uz,
  va,
  vc,
  ve,
  vg,
  vi,
  vn,
  vu,
  wf,
  ws,
  ye,
  yt,
  za,
  zm,
  zw,
};

export const FlagIcon = forwardRef(
  ({ code, sx, ...other }, ref) => {
    const baseStyles = {
      width: 26,
      height: 20,
      flexShrink: 0,
      overflow: 'hidden',
      borderRadius: '5px',
      display: 'inline-flex',
      bgcolor: 'background.neutral',
    };

    const renderFallback = <Box component="span" sx={{ ...baseStyles, ...sx }} />;

    if (!code) {
      return renderFallback;
    }

    const imgSrc = flagImages[code.toLowerCase()];

    if (!imgSrc) {
      console.error('No image found for code:', code);
      return renderFallback;
    }

    return (
      <NoSsr fallback={renderFallback}>
        <Box ref={ref} component="span" sx={{ ...baseStyles, ...sx }} {...other}>
          <Box
            component="img"
            alt={code}
            src={imgSrc}
            sx={{ width: 1, height: 1, objectFit: 'cover' }}
          />
        </Box>
      </NoSsr>
    );
  }
);

export default FlagIcon;