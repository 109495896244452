import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { merge } from 'lodash';

import Image from '../../components/Image';
import Carousel, { useCarousel, CarouselDots, CarouselArrows } from '../../components/carousel';

// ----------------------------------------------------------------------

const CarouselV2 = ({ data, sx, containerSx }) => {
  const theme = useTheme();

  const carousel = useCarousel({
    autoplay: true,
    ...CarouselDots({
      rounded: true,
      sx: { mt: 3, ...sx },
    }),
  });

  return (
    <Box
      sx={merge({
        position: 'relative',
        '& .slick-list': {
          borderRadius: 2,
          boxShadow: theme.customShadows.z16,
        }
      }, containerSx)}
    >
      <CarouselArrows filled shape="rounded" onNext={carousel.onNext} onPrev={carousel.onPrev}>
        <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
          {data.map((item) => (
            <CarouselItem key={item.id} item={item} />
          ))}
        </Carousel>
      </CarouselArrows>
    </Box>
  );
};

export default CarouselV2;

CarouselV2.propTypes = {
  data: PropTypes.array,
  sx: PropTypes.object,
};

// ----------------------------------------------------------------------

const CarouselItem = ({ item }) => {
  const { url, title, ratio="16/9" } = item;

  return <Image alt={title} src={url} ratio={ratio} />;
};

CarouselItem.propTypes = {
  item: PropTypes.object,
  containerSx: PropTypes.object
};
