import { Box, Card, Chip, Container, Divider, Grid, Icon, Link, ListItem, ListItemText, Paper, Stack, Tab, Tabs, Typography, List, ListItemIcon, Button, Fab, CardContent, Avatar, Accordion, AccordionSummary, AccordionDetails, AccordionActions, CardHeader, Alert, Tooltip, IconButton, CardActions } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { m } from 'framer-motion';
import NavigationIcon from '@mui/icons-material/EmojiEvents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { styled } from '@mui/system';

import DynamicAspectRatioImage from '../../components/DynamicAspectRatioImage';
import useResponsive from '../../hooks/useResponsive';

const StyledCard = styled(Card)(({ theme, selected }) => ({
    minWidth: 275,
    marginBottom: theme.spacing(2),
    transition: 'transform 0.3s ease-in-out',
    border: selected ? `1px solid ${theme.palette.secondary.main}` : 'none',
    boxShadow: selected ? `0 0 10px ${theme.palette.secondary.main}` : theme.shadows[1],
    '&:hover': {
        transform: 'scale(1.02)',
    },
    // ...bgGradient({
    //     startColor: theme.palette.error.light,
    //     endColor: theme.palette.primary.light,

    // })
}));

const EventCard = ({ event, selected, registered, onSelect, onBook }) => {
    let buttonColor;
    let buttonText;

    if (registered) {
        buttonColor = "primary";
        buttonText = "Registered";
    } else if (selected) {
        buttonColor = "secondary";
        buttonText = "Selected";
    } else {
        buttonColor = "secondary";
        buttonText = "Select";
    }

    return (
        <StyledCard selected={selected}>
            <CardContent>
                <Grid container spacing={2} alignItems="flex-start">
                    <Stack direction={"row"} flex={1}>
                        <Stack justifyContent="space-between">
                            <Typography textAlign={"left"} variant="subtitle2" color="textSecondary">{event.name}</Typography>
                            <Typography alignItems={"center"} variant={"subtitle2"} display={"flex"} direction={"row"} >Price: <Typography pl={1} variant='h6'>₹{event.price}</Typography></Typography>
                        </Stack>
                        <Stack alignItems={"flex-start"} flex={1} pl={1}>
                            <Tooltip title={
                                <>
                                    <Typography>{event.helpInfo}</Typography>
                                    <Typography>Doc Requirements: {event.docRequirements}</Typography>
                                    <Typography>DOB Requirement: Born after {event.dobRequirement}</Typography>
                                </>
                            }>
                                <IconButton sx={{ padding: 0 }}>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                    <Stack>
                        {registered && <Chip size="small" label="Registered" color="primary" />}
                        {selected && !registered && <Chip size="small" label="Selected" color="secondary" />}
                        {!registered && !selected && <Chip size="small" label="Not Eligible" color="error" />}

                    </Stack>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container alignItems="center" justifyContent="space-between">
                    {!registered && <Button fullWidth variant="contained" color={buttonColor} onClick={() => onSelect(event)}>{buttonText}</Button>}
                </Grid>
            </CardActions>
        </StyledCard>
    );
};

const CategoryRow = ({ category, events, onEventSelect, selectedEvents, registeredEvents, docRequirements, dobRequirement, helpInfo, maxSelections, onBook, expanded, setExpanded }) => {
    const handleSelect = (event) => {
        const alreadySelected = selectedEvents.includes(event.id);
        const alreadyRegistered = registeredEvents.includes(event.id);

        if (alreadyRegistered) return;
        if (alreadySelected) {
            onEventSelect(event.id, false);
        } else if (selectedEvents.length + registeredEvents.length < maxSelections) {
            onEventSelect(event.id, true, category);
            // setExpanded(false);
        } else {
            alert(`You can only select up to ${maxSelections} events.`);
        }
    };
    const selectedEvent = events.find(event => selectedEvents.includes(event.id));

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(category, !expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${category}-content`} id={`${category}-header`}>
                <Typography pr={1}>{category}</Typography>
                {selectedEvent && selectedEvent.id ? <Chip size="small" label={`${selectedEvent.name}`} color="primary" /> : null}
            </AccordionSummary>
            <AccordionDetails padding={0}>
                <Box>
                    {events.map((event, index) => (
                        <EventCard
                            key={index}
                            event={event}
                            selected={selectedEvents.includes(event.id)}
                            registered={registeredEvents.includes(event.id)}
                            onSelect={handleSelect}
                            onBook={onBook}
                        />
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion >
    );
};

const EventList = ({ data, registeredEvents, maxSelections }) => {
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [expanded, setExpanded] = useState(data?.reduce((acc, categoryData, index) => {
        return {
            ...acc,
            [categoryData.category]: index === 0
        }
    }, {}));

    const handleEventSelect = (eventId, isSelected, category) => {
        if (isSelected) {
            setSelectedEvents([eventId]);
            if (category) {

                const newExpanded = Object.keys(expanded).reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: false
                    }
                }, {});
                setExpanded(newExpanded);
            } else {
                setSelectedEvents([]);
            }
        };
    };

    const handleBookEvent = (event) => {
        alert(`Booking event: ${event.label} for price: $${event.price}`);
    };

    const handleExpand = (category, isExpanded, closeOthers) => {
        setExpanded({
            ...expanded,
            [category]: isExpanded
        });
    }

    return (
        <Container sx={{ maxWidth: '100%', overflowX: 'auto' }} disableGutters>
            <Box mt={4}>
                {alertMessage && <Alert severity="error">{alertMessage}</Alert>}
                {data.map((categoryData, index) => (
                    <CategoryRow
                        key={index}
                        category={categoryData.category}
                        events={categoryData.events.map(event => ({
                            ...event,
                            docRequirements: categoryData.docRequirements,
                            dobRequirement: categoryData.dobRequirement,
                            helpInfo: categoryData.helpInfo,
                        }))}
                        selectedEvents={selectedEvents}
                        registeredEvents={registeredEvents}
                        onEventSelect={handleEventSelect}
                        maxSelections={maxSelections}
                        onBook={handleBookEvent}
                        setExpanded={handleExpand}
                        expanded={expanded[categoryData.category]}
                    />
                ))}
            </Box>
        </Container>
    );
};

// Example usage with nested array input
const eventData = [
    {
        category: "OPEN",
        events: [{ label: 'MS', name: "Mens Singles", id: "open-ms", price: 500 }, { label: 'WD', name: "Womens Doubles", id: "open-wd", price: 800 }],
        docRequirements: 'ID Proof, Passport Photo',
        dobRequirement: '2003-01-01',
        helpInfo: 'Open category for all age groups.'
    },
    {
        category: "U10",
        events: [{ label: 'MS', id: "u10-ms", price: 500, name: "U10 Mens Singles" }, { label: 'WD', id: "u10-wd", price: 800, name: "U10 Womens Doubles" }],
        docRequirements: 'Birth Certificate, NOC from school, Passport Photo',
        dobRequirement: '2014-01-01',
        helpInfo: 'Under 10 category.'
    },
    {
        category: "U13",
        events: [{ label: 'MS', id: "u13-ms", price: 500, name: "U13 Mens Singles" }, { label: 'WD', id: "u13-wd", price: 800, name: "U13 Womens Doubles" }],
        docRequirements: 'Birth Certificate, NOC from school, Passport Photo',
        dobRequirement: '2011-01-01',
        helpInfo: 'Under 13 category.'
    },
    {
        category: "U16",
        events: [{ label: 'MS', id: "u16-ms", price: 500, name: "U16 Mens Singles" }, { label: 'WD', id: "u16-wd", price: 800, name: "U16 Womens Doubles" }],
        docRequirements: 'Birth Certificate, NOC from school, Passport Photo',
        dobRequirement: '2008-01-01',
        helpInfo: 'Under 16 category.'
    },
    {
        category: "U19",
        events: [{ label: 'MS', id: "u19-ms", price: 500, name: "U19 Mens Singles" }, { label: 'WD', id: "u19-wd", price: 800, name: "U19 Womens Doubles" }],
        docRequirements: 'Birth Certificate, NOC from school, Passport Photo',
        dobRequirement: '2005-01-01',
        helpInfo: 'Under 19 category.'
    },
    // Additional categories...
];
const AddEventForRegistration = () => {

    const contentRef = useRef(null);
    const [expanded, setExpanded] = useState(false);


    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };

    const mdUp = useResponsive('up', 'md');

    return (
        <Container disableGutters maxWidth={"lg"} align="center" sx={{ maxHeight: "100%", minHeight: "540", position: "relative" }}>
            <Grid container>
                <Grid item xs={12} md={8} >
                    <Grid container>

                        <Grid container alignItems={"flex-end"}>
                            <Grid item xs md={4} />
                            <Grid item xs={12} md={8} >
                                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} >
                                    <EventList data={eventData} registeredEvents={[]} maxSelections={3} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            {/* <Grid container>
                <EventList />
            </Grid> */}
            {/* 
            VENUE + (MAP LOCATION)
            CONTACT
            ORGANIZER
            

             */}
        </Container>
    );
}

export default AddEventForRegistration;
