import { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Stack,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { alpha, useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from '../../../redux/store';
import { setLoginFormOpen } from '../../../redux/slices/user';
import useResponsive from '../../../hooks/useResponsive';
import LoadingScreen from '../../../components/LoadingScreen';
import MainHeader from '../../../layouts/main/MainHeader';
import Image from '../../../components/Image';
import BOOK_FREE_TRIAL_BACKGROUND from '../../../assets/Event.svg';

import { getStepsForEventType } from './stepConfigurations';
import { fetchEventDetail } from '../../../redux/slices/eventDetails';
import { setCurrentStepById } from '../../../redux/slices/tournamentRegistrationSlice';
import useRegistration from './useRegistration';

const ActionButtons = ({ children }) => {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: mdUp ? 'sticky' : 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        background: `linear-gradient(${mdUp ? 'to top' : 'to bottom'}, ${alpha(
          theme.palette.background.paper,
          0.9
        )}, transparent)`,
        backdropFilter: 'blur(8px)',
        borderTopLeftRadius: mdUp ? 0 : theme.spacing(2),
        borderTopRightRadius: mdUp ? 0 : theme.spacing(2),
        boxShadow: mdUp ? 'none' : '0 -4px 12px rgba(0,0,0,0.1)',
        padding: theme.spacing(2),
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
};

const RegisterEvent = () => {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();
  const { currentPlayer, loader } = useSelector((state) => state.user);
  const { eventDetailsLoader, eventDetailsData } = useSelector(
    (state) => state.eventDetails
  );
  const {
    stepper: { currentStepId, steps },
  } = useSelector((state) => state.eventRegistration);
  const { clubId } = useParams();
  const dispatch = useDispatch();

  const basicDetailsRef = useRef(null);
  const tournamentRegisterRef = useRef(null);
  const verifyFormRef = useRef(null);

  const {
    nextStep,
    prevStep,
    resetData,
    currentStep,
    updateStepsState,
    setEventId,
  } = useRegistration(clubId);
  
  const currentStepData = steps[currentStep];

  useEffect(() => {
    if(clubId) {
      dispatch(fetchEventDetail(clubId));
      setEventId(clubId);
    }
  }, [clubId, dispatch]);

  useEffect(() => {
    if (eventDetailsLoader === 'succeeded') {
      const eventType = eventDetailsData?.category;
      const newSteps = getStepsForEventType(eventType);
      updateStepsState(newSteps);
    }
  }, [eventDetailsLoader, eventDetailsData, updateStepsState]);

  useEffect(() => {
    return () => {
      resetData();
    };
  }, [resetData]);

  if (loader) {
    return <LoadingScreen />;
  }

  const refs = {
    'basic-details': basicDetailsRef,
    'sub-event-selection': tournamentRegisterRef,
    'verify-form': verifyFormRef,
  };

  const renderNavigationButtons = () => {
    if (!currentPlayer?.id || !currentStepData) return null;

    const stepButtons = currentStepData?.buttons;

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: theme.spacing(2),
          pb: theme.spacing(2),
        }}
      >
        {stepButtons
          .filter((button) => button.show)
          .map((buttonConfig, index) => {
            const { action, label, variant, disabled, flex } = buttonConfig;
            const onClick = () => {
              if (action === 'next') {
                refs[currentStepId]?.current?.onNextStep();
              } else if (action === 'back') {
                const currentIndex = currentStep;
                if (currentIndex > 0) {
                  dispatch(setCurrentStepById(steps[currentIndex - 1].id));
                }
              }
            };

            return (
              <Button
                key={index}
                variant={variant}
                onClick={onClick}
                disabled={disabled}
                sx={{
                  flex: flex || 1,
                  height: theme.spacing(6),
                  textTransform: 'none',
                }}
                fullWidth
              >
                {label}
              </Button>
            );
          })}
      </Box>
    );
  };

  return (
    <>
      <MainHeader />
      {/* Background Image and Overlay */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      >
        <Image
          src="https://images.unsplash.com/photo-1637203727770-3fff478fb1ef?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8dHJvcGh5fGVufDB8fDB8fHwy"
          alt="Background"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: `linear-gradient(to right, rgba(22, 28, 36, 0.98), rgba(22, 28, 36, 0.8))`,
          }}
        />
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          height: `calc(100vh - ${mdUp ? theme.spacing(4) : '0px'})`,
          pt: { xs: theme.spacing(8), md: theme.spacing(12) },
          px: { xs: theme.spacing(2), md: theme.spacing(3) },
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: theme.spacing(3), md: theme.spacing(8) }}
          sx={{ height: '100%' }}
        >
          {/* Left Section - Hidden on mobile */}
          {mdUp && (
            <Stack
              flex={1}
              spacing={theme.spacing(3)}
              sx={{
                alignItems: 'start',
                color: 'common.white',
              }}
            >
              <Image
                src={BOOK_FREE_TRIAL_BACKGROUND}
                alt="Event"
                sx={{
                  width: '100%',
                  maxWidth: 480,
                }}
              />
              <Typography variant="h3" sx={{ fontWeight: 700 }}>
                Join Our Sports Community
              </Typography>
              <Typography variant="h6" sx={{ opacity: 0.72 }}>
                Register for events, track your progress, and connect with other athletes
              </Typography>
            </Stack>
          )}

          {/* Right Form Section */}
          <Stack
            flex={1}
            sx={{
              width: '100%',
              maxWidth: 480,
              height: { xs: 'auto', md: '100%' },
              minHeight: { xs: 0, md: '100%' },
              background: alpha(theme.palette.background.paper, 0.9),
              backdropFilter: 'blur(8px)',
              borderRadius: theme.spacing(2),
              p: { xs: theme.spacing(2), md: theme.spacing(3) },
              display: 'flex',
              flexDirection: 'column',
              mx: { xs: 'auto', md: 0 },
              overflow: 'hidden',
            }}
          >
            {/* Header */}
            <Typography
              variant={mdUp ? 'h4' : 'h6'}
              sx={{ mb: theme.spacing(1), textAlign: 'center' }}
            >
              Registration for {eventDetailsData?.name}
            </Typography>

            {/* Stepper */}
            <Stepper activeStep={currentStep} alternativeLabel sx={{ mb: theme.spacing(3) }}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box
              sx={{
                flex: 1,
                overflowY: 'auto',
                minHeight: 0,
                pb: { xs: theme.spacing(8), md: 0 },
              }}
            >
              {!currentPlayer?.id ? (
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: { xs: 200, md: 'auto' },
                  }}
                >
                  <Stack spacing={theme.spacing(2)} alignItems="center">
                    <Typography variant="h4">Quick Registration</Typography>
                    <Stack direction="row" spacing={theme.spacing(2)}>
                      <Button
                        variant="contained"
                        onClick={() => dispatch(setLoginFormOpen(true))}
                      >
                        Login
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => dispatch(setLoginFormOpen(true))}
                      >
                        Sign up
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              ) : (
                currentStepData?.getComponent(refs[currentStepId])
              )}
            </Box>

            {/* Action Buttons */}
            <ActionButtons>{renderNavigationButtons()}</ActionButtons>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default RegisterEvent;