import React from 'react';
import { Container, Box, Typography, Divider, Stack,Card,Paper } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import { fDate } from '../../utils/formatNumber';
import useResponsive from '../../hooks/useResponsive';

const TimelineEvent = ({ icon, title, dateTime, isLast }) => {
    const mdUp = useResponsive('up', 'md');
    return (
        <TimelineItem>
            <TimelineOppositeContent>
            <Typography textAlign={"right"} variant={mdUp ? "subtitle1" : "subtitle2"}>{title}</Typography>

        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot color="primary">
                <Iconify icon={icon} width={16} height={16} margin={.5} />
            </TimelineDot>
            {!isLast && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent justifyContent={'center'}>
            <Typography variant="body2" component={'span'} color="textSecondary">{dateTime}</Typography>
        </TimelineContent>
    </TimelineItem>
    )
};

const LastTimelineEvent = ({ icon, title, dateTime }) => {
    const mdUp = useResponsive('up', 'md');
        return (
        <TimelineItem>
            <TimelineOppositeContent>
            <Typography textAlign={"right"} variant={mdUp ? "subtitle1" : "subtitle2"}>{title}</Typography>

        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot color="error">
                <Iconify icon={icon} width={16} height={16} margin={.5} />
            </TimelineDot>
        </TimelineSeparator>
        <TimelineContent justifyContent={'center'}>

            <Typography variant="body2" component={'span'}>{dateTime}</Typography>
        </TimelineContent>
    </TimelineItem>
    )
};

const TournamentTimeline = () => {
    const { eventDetailsData } = useSelector((state) => state.eventDetails);

    return (
        <Paper sx={{ textAlign: 'left',width: '100%'}}>
            <Box mt={4} sx={{display:'flex',justifyContent:'start', flexDirection:'column'}}>
            <Typography variant="h6" fontWeight={600} ml={2}>Registration Timeline</Typography>
            <Timeline position="right" sx={{ marginRight:{md:20}, marginTop:2}}>
                <TimelineEvent
                    icon={'fluent:edit-32-filled'}
                    title="Entry opens"
                    dateTime={fDate(eventDetailsData?.registrationStartDate)}

                />
                <TimelineEvent
                    icon={'uis:lock'}
                    title="Closing deadline"
                    dateTime={fDate(eventDetailsData?.registrationEndDate)}
                />
                <TimelineEvent
                    icon={'ph:flag-checkered-fill'}
                    title="Start tournament"
                    dateTime={fDate(eventDetailsData?.startDate)}
                />
                <LastTimelineEvent
                    icon={'fluent:trophy-48-filled'}
                    title="End of tournament"
                    dateTime={fDate(eventDetailsData?.endDate)}
                />
            </Timeline>
        </Box>
        </Paper>
    );
};

export default TournamentTimeline;