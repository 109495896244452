import { m } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Link, Container, Grid, Typography, Stack, Card } from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { varFade } from '../animate';


import Image from '../Image';
import KRIDA_LOGO from "../../assets/logo_KRIDA.png"
import PSM_LOGO from "../../assets/logo_PSM_old.png"
import PSM_LANDING from "../../assets/landing_page.png"
import PSM_LANDING_MOBILE from "../../assets/mobile_hero.webp";

import useResponsive from '../../hooks/useResponsive';



const ContentStyle = styled((props) => <Stack {...props} />)(({ theme }) => ({
    textAlign: 'center',
    position: 'relative',
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        maxWidth: 480
    },
}));

const RootStyle = styled(m.div)(({ theme }) => ({
    position: 'relative',
    // backgroundColor: theme.palette.grey[400],
    backgroundImage: `url(${PSM_LANDING_MOBILE})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.up('md')]: {
        top: 0,
        left: 0,
        backgroundImage: `url(${PSM_LANDING})`
    },
}));

const HeroLanding = (props) => {

    const navigate = useNavigate();
    const smDown = useResponsive('down', 'sm');
    const mdUp = useResponsive('up', 'md');
    return (
        <RootStyle>
            <Container disableGutters>
                <ContentStyle>
                    <m.div variants={varFade().inRight}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            textAlign: 'center'
                        }}>

                        <Stack sx={{ position: 'absolute', bottom: 30, width: '100%', height: '100vh' }} justifyContent={mdUp ? 'center' : 'flex-end'}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }} pb={{ md: 4 }}>
                                <Image
                                    alt="login"
                                    src={PSM_LOGO}
                                    sx={{
                                        height: smDown ? 40 : 72,
                                        width: smDown ? 40 : 72,
                                        alignSelf: 'center',
                                    }}
                                />
                            </Box>
                            {!smDown && <Typography variant="h6" sx={{ color: 'common.defaultBlack' }}>
                                Padukone Sports Management
                            </Typography>}
                            <Typography variant={smDown ? 'h2' : 'h1'} sx={{ color: 'common.defaultBlack', lineHeight: 1, marginTop: 1, marginBottom: 1 }}>
                                Badminton <br /> Summer Camp
                            </Typography>
                            <Typography pb={{ xs: 2, md: 6 }} pt={{ md: 4 }} variant={smDown ? 'body2' : 'subtitle1'} sx={{ color: 'common.defaultBlack', px: 3 }}>
                                {/* Spend an exciting summer by enrolling at the Padukone sports management badminton summer camp at a world-class facility near you. We offer your kids an entire summer of fun and excitement with interesting badminton drills, friendly competitions, and fun workouts to keep their bodies and minds fit. */}
                                We offer your kids interesting badminton drills, friendly competitions, and fun workouts to keep their bodies and minds fit.
                            </Typography>
                            <Button sx={{
                                width: '75%', maxWidth: '400px', alignSelf: 'center', backgroundColor: '#193052', ":hover": {
                                    background: "#193052"
                                }
                            }} variant="contained" size="large" // disabled
                                onClick={(e) => {
                                    navigate(`/event/psm-summer-camp-2024`);
                                }}
                            >
                                Register Now
                            </Button>
                        </Stack>
                    </m.div>
                </ContentStyle>
            </Container>
            {mdUp && <Box sx={{
                position: 'absolute',
                bottom: 20,
                width: '100%',
                display: "flex",
                justifyContent: "center"
            }}>
                <m.div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    textAlign: 'end',
                    margin: 16,
                    width: '100%'
                }} variants={varFade().inRight}>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems='center' width={'100%'}>

                        <Stack direction={'row'}>
                            <Image
                                alt="login"
                                src={KRIDA_LOGO}
                                sx={{
                                    height: 40,
                                    width: 40,
                                    alignSelf: 'center',
                                }}
                            />
                            <Stack direction={'column'} alignItems='flex-start' pr={2} pl={1} justifyContent={'flex-start'}>
                                <Typography variant="caption" sx={{ color: 'common.defaultBlack' }}>
                                    powered by
                                </Typography>
                                <Typography variant="h3" sx={{ color: '#193052', lineHeight: 1 }}>
                                    Krida
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </m.div>
            </Box>}
        </RootStyle>

    )
}

export default HeroLanding;