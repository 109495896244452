import { Stack, Typography, Card, CardHeader, CardContent } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Searchbar from '../../krida/searchbar';


const PlayerTeamList = () => {
    const { partnerList } = useSelector((state) => state.tournamentRegistration);

    return (

        <Searchbar />

    );
};

export default PlayerTeamList;