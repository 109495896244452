import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack, Typography } from '@mui/material';
import Iconify from '../Iconify';

const LocationPermissionDialog = ({ onLocationUpdate }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'prompt') {
                setOpen(true);
            } else if (result.state === 'granted') {
                handleAgree();
            }
        });
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleAgree = () => {
        setOpen(false);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                onLocationUpdate(position);
                setOpen(false);
            },
            (error) => {
                console.error(error);
            }
        );
    };


    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction={'row'} alignItems={'center'}><Iconify sx={{ color: 'green', width: 32 }} icon={'eva:pin-fill'} /> <Typography variant='subtitle2'>Find the nearest sports academy near you!</Typography></Stack>
                </DialogTitle>
                <DialogContent sx={{ paddingX: 5, justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                    <DialogContentText variant='body2' id="alert-dialog-description">
                        <Stack alignItems={'center'}>
                            Allow location access to personalize your recommendations.
                        </Stack>
                    </DialogContentText>
                    <Stack direction={'row'} justifyContent={'center'} paddingTop={2} alignItems={'center'}><Typography variant='caption' textAlign={'center'} justifyContent={'center'} alignItems={'center'}>
                        We value your privacy
                    </Typography> <Iconify icon={'eva:lock-fill'} sx={{ color: 'green' }} /> </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Later</Button>
                    <Button onClick={handleAgree} autoFocus>
                        Allow
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default LocationPermissionDialog;
