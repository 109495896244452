import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Box } from '@mui/material';
import { useSelector } from '../../../redux/store';
import useRegistration from './useRegistration';
import UserCard from './UserCard';
import DynamicForm from '../../../components/dynamic-form/DynamicForm';

const BasicDetails = forwardRef((_, ref) => {
  const { currentPlayer } = useSelector((state) => state.user);
  const { formData, eventId } = useSelector((state) => state.eventRegistration);
  const { updateFormData, nextStep } = useRegistration();
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      if (formRef.current) {
        formRef.current.submitForm();
      }
    },
  }));

  const onSubmit = (data) => {
    updateFormData(data);
    nextStep();
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          // sm: '1fr 1fr',
        },
        gap: theme.spacing(2),
        mb: theme.spacing(2),
      })}
    >
      <UserCard user={currentPlayer} />
      <DynamicForm
        ref={formRef}
        formName={`basic-details-${eventId}`}
        onSubmit={onSubmit}
        showSubmitButton={false}
        defaultValues={formData || {}}
        schema={{
          name: 'player_form',
          layout: 'single-column',
          groups: [
            // {
            //   name: 'personal_info',
            //   label: { en: 'Personal Information' },
            //   fields: ['playerName', 'age', 'dob', 'place', 'state'],
            // },
            // {
            //   name: 'contact_info',
            //   label: { en: 'Contact Information' },
            //   fields: ['contactNumber', 'baiId'],
            // },
            {
              name: 'performance_info',
              label: { en: 'Performance Information' },
              fields: ['baiId','stateRanking', 'achievements','academyNameAndPlace'],
            },
          ],
          fields: 
          [
            
          // },
          // {
          //   name: 'age',
          //   type: 'number',
          //   label: { en: 'Age' },
          //   placeholder: { en: 'Enter age' },
          //   validations: {
          //     required: true,
          //     min: 5,
          //     max: 100,
          //   },
          // },
          // {
          //   name: 'dob',
          //   type: 'date',
          //   label: { en: 'Date of Birth' },
          //   placeholder: { en: 'Select date of birth' },
          //   validations: {
          //     required: true,
          //   },
          // },
          // {
          //   name: 'place',
          //   type: 'text',
          //   label: { en: 'Place' },
          //   placeholder: { en: 'Enter place' },
          //   validations: {
          //     required: true,
          //     maxLength: 50,
          //   },
          // },
          // {
          //   name: 'state',
          //   type: 'select',
          //   label: { en: 'State' },
          //   placeholder: { en: 'Select state' },
          //   options: [
          //     { value: 'state1', label: 'State 1' },
          //     { value: 'state2', label: 'State 2' },
          //     { value: 'state3', label: 'State 3' },
          //   ],
          //   validations: {
          //     required: true,
          //   },
          // },
          // {
          //   name: 'contactNumber',
          //   type: 'phone',
          //   label: { en: 'Contact Number' },
          //   placeholder: { en: 'Enter contact number' },
          //   validations: {
          //     required: true,
          //     pattern: '^\\+?[1-9]\\d{1,14}$',
          //   },
          // },
          {
            name: 'academyNameAndPlace',
            type: 'text',
            label: { en: 'Enter Academy Name and Place' },
            placeholder: { en: 'Enter Academy Name and Place' },
            validations: {
              required: false,
              minLength: 2,
              maxLength: 100,
            },
          },
          {
            name: 'baiId',
            type: 'text',
            label: { en: 'BAI ID' },
            placeholder: { en: 'Enter BAI ID' },
            validations: {
              required: false,
              // pattern: '^\\d{6}$',
            },
          },
          {
            name: 'stateRanking',
            type: 'number',
            label: { en: 'State Ranking' },
            placeholder: { en: 'Enter state ranking' },
            validations: {
              required: false,
              min: 1,
              max: 1000,
            },
          },
          {
            name: 'achievements',
            type: 'textarea',
            label: { en: '3 Best Achievements' },
            placeholder: { en: 'Describe your top 3 achievements' },
            validations: {
              required: true,
              maxLength: 1000,
            },
            rows: 3,
          },

          ],
        }}
      />
    </Box>
  );
});

export default BasicDetails;