import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import Iconify from '../../components/Iconify';


const PopupContainer = styled(Box)(({ theme, variant }) => ({
  backgroundColor: '#fff',
  borderRadius: '16px',
  padding: '24px',
  width: '280px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const ActionButton = styled(LoadingButton)(({ theme, variant }) => ({
  width: '100%',
  borderRadius: '8px',
  padding: '12px',
  marginTop: '24px',
  textTransform: 'none',
  backgroundColor: theme.palette.text.primary,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.text.primary,
  },
}));

const SuccessErrorPopup = ({ 
  variant,
  title,
  message,
  buttonText,
  onButtonClick,
  onLoading
}) =>
  (
    <PopupContainer variant={variant}>
      <Box 
        className="icon" 
        sx={{ 
          backgroundColor: variant === 'success' ? '#E8F5E9' : '#FFE5E5',
          borderRadius: '50%',
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2
        }}
      >
        {variant === 'success' ? (
          <Iconify 
            icon="material-symbols:check-circle" 
            sx={{ 
              color: '#4CAF50', 
              fontSize: 32 
            }} 
          />
        ) : (
          <Iconify 
            icon="material-symbols:error" 
            sx={{ 
              color: '#FF3B30',
              fontSize: 32 
            }} 
          />
        )}
      </Box>
      
      <Typography 
        variant="h6" 
        sx={{ 
          fontWeight: 600,
          mb: 1
        }}
      >
        {title}
      </Typography>
      
      <Typography 
        variant="body2" 
        sx={{ 
          color: '#666',
          mb: 2
        }}
      >
        {message}
      </Typography>
      
      {/* { variant === 'error' ? (
        <ActionButton
          variant={"contained"}
          onClick={onButtonClick}
          disabled={variant === 'success'}
        >
          {buttonText}
        </ActionButton>
      ) : (
        <Box>
          <Typography>
            {buttonText}
          </Typography>
          <LoadingButton type='submit' loading={onLoading} >
            {buttonText}
          </LoadingButton>
        </Box>
      )} */}
      <ActionButton
          variant={"contained"}
          onClick={onButtonClick}
          disabled={variant === 'success'}
        >
          {buttonText}
        </ActionButton>
    </PopupContainer>
  );


export default SuccessErrorPopup;
